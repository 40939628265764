import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Tabs, TextField, useTheme, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { helperTextProps, StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import IconSelector from '../../../IconSelector';
import { AppContext, setIsFetching } from '../../../../app-context';
import { updatePlatformApp } from '../../../../wsClient/AppsManagerWsClient';
import Edition from '../../../EntityManager/Edition';
import EditButton from '../../../Buttons/Edit';
import CancelButton from '../../../Buttons/Cancel';
import Share from './Share';
import APP_PRODUCT_TARGET from '../appTarget';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

/**
 * Edit app management
 */
function AppPlatformEdition({ app, onCancel, onUpdate }) {
    const theme = useTheme();
    const { dispatch } = useContext(AppContext);
    const [tab, setTab] = useState(0);
    const [name, setName] = useState(app.name || '');
    const [description, setDescription] = useState(app.description || '');
    const [url, setUrl] = useState(app.url || '');
    const [appPackage, setPackage] = useState(app.package || '');
    const [icon, setIcon] = useState(app.icon || '');
    const [productsTarget, setProductsTarget] = useState(
        app.productsTarget.map((target) => ({ label: target, value: target }))
    );
    const [shareLevel, setShareLevel] = useState(app.shareLevel || '');
    const [controlledLevel, setControlledLevel] = useState(app.controlledLevel || '');
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setName(app.name);
        setDescription(app.description);
        setUrl(app.url);
        setPackage(app.package);
        setIcon(app.icon);
        setProductsTarget(app.productsTarget.map((target) => ({ label: target, value: target })));
        setShareLevel(app.shareLevel);
        setControlledLevel(app.controlledLevel);
    }, [app]);

    const handleEdit = useCallback(() => {
        dispatch(setIsFetching(true));

        updatePlatformApp(app.id, {
            ...app,
            name,
            description,
            url,
            package: appPackage,
            icon,
            productsTarget: productsTarget.map((target) => target.value),
            shareLevel,
            controlledLevel,
        }).then((response) => {
            dispatch(setIsFetching(false));

            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.update.success'), { variant: 'success' });
                onUpdate();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.update.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [
        app,
        appPackage,
        controlledLevel,
        description,
        dispatch,
        enqueueSnackbar,
        i18n,
        icon,
        name,
        onCancel,
        onUpdate,
        productsTarget,
        shareLevel,
        url,
    ]);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleDescriptionChange = useCallback((e) => setDescription(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.type')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleProductsTargetChange = useCallback((event, newValue) => {
        setProductsTarget(newValue);
    }, []);

    const handlePackageNameChange = useCallback((e) => setPackage(e.target.value), []);

    return (
        <Edition label={i18n.tc('ssoAdmin.apps.edition.title')} onCancel={onCancel}>
            <>
                <Tabs
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    value={tab}
                    onChange={handleTabChange}
                >
                    <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.general')} />
                    <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.logo')} />
                    <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.sharing')} />
                </Tabs>
                {tab === 0 && (
                    <DrawerContent>
                        <ContentArea>
                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.name')}
                                    onChange={handleNameChange}
                                    value={name}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.description')}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    multiline
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.url.label')}
                                    value={url}
                                    onChange={handleUrlChange}
                                    fullWidth
                                    helperText={i18n.tc('ssoAdmin.apps.fields.url.helperText')}
                                    FormHelperTextProps={helperTextProps}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={APP_PRODUCT_TARGET}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={productsTarget}
                                    onChange={handleProductsTargetChange}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.packageName')}
                                    onChange={handlePackageNameChange}
                                    value={appPackage}
                                />
                            </StyledSpacedWrapper>
                        </ContentArea>
                    </DrawerContent>
                )}

                {tab === 1 && (
                    <DrawerContent>
                        <ContentArea>
                            <IconSelector selectedIcon={icon} color={theme.palette.primary.main} onChange={setIcon} />
                        </ContentArea>
                    </DrawerContent>
                )}

                {tab === 2 && <Share app={app} onUpdate={onUpdate} onCancel={onCancel} />}

                {tab !== 2 && (
                    <DrawerActions>
                        <Grid container justifyContent="flex-end" mt={1}>
                            <Grid item mr={1}>
                                <CancelButton onClick={onCancel} />
                            </Grid>
                            <EditButton onClick={handleEdit} />
                        </Grid>
                    </DrawerActions>
                )}
            </>
        </Edition>
    );
}

AppPlatformEdition.propTypes = {
    app: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        productsTarget: PropTypes.array.isRequired,
        package: PropTypes.string.isRequired,
        icon: PropTypes.string,
        url: PropTypes.string.isRequired,
        shareLevel: PropTypes.string,
        controlledLevel: PropTypes.string,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AppPlatformEdition;
