import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../wsClient/SsoWsClient';
import { AppContext, setIsFetching } from '../../app-context';
import FormField from '../FormField';
import { StyledSpacedWrapper } from '../StyledComponents';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    '& > div:not(:last-child),button': {
        marginBottom: theme.spacing(2),
    },
    paddingBottom: theme.spacing(2),
}));

const StyledCopyFieldContainer = styled(`div`)({
    display: 'flex',
    '& > div': {
        flex: 1,
    },
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
}));

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const ssoWs = new SsoWsClient();

/**
 * A Popin to edit a grant
 */
function SiteProductEdition(props) {
    const { dispatch } = useContext(AppContext);
    const [name, setName] = useState(props.product.name || '');
    const [clientName, setClientName] = useState(props.product.clientName || '');
    const [siteName, setSiteName] = useState(props.product.siteName || '');
    const [url, setUrl] = useState(props.product.url || '');
    const inputEl = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleEdition = useCallback(() => {
        ssoWs.updateProduct(
            props.provider,
            {
                ...props.product,
                name,
                clientName,
                siteName,
                url,
            },
            props.product.productId,
            {
                onSuccess: () => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(i18n.tc('ssoAdmin.siteProducts.notistack.update.success'), { variant: 'success' });
                    props.onUpdate();
                    props.onClose();
                },
                onError: (brainWsResponse, xhr) => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    props.onClose();
                },
            }
        );
    }, [clientName, dispatch, enqueueSnackbar, i18n, name, props, siteName, url]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleClientNameChange = useCallback((e) => setClientName(e.target.value), []);

    const handleSiteNameChange = useCallback((e) => setSiteName(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleCopy = useCallback(() => {
        inputEl.current.disabled = false;
        inputEl.current.select();
        inputEl.current.disabled = true;
        document.execCommand('copy');
    }, []);

    return (
        <Dialog open fullWidth onClose={props.onClose}>
            <DialogTitle>
                <StyledTitle>
                    <EditIcon />
                    {i18n.tc('ssoAdmin.siteProducts.actions.editProduct')}
                    <StyledCloseButton aria-label="Close" onClick={props.onClose} size="large">
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledTitle>
            </DialogTitle>
            <StyledDialogContent>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.siteProducts.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.siteProducts.fields.groupName')}
                            value={clientName}
                            onChange={handleClientNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.siteProducts.fields.siteName')}
                            value={siteName}
                            onChange={handleSiteNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.siteProducts.fields.url')}
                            value={url}
                            onChange={handleUrlChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <StyledCopyFieldContainer>
                            <TextField
                                label={i18n.tc('ssoAdmin.siteProducts.fields.productId')}
                                value={props.product.productId}
                                disabled
                                inputRef={inputEl}
                            />

                            <IconButton onClick={handleCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    </StyledSpacedWrapper>

                    <TextField
                        label={i18n.tc('ssoAdmin.siteProducts.fields.type')}
                        disabled
                        value={props.product.type}
                        fullWidth
                    />
                </FormField>
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button onClick={handleEdition} variant="contained" color="primary">
                    {i18n.tc('ssoAdmin.actions.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SiteProductEdition.propTypes = {
    /** The object of the member */
    // eslint-disable-next-line react/no-unused-prop-types
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        clientName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired, // eslint-disable-line react/forbid-prop-types
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    provider: PropTypes.string.isRequired,

    // eslint-disable-next-line react/no-unused-prop-types
    onUpdate: PropTypes.func.isRequired,
    /** Callback when the delete button is clicked */
};

export default SiteProductEdition;
