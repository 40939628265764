import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    styled,
    Autocomplete,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { Check, Clear } from '@mui/icons-material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import { getAllApps, getApps, getAppsCustom, getAppsInstalled } from '../../../../wsClient/AppsManagerWsClient';
import EntityManager from '../../../EntityManager';
import AppAddition from './AppAddition';
import AppEdition from './AppEdition';
import AppDeletion from './AppDeletion';
import Icon from '../../../IconSelector/Icon';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const StyledProductSelector = styled(FormControl)(({ theme }) => ({
    marginRight: theme.spacing(2),
    minWidth: 200,
}));

const StyledEm = styled(`em`)({
    color: grey[700],
});

function groupBy(option) {
    return option.type;
}

function getOptionLabel(option) {
    return option.title;
}

function isOptionEqualToValue(option, value) {
    // eslint-disable-next-line react/prop-types
    return option.value === value.value;
}

const inputLabelProps = {
    shrink: true,
};

function AddRenderer({ productsList, selectedProduct, accessList, apps, onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <AppAddition
            productsList={productsList}
            product={
                selectedProduct !== null
                    ? accessList.find((access) => access.product.productId === selectedProduct.uuid).product
                    : null
            }
            onAdd={onAdd}
            onCancel={onHideDrawer}
            tags={[...new Set(apps.map((app) => app.tags).reduce((acc, val) => acc.concat(val), []))].filter(
                (tag) => tag !== undefined
            )}
            categories={[...new Set(apps.map((app) => app.category).reduce((acc, val) => acc.concat(val), []))].filter(
                (category) => category !== null
            )}
        />
    );
}

function EditRenderer({ productsList, selectedProduct, app, apps, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <AppEdition
            productsList={productsList}
            productUuid={selectedProduct ? selectedProduct.uuid : null}
            app={app}
            tags={[...new Set(apps.map(({ tags }) => tags).reduce((acc, val) => acc.concat(val), []))].filter(
                (tag) => tag !== undefined
            )}
            categories={[
                ...new Set(apps.map(({ category }) => category).reduce((acc, val) => acc.concat(val), [])),
            ].filter((category) => category !== null)}
            onUpdate={onUpdate}
            onCancel={onHideDrawer}
        />
    );
}

function DelRenderer({ selectedProduct, app, apps, onFetch, onHideDrawer, setDeletingApp }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingApp(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingApp]);

    return (
        <AppDeletion
            productUuid={selectedProduct ? selectedProduct.uuid : null}
            app={app}
            tags={[...new Set(apps.map(({ tags }) => tags).reduce((acc, val) => acc.concat(val), []))].filter(
                (tag) => tag !== undefined
            )}
            categories={[
                ...new Set(apps.map(({ category }) => category).reduce((acc, val) => acc.concat(val), [])),
            ].filter((tag) => tag !== null)}
            onDelete={onDelete}
            onCancel={onCancel}
        />
    );
}
/**
 * Apps management
 */
function AppsComponent({ accessList }) {
    const { dispatch, state } = useContext(AppContext);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedType, setSelectedType] = useState('def');
    const [showInstalled, setShowInstalled] = useState(false);
    const [apps, setApps] = useState([]);
    const [deletingApp, setDeletingApp] = useState(null);
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const fetchApps = useCallback(
        (selectedProductId = selectedProduct) => {
            dispatch(setIsFetching(true));

            if (selectedProductId === null) {
                getAllApps()
                    .then(setApps)
                    .finally(() => dispatch(setIsFetching(false)));
            } else {
                const promises = [
                    new Promise((resolve, reject) => {
                        getApps(selectedProductId.uuid).then((response) => {
                            if (response.ok) {
                                response.json().then(resolve);
                            } else {
                                reject();
                            }
                        });
                    }),
                    new Promise((resolve, reject) => {
                        getAppsCustom(selectedProductId.uuid).then((response) => {
                            if (response.ok) {
                                response.json().then(resolve);
                            } else {
                                reject();
                            }
                        });
                    }),
                    new Promise((resolve, reject) => {
                        getAppsInstalled(selectedProductId.uuid).then((response) => {
                            if (response.ok) {
                                response.json().then(resolve);
                            } else {
                                reject();
                            }
                        });
                    }),
                ];

                Promise.all(promises)
                    .then(([available, custom, installed]) => {
                        dispatch(setIsFetching(false));

                        const appInstalledIds = installed.map((version) => version.appId);
                        const appCustomIds = custom.map((app) => app.id);

                        setApps(
                            available.map((app) => {
                                app.installed = appInstalledIds.includes(app.id);
                                app.custom = appCustomIds.includes(app.id);

                                return app;
                            })
                        );
                    })
                    .catch(() => dispatch(setIsFetching(false)));
            }
        },
        [dispatch, selectedProduct]
    );

    const productsList = useMemo(() => {
        return accessList
            .map((access) => ({
                title: access.product.name,
                type: access.product.type,
                uuid: access.product.productId,
            }))
            .sort((a, b) => a.title.localeCompare(b.title))
            .sort((a, b) => a.type.localeCompare(b.type));
    }, [accessList]);

    const handleDelete = useCallback(
        (appId) => {
            const appToDelete = apps.find((app) => app.id === appId);

            if (appToDelete) {
                setDeletingApp(appToDelete);
            }
        },
        [apps]
    );

    useEffect(fetchApps, [fetchApps]);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.apps.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'icon',
                headerName: i18n.tc('ssoAdmin.apps.headerName.colorAndLogo'),
                // eslint-disable-next-line react/prop-types
                renderCell: ({ row }) => <Icon app={row} />,
                flex: 1,
            },
            {
                field: 'description',
                headerName: i18n.tc('ssoAdmin.apps.headerName.description'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'package',
                headerName: i18n.tc('ssoAdmin.apps.headerName.package'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'published',
                headerName: i18n.tc('ssoAdmin.apps.headerName.published'),
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ value }) => (value ? <Check htmlColor={green[500]} /> : <Clear htmlColor={red[300]} />),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    const addRenderer = useCallback(
        (hideDrawers) => (
            <AddRenderer
                productsList={productsList}
                selectedProduct={selectedProduct}
                apps={apps}
                accessList={accessList}
                onHideDrawer={hideDrawers}
                onFetch={fetchApps}
            />
        ),
        [accessList, apps, fetchApps, productsList, selectedProduct]
    );

    const editRenderer = useCallback(
        (app, hideDrawers) => (
            <EditRenderer
                productsList={productsList}
                selectedProduct={selectedProduct}
                apps={apps}
                app={app}
                onFetch={fetchApps}
                onHideDrawer={hideDrawers}
            />
        ),
        [apps, fetchApps, productsList, selectedProduct]
    );

    const delRenderer = useCallback(
        (app, hideDrawers) => (
            <DelRenderer
                selectedProduct={selectedProduct}
                app={app}
                apps={apps}
                setDeletingApp={setDeletingApp}
                onHideDrawer={hideDrawers}
                onFetch={fetchApps}
            />
        ),
        [apps, fetchApps, selectedProduct]
    );

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                label={i18n.tc('ssoAdmin.apps.fields.product.label')}
                placeholder={i18n.tc('ssoAdmin.apps.fields.product.placeholder')}
                InputLabelProps={inputLabelProps}
            />
        ),
        [i18n]
    );

    const handleProductChange = useCallback(
        (e, product) => {
            setSelectedProduct(product);
            fetchApps(product || null);
        },
        [fetchApps]
    );

    const handleTypeChange = useCallback((e) => {
        setSelectedType(e.target.value);
    }, []);

    const handleInstalledChange = useCallback((event, checked) => setShowInstalled(checked), []);

    const switchControl = useMemo(
        () => <Switch checked={showInstalled} onChange={handleInstalledChange} />,
        [handleInstalledChange, showInstalled]
    );

    const filter = useMemo(
        () => (
            <FormGroup row>
                <StyledProductSelector>
                    <Autocomplete
                        renderInput={renderInput}
                        options={productsList}
                        groupBy={groupBy}
                        value={selectedProduct}
                        onChange={handleProductChange}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                    />
                </StyledProductSelector>
                <StyledProductSelector>
                    <InputLabel>{i18n.tc('ssoAdmin.apps.fields.type')}</InputLabel>
                    <Select
                        label={i18n.tc('ssoAdmin.apps.fields.type')}
                        value={selectedType}
                        onChange={handleTypeChange}
                    >
                        <MenuItem disabled value="def">
                            <StyledEm>{i18n.tc('ssoAdmin.apps.selectType')}</StyledEm>
                        </MenuItem>
                        <MenuItem value="all">{i18n.tc('ssoAdmin.apps.allTypes')}</MenuItem>
                        {['BRAINCUBE', 'IOT'].map((type) => (
                            <MenuItem key={type} value={type}>
                                {type.toLowerCase()}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledProductSelector>
                {selectedProduct !== null && (
                    <FormControlLabel control={switchControl} label={i18n.tc('ssoAdmin.apps.fields.installed')} />
                )}
            </FormGroup>
        ),
        [
            handleProductChange,
            handleTypeChange,
            i18n,
            productsList,
            renderInput,
            selectedProduct,
            selectedType,
            switchControl,
        ]
    );

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingApp}
            entities={apps
                .filter((app) => {
                    if (selectedType === 'all' || selectedType === 'def') {
                        return true;
                    }
                    return app.productsTarget.includes(selectedType);
                })
                .filter((app) => {
                    if (showInstalled) {
                        return app.installed;
                    }

                    return true;
                })}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            filter={filter}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.apps.addition.title', { onProduct: '' })}
        />
    );
}

AppsComponent.propTypes = {
    accessList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AppsComponent;
