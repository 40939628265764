import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';

/**
 * This component displays a link to revoke a certificate for a given user.
 */
function RevokeCertificateLink({ email, onCertificateRevoked }) {
    const i18n = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const onGenerationSuccess = useCallback(() => {
        enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.certificateRevokedOK'), { variant: 'success' });
        onCertificateRevoked();
    }, [enqueueSnackbar, i18n, onCertificateRevoked]);

    const onGenerationError = useCallback(() => {
        enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.certificateRevokedNOK'), { variant: 'error' });
    }, [enqueueSnackbar, i18n]);

    const onClick = useCallback(() => {
        new SsoWsClient().revokeCertificate(email, {
            onSuccess: onGenerationSuccess,
            onError: onGenerationError,
        });
    }, [email, onGenerationError, onGenerationSuccess]);

    return (
        <div>
            <Button variant="contained" fullWidth onClick={onClick}>
                {i18n.tc('ssoAdmin.user.actions.revokeCertificate')}
            </Button>
        </div>
    );
}

RevokeCertificateLink.propTypes = {
    /** User identifier. Used to initiate the call to the webservice to revoke the certificate */
    email: PropTypes.string.isRequired,
    /** Callback that is executed when the certificate is successfully revoked */
    onCertificateRevoked: PropTypes.func.isRequired,
};

export default RevokeCertificateLink;
