import { styled } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { EmptyContentMessage } from '@braincube/ui-lab';

const StyledEmptyContentMessageContainer = styled(`div`)({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
});

export function Overlay({ creationLabel, onCreate, ...rest }) {
    return (
        <StyledEmptyContentMessageContainer>
            <EmptyContentMessage intoDataGrid {...rest}>
                {creationLabel && (
                    <Button variant="contained" color="primary" onClick={onCreate}>
                        {creationLabel}
                    </Button>
                )}
            </EmptyContentMessage>
        </StyledEmptyContentMessageContainer>
    );
}

Overlay.propTypes = {
    creationLabel: PropTypes.string,
    onCreate: PropTypes.func,
};

Overlay.defaultProps = {
    creationLabel: null,
    onCreate: () => {},
};

export function NoResultsOverlay() {
    return <Overlay type="NO_RESULT" />;
}

export function NoRowsOverlay(props) {
    if (props.fromQuickSearch) {
        return <NoResultsOverlay />;
    }

    return <Overlay type="NO_DATA" {...props} />;
}
