function fetchWrapper(url, method = 'GET', body = null, headers = {}) {
    const requestHeaders = new Headers(headers);

    if (body) {
        requestHeaders.append('Content-Type', 'application/json');

        return fetch(url, {
            method,
            credentials: 'include',
            headers: requestHeaders,
            body,
        });
    }
    return fetch(url, {
        method,
        credentials: 'include',
        headers: requestHeaders,
    });
}

export function getOffer(offerId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers/${offerId}`, 'GET');
}

export function getOffers() {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers`);
}

export function reloadAkuiteo() {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/akuiteo/reload`, 'POST').then(
        (response) => {
            if (response.ok) {
                return Promise.resolve(response);
            }

            return Promise.reject(response);
        }
    );
}

export function getAkuiteoStatus() {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/akuiteo/status`).then((response) => {
        if (response.ok) {
            return response.json();
        }

        return Promise.reject(response);
    });
}

export function addOffer(offer) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers/create`,
        'POST',
        JSON.stringify(offer)
    );
}

export function deleteOffer(offerId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers/${offerId}`, 'DELETE');
}

export function updateOffer(offerId, offer) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers/${offerId}`,
        'PUT',
        JSON.stringify(offer)
    );
}

export function getApps(productId) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productId}/apps`,
        'GET',
        null,
        { 'x-product-id': productId }
    );
}

export function getAppsCustom(productId) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productId}/apps/custom`,
        'GET',
        null,
        {
            'x-product-id': productId,
        }
    );
}

export function getAppsInstalled(productId) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productId}/apps/installed`,
        'GET',
        null,
        {
            'x-product-id': productId,
        }
    );
}

export function getAppShareInfo(appId, productId) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productId}/share`,
        'GET',
        null,
        {
            'x-product-id': productId,
        }
    );
}

export function getProductInfo(productId) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productId}/info`,
        'GET',
        null,
        {
            'x-product-id': productId,
        }
    );
}

export function getPlatformApps() {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/platformApps`);
}

export function updateAppShareInfo(appId, productId, share) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productId}/share`,
        'PUT',
        JSON.stringify(share),
        {
            'x-product-id': productId,
        }
    );
}

export function addApp(app) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/create`,
        'POST',
        JSON.stringify(app)
    );
}

export function deleteApp(appId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}`, 'DELETE');
}

export function updateApp(appId, app) {
    delete app.rm;
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}`,
        'PUT',
        JSON.stringify(app)
    );
}

export function addPlatformApp(app) {
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/platformApps`,
        'POST',
        JSON.stringify(app)
    );
}

export function updatePlatformApp(appId, app) {
    delete app.rm;
    return fetchWrapper(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/platformApps/${appId}`,
        'PUT',
        JSON.stringify(app)
    );
}

export function deletePlatformApp(appId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/platformApps/${appId}`, 'DELETE');
}

export function getAllApps() {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps`).then((response) => {
        if (response.ok) {
            return response.json();
        }

        return Promise.reject(response);
    });
}

export function getAppVersions(appUuid) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appUuid}/version`).then(
        (response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject(response);
        }
    );
}

export function deleteVersion(versionUuid) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/versions/${versionUuid}`, 'DELETE');
}

export function getApp(appId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}`);
}

export function deployEverywhere(appId) {
    return fetchWrapper(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/deploy`, 'POST');
}
