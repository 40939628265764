import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { createFilterOptions, TextField, Autocomplete, Grid } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledCheckbox } from 'components/GrantList/GrantAddition';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import isASCII from '../../../../services/regex/isASCII';

const createNewGroup = (options, params) => {
    const filter = createFilterOptions();

    const filtered = filter(options, params);
    const value = params.inputValue.trim();

    // Suggest the creation of a new value
    if (typeof value === 'string' && value.length > 1) {
        filtered.push({
            value,
            title: value,
        });
    }

    return filtered;
};

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

const renderOption = (props, option, { selected }) => (
    <div {...props}>
        <StyledCheckbox icon={checkboxIcon} checkedIcon={checkedCheckboxIcon} checked={selected} />
        {option.title}
    </div>
);

function filterOption(options, params) {
    return createNewGroup(options, params);
}

const inputLabelProps = {
    shrink: true,
};

const getOptionLabel = (option) => option.title;

const isOptionEqualToValue = (option, value) => option.value === value.value;

/**
 * Add site management
 */
function SiteAddition({ provider, existingGroups, onAdd, onCancel }) {
    const [groupName, setGroupName] = useState(null);
    const [siteName, setSiteName] = useState('');
    const [idSap, setIdSap] = useState('');
    const [dnsName, setDnsName] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        if (idSap === '' || idSap == null || !isASCII(idSap)) {
            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.idSap.notValid'), { variant: 'error' });
        } else if (dnsName === '' || dnsName == null || !isASCII(dnsName)) {
            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.dnsName.notValid'), { variant: 'error' });
        } else {
            const data = JSON.stringify({
                idsap: idSap.trim(),
                dnsName: dnsName.trim(),
            });

            const groupNameValue = groupName.value;

            new SsoWsClient().createSite(
                // eslint-disable-next-line object-shorthand
                { provider, groupName: groupNameValue, siteName, data },
                {
                    onSuccess: () => {
                        enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.create.success'), { variant: 'success' });

                        setIdSap('');
                        setDnsName('');
                        setGroupName(null);
                        setSiteName('');
                        onAdd();
                    },
                    onError: (brainWsResponse, xhr) => {
                        enqueueSnackbar(xhr.response, { variant: 'error' });
                        onCancel();
                    },
                }
            );
        }
    }, [dnsName, enqueueSnackbar, groupName?.value, i18n, idSap, onAdd, onCancel, provider, siteName]);

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                label={i18n.tc('ssoAdmin.sites.fields.groupName')}
                placeholder={i18n.tc('ssoAdmin.sites.fields.groupName')}
                InputLabelProps={inputLabelProps}
            />
        ),
        [i18n]
    );

    const handleGroupNameChange = useCallback((event, newValue) => {
        setGroupName(newValue);
    }, []);

    const handleSiteNameChange = useCallback((e) => setSiteName(e.target.value), []);

    const handleIdSapChange = useCallback((e) => setIdSap(e.target.value), []);

    const handleDnsNameChange = useCallback((e) => setDnsName(e.target.value), []);

    useEffect(() => {
        if (groupName && siteName) {
            const dnsNameToGenerate = `${groupName.title}${siteName}`;
            setDnsName(
                dnsNameToGenerate
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/[^a-zA-Z0-9]/g, '')
            );
        } else {
            setDnsName('');
        }
    }, [groupName, siteName]);

    return (
        <Addition label={i18n.tc('ssoAdmin.sites.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <Autocomplete
                            filterOptions={filterOption}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            handleHomeEndKeys
                            renderInput={renderInput}
                            options={existingGroups}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={groupName}
                            onChange={handleGroupNameChange}
                            ListboxComponent={ListboxComponent}
                            renderOption={renderOption}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.sites.fields.siteName')}
                            value={siteName}
                            onChange={handleSiteNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            required
                            label={i18n.tc('ssoAdmin.sites.fields.idSap')}
                            value={idSap}
                            onChange={handleIdSapChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        required
                        label={i18n.tc('ssoAdmin.sites.fields.dnsName')}
                        value={dnsName}
                        onChange={handleDnsNameChange}
                        fullWidth
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={handleAddition} label={i18n.t('ssoAdmin.sites.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

SiteAddition.propTypes = {
    provider: PropTypes.string.isRequired,
    existingGroups: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SiteAddition;
