import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, styled } from '@mui/material';

import { getBraincubeUiComponent, appsIcons } from './icons';

const StyledAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
    margin: 'auto',
    cursor: 'pointer',
    border: selected ? `2px solid ${theme.palette.secondary.main}` : 'none',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

function IconItem({ logo, handleChange, selected }) {
    const onChange = useCallback(() => handleChange(logo), [handleChange, logo]);

    return (
        <Grid item xs={2} onClick={onChange}>
            <StyledAvatar selected={selected}>{React.createElement(getBraincubeUiComponent(logo))}</StyledAvatar>
        </Grid>
    );
}

IconItem.propTypes = {
    logo: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    style: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
    }),
};

IconItem.defaultProps = {
    style: null,
};

const StyledIconItem = styled(IconItem)(({ theme, selected, color }) =>
    selected
        ? {
              backgroundColor: color,
              color: theme.palette.getContrastText(color),
          }
        : null
);

function IconSelector({ selectedIcon, color, onChange }) {
    return (
        <Grid container spacing={2}>
            {appsIcons.map((logo) => (
                <StyledIconItem
                    key={logo}
                    logo={logo}
                    handleChange={onChange}
                    selected={logo === selectedIcon}
                    color={color}
                />
            ))}
        </Grid>
    );
}

IconSelector.propTypes = {
    selectedIcon: PropTypes.string,
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

IconSelector.defaultProps = {
    selectedIcon: null,
};

export default IconSelector;
