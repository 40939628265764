import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import Edition from '../../../EntityManager/Edition';
import EditButton from '../../../Buttons/Edit';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit provider management
 */
function ProviderEdition({ provider, onUpdate, onCancel }) {
    const [url, setUrl] = useState(provider.url || '');
    const [vendor, setVendor] = useState(provider.vendor || '');
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setUrl(provider.url || '');
        setVendor(provider.vendor || '');
    }, [provider]);

    const handleEdit = useCallback(() => {
        ssoWs.updateProvider(
            {
                ...provider,
                url,
                vendor,
            },
            provider.name,
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.providers.notistack.update.success'), { variant: 'success' });
                    onUpdate();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [enqueueSnackbar, i18n, onCancel, onUpdate, provider, url, vendor]);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleVendorChange = useCallback((e) => setVendor(e.target.value), []);

    return (
        <Edition label={i18n.tc('ssoAdmin.providers.edition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.providers.fields.name')}
                            disabled
                            value={provider.name}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.providers.fields.url')}
                            value={url}
                            onChange={handleUrlChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        label={i18n.tc('ssoAdmin.providers.fields.vendor')}
                        value={vendor}
                        onChange={handleVendorChange}
                        fullWidth
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </Edition>
    );
}

ProviderEdition.propTypes = {
    provider: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        vendor: PropTypes.string,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProviderEdition;
