import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField, Button, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { FilterList } from '@mui/icons-material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea } from '@braincube/ui-lab';

import { AppContext, setIsFetching } from '../../app-context';
import SsoWsClient from '../../wsClient/SsoWsClient';
import SiteProductAddition from './SiteProductAddition';
import SiteProductEdition from './SiteProductEdition';
import SiteProductDeletion from './SiteProductDeletion';
import CellTooltip from '../CellTooltip';
import { ActionsCell, useDataGridPro } from '../DataGridPro';
import { NoResultsOverlay, NoRowsOverlay } from '../Overlay';

const StyledHeader = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1),
}));

const StyledSearchField = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const components = {
    NoResultsOverlay,
    NoRowsOverlay,
    ColumnFilteredIcon: FilterList,
};

const ssoWs = new SsoWsClient();

const searchInputProps = {
    startAdornment: (
        <InputAdornment position="start">
            <SearchIcon />
        </InputAdornment>
    ),
};

function getRowId({ index }) {
    return index;
}

function SiteProductsList(props) {
    const { dispatch } = useContext(AppContext);
    const [productList, setProductList] = useState([]);
    const [isAddProduct, setIsAddProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [editType, setEditType] = useState(null);
    const i18n = useI18n();
    const { dataGridProLocale, dataGridProClasses } = useDataGridPro();
    const apiRef = useGridApiRef();

    const setToDelete = useCallback((e) => {
        e.preventDefault();
        setEditType('delete');
    }, []);

    const fetchProducts = useCallback(() => {
        ssoWs.getSiteProducts(props.site.siteUuid, {
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setProductList(
                    response.products.map((product) => {
                        product.delete = (
                            <IconButton onClick={setToDelete} size="large">
                                <DeleteIcon />
                            </IconButton>
                        );
                        return product;
                    })
                );
            },
            onError: () => {
                dispatch(setIsFetching(false));
            },
        });
    }, [dispatch, props.site.siteUuid, setToDelete]);

    const onAdd = useCallback(() => {
        fetchProducts();
        setIsAddProduct(false);
    }, [fetchProducts]);

    const handleAdditionClose = useCallback(() => setIsAddProduct(false), []);

    const renderAddProductIfNeeded = useCallback(() => {
        if (isAddProduct === false) {
            return null;
        }

        return (
            <SiteProductAddition
                site={props.site}
                provider={props.provider}
                onAdd={onAdd}
                onClose={handleAdditionClose}
            />
        );
    }, [handleAdditionClose, isAddProduct, onAdd, props.provider, props.site]);

    const handleClose = useCallback(() => {
        setSelectedProduct(null);
        setEditType(null);
    }, []);

    const renderEditProductIfNeeded = useCallback(() => {
        if (selectedProduct === null) {
            return null;
        }
        if (editType === 'delete') {
            const currentProduct = productList[parseInt(selectedProduct, 10)];

            return (
                <SiteProductDeletion
                    product={currentProduct}
                    provider={props.provider}
                    onClose={handleClose}
                    onDelete={fetchProducts}
                />
            );
        }

        const currentProduct = productList[parseInt(selectedProduct, 10)];

        return (
            <SiteProductEdition
                product={currentProduct}
                provider={props.provider}
                onClose={handleClose}
                onUpdate={fetchProducts}
            />
        );
    }, [editType, fetchProducts, handleClose, productList, props.provider, selectedProduct]);

    const handleDelete = useCallback((id) => {
        setSelectedProduct(id);
        setEditType('delete');
    }, []);

    const componentsProps = useMemo(
        () => ({
            noRowsOverlay: {
                onCreate: () => setIsAddProduct(true),
                creationLabel: i18n.tc('ssoAdmin.siteProducts.actions.addProduct'),
                fromQuickSearch: searchValue !== '',
            },
        }),
        [i18n, searchValue]
    );

    const doSearch = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);

    const handleAddition = useCallback(() => setIsAddProduct(true), []);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.siteProducts.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'type',
                headerName: i18n.tc('ssoAdmin.siteProducts.headerName.type'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    const onCellClick = useCallback((cell) => cell.field !== 'actions' && setSelectedProduct(cell.id), []);

    useEffect(fetchProducts, [fetchProducts]);
    const productsWithIndex = cloneDeep(productList);
    productsWithIndex.map((product, index) => {
        product.index = index.toString();
        return product;
    });

    return (
        <DrawerContent>
            <ContentArea>
                <StyledHeader>
                    <StyledSearchField
                        placeholder={!searchValue ? i18n.tc('ssoAdmin.siteProducts.fields.products') : null}
                        value={searchValue}
                        onChange={doSearch}
                        InputProps={searchInputProps}
                    />
                    <Button variant="contained" onClick={handleAddition}>
                        {i18n.tc('ssoAdmin.siteProducts.actions.addProduct')}
                    </Button>
                </StyledHeader>

                <DataGridPro
                    apiRef={apiRef}
                    autoHeight
                    className={dataGridProClasses}
                    columns={columns}
                    rows={productsWithIndex}
                    getRowId={getRowId}
                    disableSelectionOnClick
                    onCellClick={onCellClick}
                    pagination={false}
                    hideFooter
                    localeText={dataGridProLocale}
                    components={components}
                    componentsProps={componentsProps}
                />

                {renderAddProductIfNeeded()}
                {renderEditProductIfNeeded()}
            </ContentArea>
        </DrawerContent>
    );
}

SiteProductsList.propTypes = {
    site: PropTypes.shape({
        siteUuid: PropTypes.string.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    provider: PropTypes.string.isRequired,
};

export default SiteProductsList;
