import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { styled } from '@mui/material';

const StyledColorPickerContainer = styled(`div`)({
    display: 'flex',
    justifyContent: 'center',
});

function SketchPickerWrapper({ color, setColor }) {
    const handleColorChange = useCallback(({ hex }) => setColor(hex), [setColor]);

    return (
        <StyledColorPickerContainer>
            <SketchPicker
                disableAlpha
                presetColors={SketchPicker.defaultProps.presetColors.concat(['#FFDE00', '#CCCCCC', '#B079F0'])}
                color={color}
                onChange={handleColorChange}
                width="60%"
            />
        </StyledColorPickerContainer>
    );
}

SketchPickerWrapper.propTypes = {
    color: PropTypes.string.isRequired,
    setColor: PropTypes.func.isRequired,
};
export default SketchPickerWrapper;
