import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useI18n } from '@braincube/i18n';

function DeleteButton({ onClick, disabled }) {
    const i18n = useI18n();
    const [open, setOpen] = useState(false);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmDelete = useCallback(() => {
        setOpen(false);
        onClick();
    }, [onClick]);

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={disabled}>
                {i18n.tc('ssoAdmin.actions.delete')}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{i18n.tc('ssoAdmin.actions.delete')} ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {i18n.tc('ssoAdmin.actions.confirmDeletion')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        {i18n.tc('ssoAdmin.actions.cancel')}
                    </Button>
                    <Button variant="contained" onClick={confirmDelete} color="primary" autoFocus>
                        {i18n.tc('ssoAdmin.actions.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
    disabled: false,
};

export default DeleteButton;
