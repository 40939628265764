import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../wsClient/SsoWsClient';

const onLogoutSuccess = ({ httpCode }) => {
    if (httpCode === 204) {
        window.location = `https://${window.BC_API_ENDPOINTS_CONF.sso}`;
    }
};

const handleLogout = () => {
    const ssoWs = new SsoWsClient();

    ssoWs.logout({
        onSuccess: onLogoutSuccess,
        onError: () => {
            window.location.replace(window.BC_API_ENDPOINTS_CONF.sso);
        },
    });
};

function Exit() {
    const i18n = useI18n();

    return (
        <ListItem button onClick={handleLogout}>
            <ListItemIcon>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.tc('ssoAdmin.nav.logout')} />
        </ListItem>
    );
}

export default Exit;
