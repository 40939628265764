/* eslint-disable react-perf/jsx-no-new-function-as-prop,react-perf/jsx-no-new-array-as-prop */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EditButton from '../../../Buttons/Edit';
import UsersByRole from './UsersByRole';

const ROLES = ['coach', 'structu', 'sales'];

function Accountant({ product, provider, onClose }) {
    const { enqueueSnackbar } = useSnackbar();
    const [users, setUsers] = useState([]);

    const [coach, setCoach] = useState(null);
    const [structu, setStructu] = useState(null);
    const [sales, setSales] = useState(null);

    const getCorrespondingUser = useCallback(
        (roleType) => (roleType ? users.find((user) => roleType.value === user.email) : null),
        [users]
    );

    const updateAccountant = useCallback(() => {
        const { clientName, siteName, productId } = product;

        Promise.all(
            ROLES.map((role) => {
                return new Promise((resolve, reject) => {
                    let findedUser = null;

                    if (role === 'coach') {
                        findedUser = getCorrespondingUser(coach);
                    }
                    if (role === 'structu') {
                        findedUser = getCorrespondingUser(structu);
                    }
                    if (role === 'sales') {
                        findedUser = getCorrespondingUser(sales);
                    }

                    let accountant = null;

                    if (findedUser) {
                        accountant = {
                            firstName: findedUser.firstName,
                            lastName: findedUser.lastName,
                            uuid: findedUser.uuid,
                        };
                    }

                    new SsoWsClient().updateAccountants(
                        provider,
                        clientName,
                        siteName,
                        productId,
                        { role: role.toUpperCase(), accountant },
                        {
                            onSuccess: () => {
                                resolve();
                                onClose();
                            },
                            onError: reject,
                        }
                    );
                });
            })
        ).then(() => {
            enqueueSnackbar('Accountants have been updated', { variant: 'success' });
        });
    }, [coach, enqueueSnackbar, getCorrespondingUser, onClose, product, provider, sales, structu]);

    useEffect(() => {
        new SsoWsClient().getAllUsers({
            onSuccess: ({ response }) => {
                setUsers(response.users.filter((user) => user.ssoBranch === 'CORPORATE'));
            },
            onError: () => {
                enqueueSnackbar('Unable to retrieve the list of users', { variant: 'error' });
            },
        });
    }, [enqueueSnackbar]);

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <UsersByRole
                        label="coach"
                        provider={provider}
                        product={product}
                        users={users}
                        onChange={setCoach}
                    />
                    <UsersByRole
                        label="structu"
                        provider={provider}
                        product={product}
                        users={users}
                        onChange={setStructu}
                    />
                    <UsersByRole
                        label="sales"
                        provider={provider}
                        product={product}
                        users={users}
                        onChange={setSales}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton onClick={updateAccountant} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Accountant.propTypes = {
    product: PropTypes.shape({
        productId: PropTypes.string.isRequired,
        clientName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
    }).isRequired,
    provider: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Accountant;
