import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import green from '@mui/material/colors/green';
import CheckIcon from '@mui/icons-material/Check';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import { getDistributors } from '../../../../wsClient/LicenceWsClient';
import EntityManager from '../../../EntityManager';
import DistributorAddition from './DistributorAddition';
import DistributorEdition from './DistributorEdition';
import DistributorDeletion from './DistributorDeletion';
import { AppContext, setIsFetching } from '../../../../app-context';
import CellTooltip from '../../../CellTooltip';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import { ActionsCell } from '../../../DataGridPro';

const ssoWs = new SsoWsClient();

function AddRenderer({ siteList, onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <DistributorAddition siteList={siteList} onAdd={onAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ siteList, distributor, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <DistributorEdition
            siteList={siteList}
            distributor={distributor}
            onDelete={onUpdate}
            onUpdate={onUpdate}
            onCancel={onHideDrawer}
        />
    );
}

function DelRenderer({ distributor, setDeletingDistributor, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingDistributor(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingDistributor]);

    return <DistributorDeletion distributor={distributor} onDelete={onDelete} onCancel={onCancel} />;
}
/**
 * Distrib management
 */
function Distributors() {
    const [distributors, setDistributors] = useState({ distributorList: [] });
    const [deletingDistributor, setDeletingDistributor] = useState(null);
    const { dispatch, state } = useContext(AppContext);
    const { user } = useMe();
    const [siteList, setSiteList] = useState([]);
    const apiRef = useGridApiRef();
    const i18n = useI18n();

    const fetchDistribs = useCallback(() => {
        dispatch(setIsFetching(true));

        getDistributors()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then((dists) => {
                dispatch(setIsFetching(false));
                setDistributors(dists);
            })
            .catch(() => {
                dispatch(setIsFetching(false));
            });
    }, [dispatch]);

    const fetchSites = useCallback(() => {
        ssoWs.getSites({
            onSuccess: ({ response }) => {
                setSiteList(response.siteList);
            },
            onError: () => {},
        });
    }, []);

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer siteList={siteList} onFetch={fetchDistribs} onHideDrawer={hideDrawers} />,
        [fetchDistribs, siteList]
    );

    const editRenderer = useCallback(
        (distibutor, hideDrawers) => (
            <EditRenderer
                siteList={siteList}
                distributor={distibutor}
                onFetch={fetchDistribs}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchDistribs, siteList]
    );

    const delRenderer = useCallback(
        (distibutor, hideDrawers) => (
            <DelRenderer
                distributor={distibutor}
                setDeletingDistributor={setDeletingDistributor}
                onFetch={fetchDistribs}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchDistribs]
    );

    const handleDelete = useCallback(
        (distributorId) => {
            const distributorToDelete = distributors.distributorList.find(
                (distributor) => distributor.id === distributorId
            );

            if (distributorToDelete) {
                setDeletingDistributor(distributorToDelete);
            }
        },
        [distributors.distributorList]
    );

    const columns = useMemo(
        () => [
            {
                field: 'key',
                headerName: 'Key',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'externalId',
                headerName: 'Ext. Id',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'allowSmartGeneration',
                headerName: 'Allowed smart generation',
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => (value ? <CheckIcon htmlColor={green[500]} /> : ''),
            },
            {
                field: 'defaultDuration',
                headerName: 'Default duration',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => `${value} days`,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => (
                    <ActionsCell
                        id={id}
                        apiRef={apiRef}
                        onDelete={handleDelete}
                        preventDelete={!state.meIsLicenceAdmin && !user.rootAdmin}
                        preventEdit
                    />
                ),
            },
        ],
        [apiRef, handleDelete, state.meIsLicenceAdmin, user.rootAdmin]
    );

    useEffect(fetchDistribs, [fetchDistribs]);
    useEffect(fetchSites, [fetchSites]);

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingDistributor}
            entities={distributors.distributorList}
            addRenderer={addRenderer}
            canAdd={state.meIsLicenceAdmin || user.rootAdmin}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.distributors.addition.title')}
        />
    );
}

export default Distributors;
