/* eslint-disable react-perf/jsx-no-new-object-as-prop,react-perf/jsx-no-new-function-as-prop,react-perf/jsx-no-new-array-as-prop */
import cloneDeep from 'lodash.clonedeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField, Button, CircularProgress, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterList } from '@mui/icons-material';

import Util from '@braincube/brain-js/lib/util';
import { DrawerContent, ContentArea } from '@braincube/ui-lab';

import { StyledLoading } from 'components/GrantList';

import MemberAddition from './MemberAddition';
import MemberEdition from './MemberEdition';
import CellTooltip from '../CellTooltip';
import { NoResultsOverlay, NoRowsOverlay } from '../Overlay';
import { useDataGridPro } from '../DataGridPro';

/**
 * This method filter entities list with filter value
 *
 * @param {string} filterValue
 * @param {array} allEntities
 * @returns {array} list of filtered entities
 */
export function listFiltering(filterValue, allEntities) {
    const reg = Util.getGlobalRegExp(filterValue, true, true);

    let filteredEntitites = allEntities;

    if (filterValue !== '') {
        filteredEntitites = allEntities.filter((row) => {
            // We only keep lines that have at least one element that matches the regular expression
            return Object.values(row).filter((value) => reg.test(value)).length > 0;
        });
    }

    return filteredEntitites;
}

const StyledHeader = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1),
}));

const StyledSearch = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const components = {
    NoResultsOverlay,
    NoRowsOverlay,
    ColumnFilteredIcon: FilterList,
};

/**
 * This component is used to edit, add or remove groups. It's an EntityManager. It does not call web services: there are
 * callbacks to handle events: handleGroupToAdd, handleGroupToEdit and handleGroupToDelete.
 * It displays groups accorded to entities (user or products).
 */
function GroupList({
    handleMemberToDelete,
    handleMemberToAdd,
    reponseEntityName,
    mapEntities,
    getMembers,
    entityLabel,
    entityKeyName,
    getEntities,
}) {
    const [originalMembers, setOriginalMembers] = useState([]);
    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddMemberDisplayed, setIsAddMemberDisplayed] = useState(false);
    const [selectedMemberIndex, setSelectedMemberIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [entities, setEntities] = useState(null);

    const { enqueueSnackbar } = useSnackbar();
    const { dataGridProLocales, dataGridProClasses } = useDataGridPro();

    const onMembersListError = useCallback(
        (response, xhr) => {
            enqueueSnackbar(`${xhr.status}: ${xhr.responseText}`, { variant: 'error' });
            setIsLoading(false);
        },
        [enqueueSnackbar]
    );

    const onMembersListSuccess = useCallback(
        ({ response }) => {
            setOriginalMembers(response[reponseEntityName]);
            setMembers(response[reponseEntityName]);
            setIsLoading(false);
        },
        [reponseEntityName]
    );

    /** Get members for a group */
    const getMembersForGroup = useCallback(() => {
        getMembers(onMembersListSuccess, onMembersListError);
        setIsLoading(true);
    }, [getMembers, onMembersListError, onMembersListSuccess]);

    const onDeleteSuccess = useCallback(
        (memberToDelete) => {
            enqueueSnackbar(`${entityLabel} ${memberToDelete[entityKeyName]} deleted`, {
                variant: 'success',
            });

            setIsLoading(true);
            getMembersForGroup();
        },
        [enqueueSnackbar, entityKeyName, entityLabel, getMembersForGroup]
    );

    const onError = useCallback(
        (response, xhr) => {
            enqueueSnackbar(xhr.responseText, { variant: 'error' });
        },
        [enqueueSnackbar]
    );

    /**
     * @param {Object} memberToDelete - The deleted member
     */
    const handleMemberDeleting = useCallback(
        (memberToDelete) => {
            handleMemberToDelete(memberToDelete, () => onDeleteSuccess(memberToDelete), onError);
        },
        [handleMemberToDelete, onDeleteSuccess, onError]
    );

    const onAddingSuccess = useCallback(
        (newGroup) => {
            enqueueSnackbar(`${entityLabel} ${newGroup[entityKeyName]} added`, {
                variant: 'success',
            });

            setIsLoading(true);
            getMembersForGroup();
        },
        [enqueueSnackbar, entityKeyName, entityLabel, getMembersForGroup]
    );

    /**
     * @param {Object} newMember - The new member
     */
    const handleMemberAdding = useCallback(
        (newMember) => {
            handleMemberToAdd(newMember, () => onAddingSuccess(newMember), onError);
        },
        [handleMemberToAdd, onAddingSuccess, onError]
    );

    /**
     * Filter allEntities with a regexp
     *
     * @param {string} filterValue - The value returned by the SearchField
     */
    const handleTextFiltering = useCallback(
        (filterValue) => {
            setMembers(listFiltering(filterValue, originalMembers));
            setSearchValue(filterValue);
        },
        [originalMembers]
    );

    const onEntitiesListSuccess = useCallback(
        ({ response }) => {
            const entitiesList = mapEntities(response);

            setEntities(entitiesList);
            setIsLoading(false);
        },
        [mapEntities]
    );

    const onEntitiesListError = useCallback(
        (response, xhr) => {
            setIsLoading(false);
            enqueueSnackbar(`${xhr.status}: ${xhr.responseText}`, { variant: 'error' });
        },
        [enqueueSnackbar]
    );

    /** Get all entities (users or groups) that can be use */
    const getEntitiesForGroup = useCallback(() => {
        getEntities(onEntitiesListSuccess, onEntitiesListError);
        setIsLoading(true);
    }, [getEntities, onEntitiesListError, onEntitiesListSuccess]);

    const renderMemberEditionIfNeeded = useCallback(() => {
        if (selectedMemberIndex === null) {
            return null;
        }

        const currentMember = members[parseInt(selectedMemberIndex, 10)];

        return (
            <MemberEdition
                member={currentMember}
                onClose={() => setSelectedMemberIndex(null)}
                onDelete={handleMemberDeleting}
                entityLabel={entityLabel}
                entityKeyName={entityKeyName}
            />
        );
    }, [entityKeyName, entityLabel, handleMemberDeleting, members, selectedMemberIndex]);

    const renderAddMemberIfNeeded = useCallback(() => {
        if (isAddMemberDisplayed === false) {
            return null;
        }

        const memberedEntities = members.map((member) => member[entityKeyName]);
        const availableEntitiesForAdding = { ...entities };

        memberedEntities.forEach((key) => {
            delete availableEntitiesForAdding[key];
        });

        return (
            <MemberAddition
                onClose={() => setIsAddMemberDisplayed(false)}
                onValidate={handleMemberAdding}
                availableEntitiesForAdding={availableEntitiesForAdding}
                entityLabel={entityLabel}
                entityKeyName={entityKeyName}
            />
        );
    }, [entities, entityKeyName, entityLabel, handleMemberAdding, isAddMemberDisplayed, members]);

    const membersWithIndex = useMemo(
        () =>
            cloneDeep(members).map((member, index) => {
                return {
                    ...member,
                    index: index.toString(),
                };
            }),
        [members]
    );

    const componentsProps = useMemo(
        () => ({
            noRowsOverlay: {
                onCreate: () => setIsAddMemberDisplayed(true),
                creationLabel: `Add ${entityLabel}`,
                fromQuickSearch: searchValue !== '',
            },
        }),
        [entityLabel, searchValue]
    );

    useEffect(() => {
        getMembersForGroup();
        getEntitiesForGroup();
    }, [getEntitiesForGroup, getMembersForGroup]);

    if (isLoading) {
        return (
            <StyledLoading>
                <CircularProgress />
            </StyledLoading>
        );
    }

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <StyledHeader>
                        <StyledSearch
                            placeholder={!searchValue ? entityLabel : null}
                            value={searchValue}
                            onChange={(event) => handleTextFiltering(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button variant="contained" onClick={() => setIsAddMemberDisplayed(true)}>
                            {`Add ${entityLabel}`}
                        </Button>
                    </StyledHeader>

                    <DataGridPro
                        autoHeight
                        columns={[
                            {
                                field: entityKeyName,
                                headerName: entityKeyName,
                                flex: 1,
                                // eslint-disable-next-line react/prop-types
                                renderCell: ({ value }) => <CellTooltip value={value} />,
                            },
                        ]}
                        rows={membersWithIndex}
                        getRowId={({ index }) => index}
                        disableColumnMenu
                        disableColumnReorder
                        disableSelectionOnClick
                        onCellClick={(cell) => setSelectedMemberIndex(cell.id)}
                        pagination={false}
                        hideFooter
                        className={dataGridProClasses}
                        localeText={dataGridProLocales}
                        components={components}
                        componentsProps={componentsProps}
                    />
                </ContentArea>
            </DrawerContent>
            {renderMemberEditionIfNeeded()}
            {renderAddMemberIfNeeded()}
        </>
    );
}

GroupList.propTypes = {
    /**
     * This function takes a response as a parameter, and it should return a map which keys are the entity IDs and values are
     * what should be displayed.
     */
    mapEntities: PropTypes.func.isRequired,
    /**
     * This method is called when the component wants to refresh its members list.
     * It is passed 2 callbacks that must be called in case of success or error, and it has to return the request.
     */
    getMembers: PropTypes.func.isRequired,
    /** Key of a member that should be displayed */
    entityKeyName: PropTypes.string.isRequired,
    /** Label of the entity (such as member or group name), depending on what is the entityKeyName and what mapEntities returns */
    entityLabel: PropTypes.string.isRequired,
    /**
     * This method is called to get all entities (users or group) that can be used to
     * adding or editing groups.
     */
    getEntities: PropTypes.func.isRequired,
    /** This function is called when a member is added through the interface */
    handleMemberToAdd: PropTypes.func.isRequired,
    /** This function is called when a member is deleted through the interface */
    handleMemberToDelete: PropTypes.func.isRequired,
    /** This function is called when a member is deleted through the interface */
    reponseEntityName: PropTypes.string.isRequired,
};

export default GroupList;
