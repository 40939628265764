import { styled } from '@mui/material';

export const StyledTitle = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '& > svg': {
        margin: theme.spacing(0, 1, 0, 1),
    },
}));

export const StyledDialogTitle = styled(StyledTitle)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});
