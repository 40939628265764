/**
 * Rendering mode.
 * @enum {string}
 */
const RenderingMode = {
    SELECTOR: 'selector',
    SUMMARY: 'summary',
    EXTENDED: 'extended',
};

/**
 * Display type.
 * @enum {string}
 */
const DisplayType = {
    TAG: 'TAG',
    LOCAL: 'LOCAL',
    STANDARD: 'STANDARD',
};

/**
 * Chart type.
 * @enum {string}
 */
const ChartType = {
    INDEXPLOT: 'INDEXPLOT',
    LOADPLOT: 'LOADPLOT',
    TIMEPLOT: 'TIMEPLOT',
    MULTIPLOT: 'MULTIPLOT',
    HISTOGRAM: 'HISTOGRAM',
    BOXPLOT: 'BOXPLOT',
    HYPERLIFT: 'HYPERLIFT',
    AGGREGATEPLOT: 'AGGREGATEPLOT',
};

/**
 * DataType
 * @enum {string}
 */
const DataType = {
    DATETIME: 'DATETIME',
    DISCRETE: 'DISCRETE',
    NUMERICAL: 'NUMERICAL',
};

/**
 * ControlType
 * @enum {string}
 */
const ControlType = {
    INDICATOR: 'INDICATOR',
    SETTING: 'SETTING',
    OBJECTIVE: 'OBJECTIVE',
};

/**
 * Enum of functions that can be used in the Braincube Web Service.
 * @enum {string}
 */
const FunctionType = {
    VALUE: 'VALUE',
    MAVG: 'MAVG',
    MRANGE: 'MRANGE',
    CUSUM_PERSO: 'CUSUM_PERSO',
    CUSUM_AUTO: 'CUSUM_AUTO',
    SIGMA: 'SIGMA',
    MINIMUM: 'MINIMUM',
    MAXIMUM: 'MAXIMUM',
    AVERAGE: 'AVERAGE',
    MEDIAN: 'MEDIAN',
    LINEAR_REGRESSION: 'LINEAR_REGRESSION',
    GAUSSIAN: 'GAUSSIAN',
    LINEMARKER: 'LINEMARKER',
    RANGEMARKER: 'RANGEMARKER',
    PICTURE: 'PICTURE',
    COEFFICIENT_VARIATION: 'COEFFICIENT_VARIATION',
    EXPONENTIAL_TREND: 'EXPONENTIAL_TREND',
    LOGARITHMIC_TREND: 'LOGARITHMIC_TREND',
    POWER_TREND: 'POWER_TREND',
    SPECIFICATION: 'SPECIFICATION',
};

/**
 * ActivationType
 * @enum {string}
 */
const ActivationType = {
    TARGET: 'TARGET',
    ACTIVATED: 'ACTIVATED',
    DESACTIVATED: 'DESACTIVATED',
};

/**
 * BraindataViewType
 * @enum {string}
 */
const BraindataViewType = {
    SIMPLE: 'simple',
    FULL: 'full',
    EXTENDED: 'extended',
};

/**
 * Webservices available
 * @enum {string}
 */
const Webservices = {
    BRAINDATA: 'braindata',
    BRAINCUBE: 'braincube',
    LIVE: 'live',
    LOG: 'log',
    PREFERENCES: 'preferences',
};

/**
 * http methods used in Braincube webservices
 * @enum {string}
 */
const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

/**
 * Data resolution type
 * Use low or high frequency data type.
 * @enum {string}
 */
const FrequencyResolution = Object.freeze({
    LF: 'LF',
    HF: 'HF',
});

/**
 * HF Periods
 * @enum
 * @type {Readonly<{RUN: string, DAY: string, EIGHT_HOURS: string, FOUR_HOURS: string, TWO_HOURS: string, HOUR: string}>}
 */
const HfPeriods = Object.freeze({
    RUN: 'RUN',
    DAY: 'DAY',
    EIGHT_HOURS: 'EIGHT_HOURS',
    FOUR_HOURS: 'FOUR_HOURS',
    TWO_HOURS: 'TWO_HOURS',
    HOUR: 'HOUR',
});

/**
 * Lf Periods
 * @enum
 * @type {Readonly<{ALL: string, YEAR: string, YEAR_TO_DAY: string, QUARTER: string, MONTH: string, WEEK: string}>}
 */
const LfPeriods = Object.freeze({
    ALL: 'ALL',
    YEAR: 'YEAR',
    YEAR_TO_DAY: 'YTD',
    QUARTER: 'QUARTER',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
});

/**
 * Live Views using only HF resolution.
 * @enum
 * @type {Readonly<{DIALS: string, RULES: string}>}
 */
const HfViews = Object.freeze({
    DIALS: 'dials',
    RULES: 'rules',
});

/**
 * Live Views using both HF and LF data resolutions.
 * @enum
 * @type {Readonly<{TABLE: string, CHARTS: string, CHART: string}>}
 */
const LfHfViews = Object.freeze({
    TABLE: 'table',
    CHARTS: 'charts',
    CHART: 'chart',
});

export default {
    ActivationType,
    BraindataViewType,
    ChartType,
    ControlType,
    DataType,
    DisplayType,
    FunctionType,
    HttpMethods,
    RenderingMode,
    Webservices,
    FrequencyResolution,
    HfPeriods,
    LfPeriods,
    HfViews,
    LfHfViews,
};
