import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { debounce } from 'debounce';
import { useSnackbar } from 'notistack';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { green } from '@mui/material/colors';
import { InputAdornment, TextField, CircularProgress, styled } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { FilterList } from '@mui/icons-material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea } from '@braincube/ui-lab';

import RequestsManager from 'wsClient/BrainWsRequestManager';

import { listFiltering } from '../GroupList';
import GrantEdition from './GrantEdition';
import GrantAddition from './GrantAddition';
import GrantDelete from './GrantDelete';
import CellTooltip from '../CellTooltip';
import { ActionsCell, useDataGridPro } from '../DataGridPro';
import { NoResultsOverlay, NoRowsOverlay } from '../Overlay';

const StyledHeader = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1),
}));

const StyledSearch = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

/** ID of the request that fetches user grants. */
const GRANTS_LIST_REQUEST_ID = 'grantsList';

/** ID of the request that fetches entities. */
const ENTITIES_LIST_REQUEST_ID = 'entitiesList';

const requestsManager = new RequestsManager();

const components = {
    NoResultsOverlay,
    NoRowsOverlay,
    ColumnFilteredIcon: FilterList,
};

export const StyledLoading = styled('div')({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItem: 'center',
    justifyContent: 'center',
});

const inputProps = {
    startAdornment: (
        <InputAdornment position="start">
            <SearchIcon />
        </InputAdornment>
    ),
};

function getRowId({ index }) {
    return index;
}

/**
 * This component is used to edit, add or remove grants. It's an EntityManager. It does not call web services: there are
 * callbacks to handle events: handleGrantToAdd, handleGrantToEdit and handleGrantToDelete.
 * It displays grants accorded to entities (user or products).
 */
// eslint-disable-next-line max-statements
function GrantList({
    handleGrantToAdd,
    handleGrantToEdit,
    handleGrantToDelete,
    entityKeyName,
    mapEntities,
    getGrants: getGrantsFn,
    getEntities: getEntitiesFn,
    grantLabelFunction,
    entityLabel,
    productType,
    currentUserEmail,
}) {
    const [originalGrants, setOriginalGrants] = useState([]);
    const [grants, setGrants] = useState([]);
    const [entities, setEntities] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedGrantIndex, setSelectedGrantIndex] = useState(null);
    const [isAddGrantDisplayed, setIsAddGrantDisplayed] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [editType, setEditType] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { dataGridProClasses, dataGridProLocale } = useDataGridPro();
    const apiRef = useGridApiRef();
    const i18n = useI18n();

    const debounceSetGrants = debounce(
        (value, original = originalGrants) => setGrants(listFiltering(value, original)),
        200
    );

    const onGrantDelete = useCallback((e) => {
        e.preventDefault();
        setEditType('delete');
    }, []);

    const onGrantsListSuccess = useCallback(
        (accessList) => {
            setOriginalGrants(accessList);
            debounceSetGrants(
                searchValue,
                accessList.map((grant) => {
                    if (grant.expireDate) {
                        grant.isTemporary = <CheckIcon htmlColor={green[500]} />;
                    }

                    grant.delete = (
                        <IconButton onClick={onGrantDelete} size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                    return grant;
                }),
                accessList
            );
            setIsLoading(requestsManager.setTerminated(GRANTS_LIST_REQUEST_ID));
        },
        // eslint-disable-next-line
        [searchValue]
    );

    const onGrantsListError = useCallback(
        (response, xhr) => {
            setIsLoading(requestsManager.setTerminated(GRANTS_LIST_REQUEST_ID));
            enqueueSnackbar(`${xhr.status}: ${xhr.responseText}`, { variant: 'error' });
        },
        [enqueueSnackbar]
    );

    const getGrants = useCallback(() => {
        const request = getGrantsFn(onGrantsListSuccess, onGrantsListError);
        requestsManager.setPending(GRANTS_LIST_REQUEST_ID, request);
    }, [getGrantsFn, onGrantsListError, onGrantsListSuccess]);

    const onEntitiesListSuccess = useCallback(
        ({ response }) => {
            setEntities(mapEntities(response));
            setIsLoading(requestsManager.setTerminated(ENTITIES_LIST_REQUEST_ID));
        },
        [mapEntities]
    );

    const onEntitiesListError = useCallback(
        (response, xhr) => {
            setIsLoading(requestsManager.setTerminated(ENTITIES_LIST_REQUEST_ID));
            enqueueSnackbar(`${xhr.status}: ${xhr.responseText}`, { variant: 'error' });
        },
        [enqueueSnackbar]
    );

    /** Get all entities (products or users) that can be associated to an access type for adding grants */
    const getEntities = useCallback(() => {
        const request = getEntitiesFn(onEntitiesListSuccess, onEntitiesListError);
        requestsManager.setPending(ENTITIES_LIST_REQUEST_ID, request);
    }, [getEntitiesFn, onEntitiesListError, onEntitiesListSuccess]);

    useEffect(() => {
        if (grants.length === 0 && Object.keys(entities).length === 0) {
            getGrants();
            getEntities();
        }
    }, [entities, getEntities, getGrants, grants.length]);

    const onAddingSuccess = useCallback(
        (newGrant) => {
            enqueueSnackbar(i18n.tc('ssoAdmin.grant.notifications.created', { grant: newGrant[entityKeyName] }), {
                variant: 'success',
            });
            setIsLoading(true);
            getGrants();
        },
        [enqueueSnackbar, entityKeyName, getGrants, i18n]
    );

    const onEditSuccess = useCallback(
        (grantUpdated) => {
            enqueueSnackbar(i18n.tc('ssoAdmin.grant.notifications.updated', { grant: grantUpdated[entityKeyName] }), {
                variant: 'success',
            });
            getGrants();
        },
        [enqueueSnackbar, entityKeyName, getGrants, i18n]
    );

    const onDeleteSuccess = useCallback(
        (grantToDelete) => {
            enqueueSnackbar(i18n.tc('ssoAdmin.grant.notifications.deleted', { grant: grantToDelete[entityKeyName] }), {
                variant: 'success',
            });
            getGrants();
        },
        [enqueueSnackbar, entityKeyName, getGrants, i18n]
    );

    const onError = useCallback(
        (response, xhr) => {
            enqueueSnackbar(xhr.responseText, { variant: 'error' });
        },
        [enqueueSnackbar]
    );

    /**
     * @param {Object} newGrant - The new grant
     */
    const handleGrantAdding = useCallback(
        (newGrant) => {
            const entityToAdd = { ...newGrant };

            handleGrantToAdd(entityToAdd, () => onAddingSuccess(newGrant), onError);
        },
        [handleGrantToAdd, onAddingSuccess, onError]
    );

    /**
     * @param {Object} grantUpdated - The amended grant
     */
    const handleGrantEditing = useCallback(
        (grantUpdated) => {
            handleGrantToEdit(grantUpdated, () => onEditSuccess(grantUpdated), onError);
        },
        [handleGrantToEdit, onEditSuccess, onError]
    );

    /**
     * @param {Object} grantToDelete - The deleted grant
     */
    const handleGrantDeleting = useCallback(
        (grantToDelete) => {
            handleGrantToDelete(grantToDelete, () => onDeleteSuccess(grantToDelete), onError);
        },
        [handleGrantToDelete, onDeleteSuccess, onError]
    );

    const onGrantManagementClose = useCallback(() => {
        setSelectedGrantIndex(null);
        setEditType(null);
    }, []);

    const renderGrantEditionIfNeeded = () => {
        if (selectedGrantIndex === null) {
            return null;
        }
        if (editType === 'delete') {
            const currentGrant = grants[parseInt(selectedGrantIndex, 10)];

            return (
                <GrantDelete
                    grant={currentGrant}
                    grantValue={grantLabelFunction(entities[currentGrant[entityKeyName]])}
                    onClose={onGrantManagementClose}
                    onDelete={handleGrantDeleting}
                    entityLabel={entityLabel}
                />
            );
        }

        const currentGrant = grants[parseInt(selectedGrantIndex, 10)];

        return (
            <GrantEdition
                grant={currentGrant}
                grantValue={grantLabelFunction(entities[currentGrant[entityKeyName]])}
                onClose={onGrantManagementClose}
                onValidate={handleGrantEditing}
                entityLabel={entityLabel}
                productType={productType}
            />
        );
    };

    const onCloseGrantAddition = useCallback(() => setIsAddGrantDisplayed(false), []);

    const entitiesCopy = useMemo(() => ({ ...entities }), [entities]);

    const renderAddGrantIfNeeded = useCallback(() => {
        if (isAddGrantDisplayed === false) {
            return null;
        }

        const grantedEntities = grants.map((grant) => grant[entityKeyName]);
        const availableEntitiesForAdding = entitiesCopy;

        grantedEntities.forEach((key) => {
            delete availableEntitiesForAdding[key];
        });

        return (
            <GrantAddition
                currentUserEmail={currentUserEmail}
                onClose={onCloseGrantAddition}
                onValidate={handleGrantAdding}
                labelFunction={grantLabelFunction}
                availableEntitiesForAdding={availableEntitiesForAdding}
                entityLabel={entityLabel}
                entityKeyName={entityKeyName}
                productType={productType}
            />
        );
    }, [
        currentUserEmail,
        entitiesCopy,
        entityKeyName,
        entityLabel,
        grantLabelFunction,
        grants,
        handleGrantAdding,
        isAddGrantDisplayed,
        onCloseGrantAddition,
        productType,
    ]);

    const handleDelete = useCallback((id) => {
        setSelectedGrantIndex(id);
        setEditType('delete');
    }, []);

    const componentsProps = useMemo(
        () => ({
            noRowsOverlay: {
                onCreate: () => setIsAddGrantDisplayed(true),
                creationLabel: i18n.tc('ssoAdmin.grant.actions.add'),
                fromQuickSearch: searchValue !== '',
            },
        }),
        [i18n, searchValue]
    );

    const handleGrantsSearch = useCallback(
        (event) => {
            setSearchValue(event.target.value);
            debounceSetGrants(event.target.value);
        },
        [debounceSetGrants]
    );

    const onGrantAddition = useCallback(() => setIsAddGrantDisplayed(true), []);

    const columns = useMemo(
        () => [
            {
                field: entityKeyName,
                headerName: entityLabel,
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'accessType',
                headerName: i18n.tc('ssoAdmin.grant.fields.accessType'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'isTemporary',
                headerName: i18n.tc('ssoAdmin.grant.fields.temporary'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, entityKeyName, entityLabel, handleDelete, i18n]
    );

    const onCellClick = useCallback((cell) => cell.field !== 'actions' && setSelectedGrantIndex(cell.id), []);

    if (isLoading) {
        return (
            <StyledLoading>
                <CircularProgress />
            </StyledLoading>
        );
    }

    const grantsWithIndex = cloneDeep(grants);

    grantsWithIndex.map((grant, index) => {
        grant[entityKeyName] = grantLabelFunction(entities[grant[entityKeyName]]);
        grant.index = index.toString();
        return grant;
    });

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <StyledHeader>
                        <StyledSearch
                            placeholder={!searchValue ? i18n.tc('ssoAdmin.grant.fields.grants') : null}
                            value={searchValue}
                            onChange={handleGrantsSearch}
                            InputProps={inputProps}
                        />
                        <Button variant="contained" onClick={onGrantAddition}>
                            {i18n.tc('ssoAdmin.grant.actions.add')}
                        </Button>
                    </StyledHeader>
                    <DataGridPro
                        apiRef={apiRef}
                        columns={columns}
                        className={dataGridProClasses}
                        autoHeight
                        rows={grantsWithIndex}
                        getRowId={getRowId}
                        disableSelectionOnClick
                        onCellClick={onCellClick}
                        pagination={false}
                        hideFooter
                        localeText={dataGridProLocale}
                        components={components}
                        componentsProps={componentsProps}
                    />
                </ContentArea>
            </DrawerContent>
            {renderGrantEditionIfNeeded()}
            {renderAddGrantIfNeeded()}
        </>
    );
}

GrantList.propTypes = {
    /**
     * This function takes a response as a parameter, and it should return a map which keys are the entity IDs and values are
     * what should be displayed.
     */
    mapEntities: PropTypes.func.isRequired,
    /** Key of a grant that should be displayed */
    entityKeyName: PropTypes.string.isRequired,
    /**
     * Label of the entity (such as Email or Product Name), depending on what is the entityKeyName and what mapEntities
     * returns
     */
    entityLabel: PropTypes.string.isRequired,
    /**
     * This method is called when the component wants to refresh its grants list.
     * It is passed 2 callbacks that must be called in case of success or error, and it has to return the request.
     */
    getGrants: PropTypes.func.isRequired,
    /**
     * Grant label function: will return a string describing the grant for display in a AutoComplete and use in all areas
     * where the label is needed. Optional, defaults to tostring
     */
    grantLabelFunction: PropTypes.func,
    /**
     * This method is called to get all entities (products or users) that can be associated to an access type for
     * adding or editing grants.
     */
    getEntities: PropTypes.func.isRequired,
    /** This function is called when a grant is added through the interface */
    handleGrantToAdd: PropTypes.func.isRequired,
    /** This function is called when a grant is edited through the interface */
    handleGrantToEdit: PropTypes.func.isRequired,
    /** This function is called when a grant is deleted through the interface */
    handleGrantToDelete: PropTypes.func.isRequired,
    productType: PropTypes.string,
    /** The selected user on which the products will be granted */
    currentUserEmail: PropTypes.string,
};

GrantList.defaultProps = {
    grantLabelFunction: (item) => item?.toString() || '',
    productType: null,
    currentUserEmail: '',
};

export default GrantList;
