import { fetchWithBearer } from '@braincube/jwt-browser';

function handleResponseStatus(response) {
    if (response.ok) {
        return Promise.resolve(response);
    }
    return Promise.reject(response);
}

export function createConsumer({ siteUuid, siteName }) {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.api}/apigateway/api/consumers`, {
        method: 'POST',
        body: JSON.stringify({
            siteUuid,
            name: siteName,
        }),
    })
        .then(handleResponseStatus)
        .then((response) => response.json());
}

export function getConsumers() {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.api}/apigateway/api/consumers`, { method: 'GET' })
        .then(handleResponseStatus)
        .then((response) => response.json());
}

export function getConsumer(consumerId) {
    return fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.api}/apigateway/api/consumers/${encodeURIComponent(consumerId)}`,
        {
            method: 'GET',
        }
    )
        .then(handleResponseStatus)
        .then((response) => response.json());
}

export function activatePlan({ consumerId }) {
    return fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.api}/apigateway/api/consumers/${encodeURIComponent(consumerId)}/plans`,
        {
            method: 'POST',
        }
    )
        .then(handleResponseStatus)
        .then((response) => response.json());
}
