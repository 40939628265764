import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormControl, InputLabel, MenuItem, Select, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import ACCESS_TYPES from 'components/GrantList/accessTypes';

import { updatePlatformApp } from '../../../../../wsClient/AppsManagerWsClient';
import SsoWsClient from '../../../../../wsClient/SsoWsClient';
import EditButton from '../../../../Buttons/Edit';
import ListboxComponent from '../../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../../StyledComponents';

const SHARE_TYPES = ['PUBLIC', 'PRIVATE', 'CONTROLLED'];

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function Share({ app, onUpdate, onCancel }) {
    const [share, setShare] = useState(null);
    const [users, setUsers] = useState([]);
    const none = ['NONE'];
    const accessTypes = Object.values(ACCESS_TYPES).concat(none);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setShare({ shareLevel: app.shareLevel, controlledLevel: app.controlledLevel, usersUuid: app.usersUuid });
    }, [app]);

    useEffect(() => {
        new SsoWsClient().getAllUsers({
            onSuccess: (usersResponse) => {
                setUsers(usersResponse.response.users);
            },
            onError: () => {},
        });
    }, []);

    const handleEdit = useCallback(() => {
        if (share.shareLevel === 'CONTROLLED' && share.controlledLevel === 'NONE') {
            share.controlledLevel = null;
        }

        if (['PUBLIC', 'PRIVATE'].includes(share.shareLevel)) {
            share.controlledLevel = null;
            share.usersUuid = null;
        }

        updatePlatformApp(app.id, { ...app, ...share })
            .then((response) => {
                if (response.ok) {
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .then(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.share.notistack.update.success'), { variant: 'success' });
                onUpdate();
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.share.notistack.update.error'), { variant: 'error' });
                onCancel();
            });
    }, [app, enqueueSnackbar, i18n, onCancel, onUpdate, share]);

    const handleTypeChange = useCallback((e) => setShare({ ...share, shareLevel: e.target.value }), [share]);

    const handleProfileChange = useCallback((e) => setShare({ ...share, controlledLevel: e.target.value }), [share]);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.share.fields.byUsers')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const shareValue = useMemo(() => {
        if (!share) {
            return [];
        }

        return share?.usersUuid === null
            ? []
            : share?.usersUuid.map((uuid) => {
                  const finded = users.find((user) => user.uuid === uuid);

                  return {
                      value: finded.uuid,
                      label: finded.email,
                  };
              });
    }, [share, users]);

    const handleShareChange = useCallback(
        (event, newValue) => {
            setShare({
                ...share,
                usersUuid: newValue.map((user) => user.value),
            });
        },
        [share]
    );

    if (share === null) {
        return null;
    }

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('ssoAdmin.share.fields.type')}</InputLabel>
                            <Select
                                label={i18n.tc('ssoAdmin.share.fields.type')}
                                value={SHARE_TYPES.find((shareType) => shareType === share.shareLevel) || ''}
                                onChange={handleTypeChange}
                            >
                                {SHARE_TYPES.map((shareType) => (
                                    <MenuItem key={shareType} value={shareType}>
                                        {shareType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>
                    {share.shareLevel === 'CONTROLLED' && (
                        <>
                            <StyledSpacedWrapper>
                                <FormControl fullWidth>
                                    <InputLabel>By profile</InputLabel>
                                    <Select
                                        label="By profile"
                                        value={share.controlledLevel || 'NONE'}
                                        onChange={handleProfileChange}
                                    >
                                        {accessTypes.map((shareType) => (
                                            <MenuItem key={shareType} value={shareType}>
                                                {shareType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </StyledSpacedWrapper>
                            {users.length > 0 && (
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={users.map((user) => ({
                                        value: user.uuid,
                                        label: user.email,
                                    }))}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={shareValue}
                                    onChange={handleShareChange}
                                />
                            )}
                        </>
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Share.propTypes = {
    app: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default Share;
