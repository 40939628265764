import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Button, Fab } from '@mui/material';

import { apiRefPropTypes } from '../../services';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
}));

function RowCreator({ label, apiRef, noFab }) {
    const {
        current: { updateRows, setRowMode, scrollToIndexes, getRowsCount, setCellFocus },
    } = apiRef;

    const handleAddRow = useCallback(() => {
        const id = uuidv4();

        updateRows([{ id, isNew: true }]);
        setRowMode(id, 'edit');

        // Wait for the grid to render with the new row
        setTimeout(() => {
            scrollToIndexes({
                rowIndex: getRowsCount() - 1,
            });

            setCellFocus(id, 'name');
        }, 150);
    }, [getRowsCount, scrollToIndexes, setCellFocus, setRowMode, updateRows]);

    return noFab ? (
        <Button variant="contained" color="primary" onClick={handleAddRow} data-testid="dataGrid-addRow">
            {label}
        </Button>
    ) : (
        <Root>
            <Fab variant="extended" color="primary" onClick={handleAddRow} data-testid="dataGrid-addRow">
                {label}
            </Fab>
        </Root>
    );
}

RowCreator.propTypes = {
    label: PropTypes.string.isRequired,
    apiRef: apiRefPropTypes.isRequired,
    noFab: PropTypes.bool,
};

RowCreator.defaultProps = {
    noFab: false,
};

export default RowCreator;
