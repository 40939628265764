import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormControl, InputLabel, MenuItem, Select, styled, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import ACCESS_TYPES from 'components/GrantList/accessTypes';

import { getAppShareInfo, updateAppShareInfo } from '../../../../../wsClient/AppsManagerWsClient';
import SsoWsClient from '../../../../../wsClient/SsoWsClient';
import EditButton from '../../../../Buttons/Edit';
import ListboxComponent from '../../../../ListboxComponent';

const SHARE_TYPES = ['PUBLIC', 'PRIVATE', 'CONTROLLED'];

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
}));

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} {...props} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function Share({ appId, productUuid }) {
    const [share, setShare] = useState(null);
    const [users, setUsers] = useState([]);
    const none = ['NONE'];
    const accessTypes = Object.values(ACCESS_TYPES).concat(none);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        getAppShareInfo(appId, productUuid).then((response) => {
            if (response.ok) {
                response.json().then(setShare);
            }
        });
    }, [appId, productUuid]);

    useEffect(() => {
        new SsoWsClient().getAllUsers({
            onSuccess: (usersResponse) => {
                setUsers(usersResponse.response.users);
            },
            onError: () => {},
        });
    }, []);

    const handleEdit = useCallback(() => {
        if (share.shareLevel === 'CONTROLLED' && share.controlledLevel === 'NONE') {
            share.controlledLevel = null;
        }

        if (['PUBLIC', 'PRIVATE'].includes(share.shareLevel)) {
            share.controlledLevel = null;
            share.usersUuid = null;
        }

        updateAppShareInfo(appId, productUuid, share)
            .then((response) => {
                if (response.ok) {
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .then(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.share.notistack.update.success'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.share.notistack.update.error'), { variant: 'error' });
            });
    }, [appId, enqueueSnackbar, i18n, productUuid, share]);

    const handleShareChange = useCallback((e) => setShare({ ...share, shareLevel: e.target.value }), [share]);

    const handleProfileChange = useCallback((e) => setShare({ ...share, controlledLevel: e.target.value }), [share]);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.share.fields.byUsers')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const shareValue = useMemo(() => {
        if (!share) {
            return [];
        }

        return share.usersUuid === null
            ? []
            : share.usersUuid.map((uuid) => {
                  const finded = users.find((user) => user.uuid === uuid);

                  return {
                      value: finded.uuid,
                      label: finded.email,
                  };
              });
    }, [share, users]);

    const handleUsersChange = useCallback(
        (event, newValue) => {
            setShare({
                ...share,
                usersUuid: newValue.map((user) => user.value),
            });
        },
        [share]
    );

    if (share === null) {
        return null;
    }

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <StyledFormControl fullWidth>
                        <InputLabel>{i18n.tc('ssoAdmin.share.fields.type')}</InputLabel>
                        <Select
                            label={i18n.tc('ssoAdmin.share.fields.type')}
                            value={SHARE_TYPES.find((shareType) => shareType === share.shareLevel)}
                            onChange={handleShareChange}
                        >
                            {SHARE_TYPES.map((shareType) => (
                                <MenuItem key={shareType} value={shareType}>
                                    {shareType}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                    {share.shareLevel === 'CONTROLLED' && (
                        <>
                            <StyledFormControl fullWidth>
                                <InputLabel>{i18n.tc('ssoAdmin.share.fields.byProfile')}</InputLabel>
                                <Select
                                    label={i18n.tc('ssoAdmin.share.fields.byProfile')}
                                    value={share.controlledLevel || 'NONE'}
                                    onChange={handleProfileChange}
                                >
                                    {accessTypes.map((shareType) => (
                                        <MenuItem key={shareType} value={shareType}>
                                            {shareType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>
                            {users.length > 0 && (
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={users.map((user) => ({
                                        value: user.uuid,
                                        label: user.email,
                                    }))}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={shareValue}
                                    onChange={handleUsersChange}
                                />
                            )}
                        </>
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Share.propTypes = {
    appId: PropTypes.string.isRequired,
    productUuid: PropTypes.string.isRequired,
};

export default Share;
