import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import FormField from '../../../FormField';
import { StyledSpacedWrapper } from '../../../StyledComponents';

/**
 * This component displays a link to generate a certificate for a given user.
 * After the user has clicked the link, a popin invites him to give a password for encrypting the certificate container.
 */
function GenerateCertificateLink({ email, onCertificateGenerated }) {
    const i18n = useI18n();
    /** Indicates if the password popin is visible. */
    const [showPopin, setShowPopin] = useState(false);
    /** User password. */
    const [password, setPassword] = useState('');
    /** Password confirmed by the user. */
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const onClick = useCallback(() => {
        setShowPopin(true);
    }, []);

    const onGenerationSuccess = useCallback(
        (certificateSerial) => {
            enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.certificateGenerateOK'), { variant: 'success' });
            onCertificateGenerated(certificateSerial);
            setShowPopin(false);
        },
        [enqueueSnackbar, i18n, onCertificateGenerated]
    );

    const onGenerationError = useCallback(() => {
        enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.certificateGenerateNOK'), { variant: 'error' });
        setShowPopin(false);
    }, [enqueueSnackbar, i18n]);

    const onSubmit = useCallback(
        (event) => {
            // Disable default form submission behavior
            event.preventDefault();

            // If the password is empty or if its confirmation does not match, display an error
            if (password !== passwordConfirm && password.trim() !== '') {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.passwordMismatch'), { variant: 'error' });
            } else if (password.trim() === '') {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.passwordEmpty'), { variant: 'error' });
            } else {
                new SsoWsClient().generateCertificate(email, password, {
                    onSuccess: onGenerationSuccess,
                    onError: onGenerationError,
                });
            }
        },
        [email, enqueueSnackbar, i18n, onGenerationError, onGenerationSuccess, password, passwordConfirm]
    );

    const handleClosePopin = useCallback(() => setShowPopin(false), []);

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
    }, []);

    const handlePasswordConfirmChange = useCallback((e) => {
        setPasswordConfirm(e.target.value);
    }, []);

    const renderPopin = useCallback(() => {
        if (showPopin === false) {
            return null;
        }

        return (
            <Dialog onClose={handleClosePopin} open fullWidth>
                <DialogTitle>{i18n.tc('ssoAdmin.user.actions.generateCertificate')}</DialogTitle>
                <DialogContent>
                    <FormField>
                        <StyledSpacedWrapper>
                            <TextField
                                label={i18n.tc('ssoAdmin.user.password')}
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                                type="password"
                            />
                        </StyledSpacedWrapper>

                        <TextField
                            label={i18n.tc('ssoAdmin.user.confirmPassword')}
                            value={passwordConfirm}
                            onChange={handlePasswordConfirmChange}
                            fullWidth
                            type="password"
                        />
                    </FormField>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClosePopin}>
                        {i18n.tc('ssoAdmin.actions.cancel')}
                    </Button>
                    <Button variant="contained" onClick={onSubmit} color="primary">
                        {i18n.tc('ssoAdmin.actions.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }, [
        handleClosePopin,
        handlePasswordChange,
        handlePasswordConfirmChange,
        i18n,
        onSubmit,
        password,
        passwordConfirm,
        showPopin,
    ]);

    return (
        <div>
            {renderPopin()}

            <Button variant="contained" fullWidth onClick={onClick}>
                {i18n.tc('ssoAdmin.user.actions.generateCertificate')}
            </Button>
        </div>
    );
}

GenerateCertificateLink.propTypes = {
    /** User identifier. Used to initiate the call to the webservice to change the email */
    email: PropTypes.string.isRequired,
    /**
     * Callback that is executed when the certificate is successfully generated. It is passed the certificate serial as
     * a parameter
     */
    onCertificateGenerated: PropTypes.func.isRequired,
};

export default GenerateCertificateLink;
