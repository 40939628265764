import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import UserAddition from './UserAddition';
import UserEdition from './UserEdition';
import UserDeletion from './UserDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const isBlocked = (email, blockedUsers) => blockedUsers.some((user) => user.email === email);

const StyledClose = styled(`div`)({
    color: '#f44336',
});

const ssoWs = new SsoWsClient();

function DelRenderer({ user, users, blockedUsers, onHideDrawer, onFetch }) {
    const handleDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onHideDrawer, onFetch]);

    return (
        <UserDeletion
            isBlocked={isBlocked(user.email, blockedUsers)}
            user={user}
            onDelete={handleDelete}
            onCancel={onHideDrawer}
            users={users}
        />
    );
}

function AddRenderer({ onFetch, onHideDrawer }) {
    const handleAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <UserAddition onAdd={handleAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ user, users, blockedUsers, onFetch, onHideDrawer }) {
    const handleUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <UserEdition
            isBlocked={isBlocked(user.email, blockedUsers)}
            user={user}
            onUpdate={handleUpdate}
            onCancel={onHideDrawer}
            users={users}
        />
    );
}

/**
 * Users management
 */
function Users() {
    const { dispatch, state } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [users, setUsers] = useState([]);
    const [deletingUser, setDeletingUser] = useState(null);
    const [blockedUsers, setBlockedUsers] = useState([]);
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const fetchUsers = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.getBlockedUsers({
            onSuccess: (blocked) => {
                setBlockedUsers(blocked.response.blockedList);
                ssoWs.getAllUsers({
                    onSuccess: ({ response }) => {
                        dispatch(setIsFetching(false));

                        setUsers(response.users);
                    },
                    onError: (brainWsResponse, xhr) => {
                        dispatch(setIsFetching(false));
                        enqueueSnackbar(xhr.response, { variant: 'error' });
                    },
                });
            },
            onError: () => {
                dispatch(setIsFetching(false));
            },
        });
    }, [dispatch, enqueueSnackbar]);

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer onFetch={fetchUsers} onHideDrawer={hideDrawers} />,
        [fetchUsers]
    );

    const editRenderer = useCallback(
        (user, hideDrawers) => (
            <EditRenderer
                user={user}
                users={users}
                blockedUsers={blockedUsers}
                onFetch={fetchUsers}
                onHideDrawer={hideDrawers}
            />
        ),
        [blockedUsers, fetchUsers, users]
    );

    const delRenderer = useCallback(
        (user, hideDrawers) => (
            <DelRenderer
                user={user}
                users={users}
                blockedUsers={blockedUsers}
                onHideDrawer={hideDrawers}
                onFetch={fetchUsers}
            />
        ),
        [blockedUsers, fetchUsers, users]
    );

    const handleDelete = useCallback(
        (userId) => {
            const userToDelete = users.find((user) => user.uuid === userId);

            if (userToDelete) {
                setDeletingUser(userToDelete);
            }
        },
        [users]
    );

    const columns = useMemo(
        () => [
            {
                field: 'firstName',
                headerName: i18n.tc('ssoAdmin.users.headerName.firstname'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'lastName',
                headerName: i18n.tc('ssoAdmin.users.headerName.lastname'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'email',
                headerName: i18n.tc('ssoAdmin.users.headerName.email'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'providerAdmin',
                headerName: i18n.tc('ssoAdmin.users.headerName.providerAdmin'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'ssoBranch',
                headerName: i18n.tc('ssoAdmin.users.headerName.branch'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'isBlocked',
                headerName: i18n.tc('ssoAdmin.users.headerName.status'),
                width: 150,
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ row }) => {
                    return isBlocked(row.email, blockedUsers) ? (
                        <StyledClose>
                            <b>Blocked</b>
                        </StyledClose>
                    ) : (
                        ''
                    );
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, blockedUsers, handleDelete, i18n]
    );

    useEffect(fetchUsers, [fetchUsers]);

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingUser}
            entities={users}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.users.addition.title')}
        />
    );
}

export default Users;
