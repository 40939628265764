import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { getOffers } from '../../../../wsClient/AppsManagerWsClient';
import EditButton from '../../../Buttons/Edit';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import { StyledSpacedWrapper } from '../../../StyledComponents';

function isOptionEqualToValue(option, value) {
    return option.label === value.label;
}

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function Offers({ product }) {
    const i18n = useI18n();
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [offers, setOffers] = useState([]);
    const [oldOffer, setOldOffer] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const fetchOffers = useCallback(() => {
        getOffers().then((response) => {
            if (response.ok) {
                // eslint-disable-next-line max-nested-callbacks
                response
                    .json()
                    .then((json) => setOffers(json.map((offer) => ({ label: offer.name, value: offer.id }))));
            }
        });
    }, []);

    useEffect(fetchOffers, [fetchOffers]);
    useEffect(() => {
        new SsoWsClient().getOfferData(product.providerName, product.clientName, product.siteName, product.productId, {
            onSuccess: ({ response }) => {
                if (response) {
                    const offersList = response.map((res) => {
                        return {
                            label: res,
                            value: res,
                        };
                    });
                    setSelectedOffers(offersList || []);
                } else {
                    setSelectedOffers([]);
                }
            },
            onError: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.getAll.error'), { variant: 'error' });
                setSelectedOffers([]);
            },
        });

        new SsoWsClient().getOldOfferData(
            product.providerName,
            product.clientName,
            product.siteName,
            product.productId,
            {
                onSuccess: ({ response }) => {
                    if (response) {
                        setOldOffer(response || '');
                    } else {
                        setOldOffer('');
                    }
                },
                onError: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.get.error'), { variant: 'error' });
                    setOldOffer('');
                },
            }
        );
    }, [enqueueSnackbar, i18n, product]);

    const handleEdit = useCallback(() => {
        // eslint-disable-next-line max-len
        const cleanedOffersList = selectedOffers.map(
            (selectedOffer) => selectedOffer.label || offers.find((offer) => offer.value === selectedOffer).label
        );

        new SsoWsClient().updateOfferData(
            product.providerName,
            product.clientName,
            product.siteName,
            product.productId,
            JSON.stringify(cleanedOffersList),
            {
                onSuccess: () => {
                    new SsoWsClient().clearOldOfferData(
                        product.providerName,
                        product.clientName,
                        product.siteName,
                        product.productId,
                        {
                            onSuccess: () => {
                                setOldOffer('');
                                enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.updateOffer.success'), {
                                    variant: 'success',
                                });
                            },
                            onError: () => {
                                enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.updateOffer.error'), {
                                    variant: 'error',
                                });
                            },
                        }
                    );
                },
                onError: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.updateOffer.error'), { variant: 'error' });
                },
            }
        );
    }, [
        enqueueSnackbar,
        i18n,
        offers,
        product.clientName,
        product.productId,
        product.providerName,
        product.siteName,
        selectedOffers,
    ]);

    const getOptionLabel = useCallback(
        (option) => option.label || offers.map((offer) => (offer.value === option ? offer.label : '')),
        [offers]
    );

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.tabs.offers')}
                placeholder={i18n.tc('ssoAdmin.tabs.offers')}
            />
        ),
        [i18n]
    );

    const selectedOffersValue = useMemo(() => (selectedOffers.length > 0 ? selectedOffers : []), [selectedOffers]);

    const handleSelectedOffersChange = useCallback((e, newValue) => setSelectedOffers(newValue), []);

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    {selectedOffers.length === 0 && oldOffer.length > 0 && (
                        <StyledSpacedWrapper>
                            <TextField label={i18n.tc('ssoAdmin.offers.oldOffer')} value={oldOffer} disabled />
                        </StyledSpacedWrapper>
                    )}
                    <Autocomplete
                        disabled
                        multiple
                        options={offers
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((offer) => ({ label: offer.label, value: offer.value }))}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions
                        renderInput={renderInput}
                        renderOption={renderOption}
                        value={selectedOffersValue}
                        onChange={handleSelectedOffersChange}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton disabled onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Offers.propTypes = {
    product: PropTypes.object.isRequired,
};

export default Offers;
