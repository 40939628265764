/* eslint-disable max-len */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Tabs, useTheme, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { helperTextProps, StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import IconSelector from '../../../IconSelector';
import { addPlatformApp } from '../../../../wsClient/AppsManagerWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import APP_PRODUCT_TARGET from '../appTarget';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

/**
 * Add app management
 */
function AppPlatformAddition({ onAdd, onCancel }) {
    const theme = useTheme();
    const [tab, setTab] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [appPackage, setPackage] = useState('');
    const [icon, setIcon] = useState('');
    const i18n = useI18n();

    const [productsTarget, setProductsTarget] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const isFormValid = useMemo(() => name !== '' && appPackage !== '', [name, appPackage]);

    const handleAddition = useCallback(() => {
        addPlatformApp({
            name,
            description,
            url,
            package: appPackage,
            icon,
            productsTarget: productsTarget.map((target) => target.value),
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.create.success'), { variant: 'success' });
                onAdd();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.create.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [appPackage, description, enqueueSnackbar, i18n, icon, name, onAdd, onCancel, productsTarget, url]);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleDescriptionChange = useCallback((e) => setDescription(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.type')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleProductsTargetChange = useCallback((event, newValue) => {
        setProductsTarget(newValue);
    }, []);

    const handlePackageNameChange = useCallback((e) => setPackage(e.target.value), []);

    const onAddition = useCallback(() => {
        if (isFormValid) {
            handleAddition();
        }
    }, [handleAddition, isFormValid]);

    return (
        <Addition label={i18n.tc('ssoAdmin.apps.addition.title', { onProduct: '' })} onCancel={onCancel} isTab>
            <Tabs
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.logo')} />
            </Tabs>

            <DrawerContent>
                <ContentArea>
                    {tab === 0 && (
                        <>
                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.name')}
                                    onChange={handleNameChange}
                                    value={name}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.description')}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    multiline
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.url.label')}
                                    value={url}
                                    onChange={handleUrlChange}
                                    fullWidth
                                    helperText={i18n.tc('ssoAdmin.apps.fields.url.helperText')}
                                    FormHelperTextProps={helperTextProps}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={APP_PRODUCT_TARGET}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={productsTarget}
                                    onChange={handleProductsTargetChange}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.apps.fields.packageName')}
                                    onChange={handlePackageNameChange}
                                    value={appPackage}
                                />
                            </StyledSpacedWrapper>
                        </>
                    )}

                    {tab === 1 && (
                        <IconSelector selectedIcon={icon} color={theme.palette.primary.main} onChange={setIcon} />
                    )}
                </ContentArea>
            </DrawerContent>

            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={onAddition} label={i18n.t('ssoAdmin.apps.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

AppPlatformAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AppPlatformAddition;
