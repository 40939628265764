import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, IconButton, styled } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import DeleteButton from '../../../Buttons/Delete';
import Deletion from '../../../EntityManager/Deletion';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const StyledCopyFieldContainer = styled(`div`)(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spacing(2),
    '& > div': {
        flex: 1,
        paddingBottom: 0,
        paddingRight: theme.spacing(1),
    },
}));

const ssoWs = new SsoWsClient();

/**
 * Edit product management
 */
function ProductDeletion({ product, onDelete, onCancel }) {
    const { dispatch } = useContext(AppContext);
    const [idSap, setIdSap] = useState('');
    const inputEl = useRef(null);
    const inputEld = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        ssoWs.getIdSAP(product.providerName, product.clientName, product.siteName, {
            onSuccess: ({ response }) => {
                setIdSap(response);
            },
            onError: () => {
                setIdSap(null);
            },
        });
    }, [product]);

    const handleDelete = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.deleteProduct(product.providerName, product.productId, {
            onSuccess: () => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.delete.success'), { variant: 'success' });
                onDelete();
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
    }, [dispatch, enqueueSnackbar, i18n, onDelete, product.productId, product.providerName]);

    const handleCopy = useCallback(() => {
        inputEl.current.disabled = false;
        inputEl.current.select();
        inputEl.current.disabled = true;
        document.execCommand('copy');
    }, []);

    return (
        <Deletion label={i18n.tc('ssoAdmin.products.deletion.title')} onCancel={onCancel}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.products.fields.name')}
                            value={product.name}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.products.fields.groupName')}
                            value={product.clientName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.products.fields.siteName')}
                            value={product.siteName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.products.fields.url')}
                            value={product.url}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledCopyFieldContainer>
                        <StyledSpacedWrapper>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.products.fields.productId')}
                                value={product.productId}
                                disabled
                                inputRef={inputEl}
                            />
                        </StyledSpacedWrapper>
                        <IconButton onClick={handleCopy} size="large">
                            <FileCopyIcon />
                        </IconButton>
                    </StyledCopyFieldContainer>
                    {idSap && (
                        <StyledCopyFieldContainer>
                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.products.fields.idSap')}
                                    value={idSap}
                                    disabled
                                    inputRef={inputEld}
                                />
                            </StyledSpacedWrapper>
                            <IconButton onClick={handleCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    )}

                    <TextField
                        label={i18n.tc('ssoAdmin.products.fields.type')}
                        disabled
                        value={product.type}
                        fullWidth
                    />
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

ProductDeletion.propTypes = {
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        clientName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        providerName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProductDeletion;
