import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import EntityManager from '../../../EntityManager';
import AppAddition from './AppPlatformAddition';
import AppEdition from './AppPlatformEdition';
import AppDeletion from './AppPlatformDeletion';
import Icon from '../../../IconSelector/Icon';
import { AppPlatformContextProvider, usePlatformApps } from './contexts';
import CellTooltip from '../../../CellTooltip';
import { AppContext } from '../../../../app-context';
import { ActionsCell } from '../../../DataGridPro';

function AppsPlatformComponent() {
    return (
        <AppPlatformContextProvider>
            <AppsPlatformComponentWithContext />
        </AppPlatformContextProvider>
    );
}

function AddRenderer({ onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <AppAddition onAdd={onAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ app, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <AppEdition app={app} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

function DelRenderer({ app, setDeletingApp, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingApp(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingApp]);

    return <AppDeletion app={app} onDelete={onDelete} onCancel={onCancel} />;
}

/**
 * Apps Platform management
 */
function AppsPlatformComponentWithContext() {
    const { apps, getApps } = usePlatformApps();
    const [deletingApp, setDeletingApp] = useState(null);
    const { state } = useContext(AppContext);
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const handleDelete = useCallback(
        (appId) => {
            const appToDelete = apps.find((app) => app.id === appId);

            if (appToDelete) {
                setDeletingApp(appToDelete);
            }
        },
        [apps]
    );

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.apps.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'icon',
                headerName: i18n.tc('ssoAdmin.apps.headerName.logo'),
                // eslint-disable-next-line react/prop-types
                renderCell: ({ row }) => <Icon app={row} />,
                flex: 1,
            },
            {
                field: 'description',
                headerName: i18n.tc('ssoAdmin.apps.headerName.description'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'package',
                headerName: i18n.tc('ssoAdmin.apps.headerName.package'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer onHideDrawer={hideDrawers} onFetch={getApps} />,
        [getApps]
    );

    const editRenderer = useCallback(
        (app, hideDrawers) => <EditRenderer app={app} onFetch={getApps} onHideDrawer={hideDrawers} />,
        [getApps]
    );

    const delRenderer = useCallback(
        (app, hideDrawers) => (
            <DelRenderer app={app} setDeletingApp={setDeletingApp} onHideDrawer={hideDrawers} onFetch={getApps} />
        ),
        [getApps]
    );

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingApp}
            entities={apps}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.apps.addition.title', { onProduct: '' })}
        />
    );
}

export default AppsPlatformComponent;
