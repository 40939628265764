import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Clear';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../wsClient/SsoWsClient';
import { StyledDialogTitle, StyledSpacedWrapper, StyledTitle } from '../StyledComponents';
import PRODUCT_TYPES from '../Routes/Sso/Products/productTypes';

const StyledCancelIcon = styled(CancelIcon)({
    cursor: 'pointer',
});

const ssoWs = new SsoWsClient();

/**
 * A Popin to add a licence
 */
function SiteProductAddition(props) {
    const [name, setName] = useState('');
    const clientName = props.site.groupName;
    const { siteName } = props.site;
    const [url, setUrl] = useState('');
    const [type, setType] = useState(PRODUCT_TYPES.BRAINCUBE.value);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        ssoWs.createProduct(
            props.provider,
            { name, clientName, siteName, url, type },
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.siteProducts.notistack.create.success'), { variant: 'success' });

                    setName('');
                    setUrl('');
                    setType(PRODUCT_TYPES.BRAINCUBE.value);
                    props.onAdd();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    props.onClose();
                },
            }
        );
    }, [clientName, enqueueSnackbar, i18n, name, props, siteName, type, url]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleTypeChange = useCallback((e) => setType(e.target.value), []);

    return (
        <Dialog onClose={props.onClose} open fullWidth>
            <StyledDialogTitle>
                <StyledTitle>
                    <AddIcon />
                    <Typography variant="h6">{i18n.tc('ssoAdmin.siteProducts.actions.addProduct')}</Typography>
                </StyledTitle>
                <StyledCancelIcon onClick={props.onClose} />
            </StyledDialogTitle>
            <DialogContent>
                <StyledSpacedWrapper>
                    <TextField
                        label={i18n.tc('ssoAdmin.siteProducts.fields.name')}
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <StyledSpacedWrapper>
                    <TextField
                        disabled
                        label={i18n.tc('ssoAdmin.siteProducts.fields.groupName')}
                        value={clientName}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <StyledSpacedWrapper>
                    <TextField
                        disabled
                        label={i18n.tc('ssoAdmin.siteProducts.fields.siteName')}
                        value={siteName}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <StyledSpacedWrapper>
                    <TextField
                        label={i18n.tc('ssoAdmin.siteProducts.fields.url')}
                        value={url}
                        onChange={handleUrlChange}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <FormControl fullWidth required>
                    <InputLabel>{i18n.tc('ssoAdmin.siteProducts.fields.type')}</InputLabel>
                    <Select
                        value={type}
                        onChange={handleTypeChange}
                        label={i18n.tc('ssoAdmin.siteProducts.fields.type')}
                    >
                        {Object.keys(PRODUCT_TYPES).map((key) => (
                            <MenuItem key={PRODUCT_TYPES[key].value} value={PRODUCT_TYPES[key].value}>
                                {PRODUCT_TYPES[key].label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleAddition}>
                    {i18n.tc('ssoAdmin.actions.add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SiteProductAddition.propTypes = {
    /** Callback when the popin closes */
    site: PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
    }).isRequired,
    provider: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SiteProductAddition;
