import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import ProductAddition from './ProductAddition';
import ProductEdition from './ProductEdition';
import ProductDeletion from './ProductDeletion';
import { getDistributors, getLicences } from '../../../../wsClient/LicenceWsClient';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const ssoWs = new SsoWsClient();

function AddRenderer({ providersProductsList, selectedProvider, onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <ProductAddition
            providersProductsList={providersProductsList}
            provider={selectedProvider}
            onAdd={onAdd}
            onCancel={onHideDrawer}
        />
    );
}

function EditRenderer({ providersProductsList, product, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <ProductEdition
            providersProductsList={providersProductsList}
            product={product}
            licence={product.licence}
            onUpdate={onUpdate}
            onCancel={onHideDrawer}
        />
    );
}

function DelRenderer({ product, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);
    return <ProductDeletion product={product} onDelete={onDelete} onCancel={onHideDrawer} />;
}

/**
 * Products management
 */
function ProductsComponent() {
    const { dispatch, state } = useContext(AppContext);

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [deletingProduct, setDeletingProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [licences, setLicences] = useState([]);
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const fetchProducts = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.getProducts(selectedProvider, {
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setProducts(
                    response.products.map((product) => {
                        const productLicence = licences.find((licence) => licence.product === product.productId);
                        if (typeof productLicence !== 'undefined') {
                            product.licence = productLicence.id;
                        } else {
                            product.licence = '';
                        }
                        return product;
                    })
                );
            },
            onError: () => {
                dispatch(setIsFetching(false));
            },
        });
    }, [dispatch, licences, selectedProvider]);

    const fetchLicences = useCallback((distributors) => {
        Promise.all(
            distributors.map((distributor) => {
                return getLicences(distributor.id).then((response) => {
                    if (response.ok) {
                        // eslint-disable-next-line max-nested-callbacks
                        return response.json().then(({ licenceList }) => licenceList);
                    }
                    return Promise.reject();
                });
            })
        ).then((allLicences) => {
            setLicences([].concat(...allLicences));
        });
    }, []);

    const fetchDistributors = useCallback(() => {
        getDistributors()
            .then((response) => {
                if (response.ok) {
                    response.json().then((value) => {
                        fetchLicences(value.distributorList);
                    });
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                setLicences([{}]);
            });
    }, [fetchLicences]);

    const fetchProviders = useCallback(() => {
        ssoWs.getAllProviders({
            onSuccess: ({ response }) => {
                setProviders(response.providers);
                setSelectedProvider(response.providers[0].name);
            },
            onError: () => {},
        });
    }, []);

    const addRenderer = useCallback(
        (hideDrawers) => (
            <AddRenderer
                providersProductsList={products}
                selectedProvider={selectedProvider}
                onFetch={fetchProducts}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchProducts, products, selectedProvider]
    );

    const editRenderer = useCallback(
        (product, hideDrawers) => (
            <EditRenderer
                providersProductsList={products}
                product={product}
                onFetch={fetchProducts}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchProducts, products]
    );

    const delRenderer = useCallback(
        (product, hideDrawers) => <DelRenderer product={product} onFetch={fetchProducts} onHideDrawer={hideDrawers} />,
        [fetchProducts]
    );

    const handleDelete = useCallback((product) => setDeletingProduct(JSON.parse(product)), []);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.products.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'clientName',
                headerName: i18n.tc('ssoAdmin.products.headerName.groupName'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'siteName',
                headerName: i18n.tc('ssoAdmin.products.headerName.siteName'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'url',
                headerName: i18n.tc('ssoAdmin.products.headerName.url'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'type',
                headerName: i18n.tc('ssoAdmin.products.headerName.type'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'licence',
                headerName: i18n.tc('ssoAdmin.products.headerName.licence'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    const filterChange = useCallback(({ target: { value } }) => {
        setSelectedProvider(value);
    }, []);

    const filter = useMemo(
        () => (
            <FormControl>
                <InputLabel>{i18n.tc('ssoAdmin.products.fields.provider')}</InputLabel>
                <Select
                    value={selectedProvider}
                    onChange={filterChange}
                    label={i18n.tc('ssoAdmin.products.fields.provider')}
                >
                    {providers.map((provider) => (
                        <MenuItem key={provider.name} value={provider.name}>
                            {provider.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ),
        [filterChange, i18n, providers, selectedProvider]
    );

    useEffect(() => {
        fetchDistributors();
    }, [fetchDistributors]);

    useEffect(() => {
        fetchProviders();
    }, [fetchProviders, licences]);

    useEffect(() => {
        if (selectedProvider) {
            fetchProducts();
        }
    }, [fetchProducts, selectedProvider]);

    if (providers.length === 0 || selectedProvider === null) {
        return null;
    }

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingProduct}
            entities={products}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            filter={filter}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.products.addition.title')}
        />
    );
}

export default ProductsComponent;
