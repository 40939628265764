import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, TextField, Checkbox, Tabs, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import GroupMemberList from './GroupMemberList';
import Edition from '../../../EntityManager/Edition';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit group management
 */
function UserGroupEdition({ userGroup, onCancel }) {
    const [tab, setTab] = useState(0);
    const i18n = useI18n();

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const checkboxIcon = useMemo(() => <Checkbox disabled checked={userGroup.posixGroup} />, [userGroup.posixGroup]);

    return (
        <Edition label={i18n.tc('ssoAdmin.userGroups.edition.title')} onCancel={onCancel}>
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.userGroups.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.userGroups.tabs.members')} />
            </Tabs>

            {tab === 0 && (
                <>
                    <DrawerContent>
                        <ContentArea>
                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.userGroups.fields.id')}
                                    disabled
                                    value={userGroup.id}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.userGroups.fields.cn')}
                                    disabled
                                    value={userGroup.cn}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.userGroups.fields.gidNumber')}
                                    disabled
                                    value={userGroup.gidNumber || ''}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={checkboxIcon}
                                    label={i18n.tc('ssoAdmin.userGroups.fields.posixGroup')}
                                />
                            </FormControl>
                        </ContentArea>
                    </DrawerContent>
                    <DrawerActions>
                        <Grid container justifyContent="flex-end" mt={1}>
                            <CancelButton onClick={onCancel} />
                        </Grid>
                    </DrawerActions>
                </>
            )}

            {tab === 1 && <GroupMemberList group={userGroup} ssoWs={ssoWs} />}
        </Edition>
    );
}

UserGroupEdition.propTypes = {
    userGroup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cn: PropTypes.string.isRequired,
        posixGroup: PropTypes.bool.isRequired,
        gidNumber: PropTypes.string,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserGroupEdition;
