import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import FormField from '../FormField';

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

/**
 * A Popin to edit a grant
 */
function MemberEdition({ onClose, onDelete, member, entityLabel, entityKeyName }) {
    const handleDelete = useCallback(() => {
        onDelete(member);
        onClose();
    }, [member, onClose, onDelete]);

    return (
        <Dialog open fullWidth onClose={onClose}>
            <DialogTitle>
                <StyledTitle>
                    <DeleteIcon />
                    Delete group
                </StyledTitle>
            </DialogTitle>
            <DialogContent>
                <FormField>
                    <TextField disabled value={member[entityKeyName]} label={entityLabel} fullWidth />
                </FormField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MemberEdition.propTypes = {
    /** The object of the member */
    member: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    /** Callback when the delete button is clicked */
    onDelete: PropTypes.func.isRequired,
    /** The label of the entity (such as goup or email) */
    entityLabel: PropTypes.string.isRequired,
    /** The entity key name */
    entityKeyName: PropTypes.string.isRequired,
};

export default MemberEdition;
