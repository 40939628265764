import React, { useState, Suspense, lazy, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, FormControl, MenuItem, Select, InputLabel, Button, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const DelegateConfigEditor = lazy(() => import('./DelegateConfigEditor'));

export const DELEGATE_PROTOCOL = [
    { value: 'saml', label: 'SAML' },
    { value: 'oauth2', label: 'OAuth2' },
    { value: 'jwt', label: 'JWT' },
];

function SuspenseFallback() {
    const i18n = useI18n();

    return <div>{i18n.tc('ssoAdmin.delegates.actions.loadingEditor')}</div>;
}

/**
 * Add group management
 */
function DelegateAddition({ onAdd, onCancel }) {
    const [name, setName] = useState('');
    const [protocol, setProtocol] = useState(DELEGATE_PROTOCOL[0].value);
    const [configuration, setConfiguration] = useState('{}');
    const [isDelegateConfigIsOpen, setIsDelegateConfigIsOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        new SsoWsClient().createDelegate(name, protocol, JSON.parse(configuration), {
            onSuccess: ({ response }) => {
                enqueueSnackbar(i18n.tc('ssoAdmin.delegates.notistack.create.success'), { variant: 'success' });

                setName('');
                setProtocol(DELEGATE_PROTOCOL[0].value);
                setConfiguration('{}');
                onAdd();

                fetch(`${response}/reload`, { method: 'PUT', credentials: 'include' }).then((reloadResponse) => {
                    if (reloadResponse.ok) {
                        onAdd();
                    }
                });
            },
            onError: ({ httpCode }) => {
                let msg = i18n.tc('ssoAdmin.delegates.notistack.create.error.default');
                if (httpCode === 400) msg = i18n.tc('ssoAdmin.delegates.notistack.create.error.invalidDelegate');
                enqueueSnackbar(msg, { variant: 'error' });
                onCancel();
            },
        });
    }, [configuration, enqueueSnackbar, i18n, name, onAdd, onCancel, protocol]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleProtocolChange = useCallback((e) => setProtocol(e.target.value), []);

    const openDelegateConfig = useCallback(() => setIsDelegateConfigIsOpen(true), []);

    const onCloseDelegateConfigEditor = useCallback(() => setIsDelegateConfigIsOpen(false), []);

    return (
        <Addition label={i18n.tc('ssoAdmin.delegates.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            required
                            label={i18n.tc('ssoAdmin.delegates.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>
                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('ssoAdmin.delegates.fields.protocol')}</InputLabel>
                            <Select
                                value={protocol}
                                onChange={handleProtocolChange}
                                label={i18n.tc('ssoAdmin.delegates.fields.protocol')}
                            >
                                {DELEGATE_PROTOCOL.map((delegateProtocol) => (
                                    <MenuItem key={delegateProtocol.value} value={delegateProtocol.value}>
                                        {delegateProtocol.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>
                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <Button color="secondary" onClick={openDelegateConfig}>
                                {i18n.tc('ssoAdmin.delegates.actions.changeConfig')}
                            </Button>
                        </FormControl>
                    </StyledSpacedWrapper>

                    {isDelegateConfigIsOpen && (
                        <Suspense fallback={SuspenseFallback}>
                            <DelegateConfigEditor
                                onClose={onCloseDelegateConfigEditor}
                                onChange={setConfiguration}
                                onValidate={onCloseDelegateConfigEditor}
                                data={configuration}
                            />
                        </Suspense>
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={handleAddition} label={i18n.t('ssoAdmin.delegates.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

DelegateAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DelegateAddition;
