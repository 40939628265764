import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import Deletion from '../../../EntityManager/Deletion';
import DeleteButton from '../../../Buttons/Delete';
import CancelButton from '../../../Buttons/Cancel';
import { deleteDistributor } from '../../../../wsClient/LicenceWsClient';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

/**
 * Edit distrib management
 */
function DistributorDeletion({ distributor, onDelete, onCancel }) {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        deleteDistributor(distributor.id).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.delete.success'), { variant: 'success' });
                onDelete();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.delete.error'), { variant: 'error' });
            }
        });
    }, [distributor.id, enqueueSnackbar, i18n, onDelete]);

    return (
        <Deletion
            label={i18n.tc('ssoAdmin.distributors.deletion.title')}
            onCancel={onCancel}
            handleDelete={handleDelete}
        >
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField label="ID" disabled value={distributor.id} fullWidth />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.distributors.fields.key')}
                            disabled
                            value={distributor.key}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            fullWidth
                            disabled
                            label={i18n.tc('ssoAdmin.distributors.fields.name')}
                            value={distributor.name}
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        fullWidth
                        disabled
                        label={i18n.tc('ssoAdmin.distributors.fields.externalId')}
                        value={distributor.externalId}
                    />
                </FormField>

                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

DistributorDeletion.propTypes = {
    distributor: PropTypes.shape({
        id: PropTypes.string,
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        externalId: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DistributorDeletion;
