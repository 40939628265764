import Cookies from 'js-cookie';
import React, { createContext, useMemo, useReducer } from 'react';

export const AppContext = createContext();

const initialState = {
    isSidebarCollapsed: Cookies.get('sidebar_collapsed') === 'true',
    isFetching: false,
    meIsLicenceAdmin: null,
    meIsLicenceUsers: null,
};

const SET_IS_SIDEBAR_COLLAPSED = 'set-is-sidebar-collapsed';
const SET_IS_FETCHING = 'set-is-fetching';
const SET_ME_IS_LICENCE_ADMIN = 'set-me-is-licence-admin';
const SET_ME_IS_LICENCE_USERS = 'set-me-is-licence-users';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_IS_SIDEBAR_COLLAPSED:
            return { ...state, isSidebarCollapsed: action.payload };
        case SET_IS_FETCHING:
            return { ...state, isFetching: action.payload };
        case SET_ME_IS_LICENCE_ADMIN:
            return { ...state, meIsLicenceAdmin: action.payload };
        case SET_ME_IS_LICENCE_USERS:
            return { ...state, meIsLicenceUsers: action.payload };
        default:
            throw new Error();
    }
};

export function AppContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const providerValues = useMemo(() => ({ state, dispatch }), [state]);

    return <AppContext.Provider value={providerValues}>{children}</AppContext.Provider>;
}

export function setIsFetching(isFetching) {
    return {
        type: SET_IS_FETCHING,
        payload: isFetching,
    };
}

export function setIsSidebarCollapsed(isSidebarCollapsed) {
    Cookies.set('sidebar_collapsed', isSidebarCollapsed);

    return {
        type: SET_IS_SIDEBAR_COLLAPSED,
        payload: isSidebarCollapsed,
    };
}

function setMeisLicenceAdmin(meisLicenceAdmin) {
    return {
        type: SET_ME_IS_LICENCE_ADMIN,
        payload: meisLicenceAdmin,
    };
}

function setMeisLicenceUsers(meisLicenceUsers) {
    return {
        type: SET_ME_IS_LICENCE_USERS,
        payload: meisLicenceUsers,
    };
}

export function setMeFromUniverse(dispatch, groupsList) {
    const licenceAdmin = groupsList.some((group) => group.id === 'licence-admin');
    const licenceUsers = groupsList.some((group) => group.id === 'licence-users');

    dispatch(setMeisLicenceAdmin(licenceAdmin));
    dispatch(setMeisLicenceUsers(licenceUsers));
}
