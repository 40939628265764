import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, FormControlLabel, Switch, Autocomplete, Grid } from '@mui/material';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import Edition from '../../../EntityManager/Edition';
import EditButton from '../../../Buttons/Edit';
import { updateDistributor } from '../../../../wsClient/LicenceWsClient';
import CancelButton from '../../../Buttons/Cancel';
import { AppContext } from '../../../../app-context';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import { getOffers } from '../../../../wsClient/AppsManagerWsClient';

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.label === value.label;
}

/**
 * Edit distrib management
 */
function DistributorEdition({ distributor, siteList, onCancel, onUpdate }) {
    const i18n = useI18n();

    const [name, setName] = useState(distributor.name);
    const [extId, setExtId] = useState(distributor.externalId);
    const [allowSmartGeneration, setAllowSmartGeneration] = useState(true);
    const [defaultDuration, setDefaultDuration] = useState(0);
    const [site, setSite] = useState(undefined);
    const [offer, setOffer] = useState(null);
    const [offersLoading, setOffersLoading] = useState(false);
    const [offersList, setOffersList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useContext(AppContext);
    const { user } = useMe();

    useEffect(() => {
        setName(distributor.name);
        setExtId(distributor.externalId);
        setAllowSmartGeneration(distributor.allowSmartGeneration);
        setDefaultDuration(distributor.defaultDuration);
        setSite(siteList.find((s) => s.siteUuid === distributor.defaultSiteUuid));
    }, [distributor, siteList]);

    const handleEdit = useCallback(() => {
        updateDistributor(distributor.id, {
            id: distributor.id,
            key: distributor.key,
            name,
            externalId: extId,
            allowSmartGeneration,
            defaultDuration,
            defaultSiteUuid: site ? site.siteUuid : '',
            defaultOfferUuid: offer?.value || null,
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.update.success'), { variant: 'success' });
                onUpdate();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.update.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [
        allowSmartGeneration,
        defaultDuration,
        distributor.id,
        distributor.key,
        enqueueSnackbar,
        extId,
        i18n,
        name,
        offer?.value,
        onCancel,
        onUpdate,
        site,
    ]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleExternalIdChange = useCallback((e) => setExtId(e.target.value), []);

    const handleAllowSmartGenerationChange = useCallback((event, checked) => setAllowSmartGeneration(checked), []);

    const handleDefaultDurationChange = useCallback((e) => setDefaultDuration(e.target.value), []);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.distributors.fields.siteName')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const selectedSiteValue = useMemo(
        () =>
            site
                ? {
                      label: site.siteName.concat(' - ', site.groupName),
                      value: site,
                  }
                : null,
        [site]
    );

    const handleSelectedSiteChange = useCallback((event, selectedItem) => {
        setSite(selectedItem?.value);
    }, []);

    const handleOfferChange = useCallback((event, selectedItem) => {
        setOffer(selectedItem);
    }, []);

    const switchControl = useMemo(
        () => <Switch checked={allowSmartGeneration} onChange={handleAllowSmartGenerationChange} color="primary" />,
        [allowSmartGeneration, handleAllowSmartGenerationChange]
    );

    const renderOfferInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.licence.addition.formFields.offer')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const populateOffers = useCallback(() => {
        setOffersLoading(true);
        getOffers()
            .then((response) => {
                if (response.ok) {
                    response.json().then((items) => {
                        setOffersList(
                            items
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((item) => ({ label: item.name, value: item.id }))
                        );

                        if (distributor.defaultOfferUuid) {
                            const selectedOffer = items.find((item) => item.id === distributor.defaultOfferUuid);
                            setOffer({
                                value: selectedOffer.id,
                                label: selectedOffer.name,
                            });
                        }
                    });
                }
            })
            .finally(() => setOffersLoading(false));
    }, [distributor?.defaultOfferUuid]);

    useEffect(() => {
        populateOffers();
    }, [populateOffers]);

    return (
        <Edition label={i18n.tc('ssoAdmin.distributors.edition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField label="ID" disabled value={distributor.id} fullWidth />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.distributors.fields.key')}
                            disabled
                            value={distributor.key}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            fullWidth
                            label={i18n.tc('ssoAdmin.distributors.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        fullWidth
                        label={i18n.tc('ssoAdmin.distributors.fields.externalId')}
                        value={extId}
                        onChange={handleExternalIdChange}
                    />

                    <StyledSpacedWrapper>
                        <FormControlLabel
                            control={switchControl}
                            label={i18n.tc('ssoAdmin.distributors.fields.allowSmartGeneration')}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            required={allowSmartGeneration}
                            disabled={!allowSmartGeneration}
                            label={i18n.tc('ssoAdmin.distributors.fields.defaultDuration')}
                            type="number"
                            value={defaultDuration}
                            onChange={handleDefaultDurationChange}
                            fullWidth
                            helperText="in days"
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            disabled={!allowSmartGeneration}
                            selectOnFocus
                            handleHomeEndKeys
                            ListboxComponent={ListboxComponent}
                            renderInput={renderInput}
                            options={siteList.map((item) => ({
                                label: item.siteName.concat(' - ', item.groupName),
                                value: item,
                            }))}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={selectedSiteValue}
                            onChange={handleSelectedSiteChange}
                        />
                    </StyledSpacedWrapper>

                    <Autocomplete
                        loading={offersLoading}
                        options={offersList}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions
                        renderInput={renderOfferInput}
                        value={offer}
                        onChange={handleOfferChange}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <EditButton disabled={!user.rootAdmin && !state.meIsLicenceAdmin} onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </Edition>
    );
}

DistributorEdition.propTypes = {
    distributor: PropTypes.shape({
        id: PropTypes.string,
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        externalId: PropTypes.string.isRequired,
    }).isRequired,
    siteList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DistributorEdition;
