function fetchWrapper(url, method = 'GET', body = null, headers = {}) {
    const requestHeaders = new Headers(headers);

    if (body) {
        requestHeaders.append('Content-Type', 'application/json');

        return fetch(url, {
            method,
            credentials: 'include',
            headers: requestHeaders,
            body,
        });
    }
    return fetch(url, {
        method,
        credentials: 'include',
        headers: requestHeaders,
    });
}

export function getDistributors() {
    return fetchWrapper(`https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor`);
}

export function addDistributor(distributor) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/create`,
        'POST',
        JSON.stringify(distributor)
    );
}

export function deleteDistributor(distributorId) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/${distributorId}`,
        'DELETE'
    );
}

export function updateDistributor(distributorId, distributor) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/${distributorId}`,
        'PUT',
        JSON.stringify(distributor)
    );
}

export function getLicences(distributorId) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/${distributorId}/licence`
    );
}

export function getActivatedLicences(distributorId) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/${distributorId}/licence?activationState=ACTIVE`
    );
}

export function addLicence(distributorId, parameters) {
    // eslint-disable-next-line max-len
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/distributor/${distributorId}/licence/generate?` +
            `duration=${parameters.duration}&` +
            `comment=${parameters.comment}&` +
            `orderNumber=${parameters.orderNumber}&` +
            `licenceNumber=${parameters.licenceNumber}&` +
            `siteUuid=${parameters.siteUuid}&` +
            `offerUuid=${parameters.offerUuid}&` +
            `maxChildren=${parameters.maxChildren}`,
        'POST'
    );
}

export function updateLicence(licenceId, licence) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/licence/${licenceId}`,
        'PUT',
        JSON.stringify(licence)
    );
}

export function encodeLicence(licenceId) {
    return fetchWrapper(`https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/licence/${licenceId}/encode`, 'POST');
}

export function deleteLicence(licenceId) {
    return fetchWrapper(
        `https://licence.${window.BC_API_ENDPOINTS_CONF.base}/api/licence/${licenceId}`,
        'PUT',
        JSON.stringify({ activationState: 'INACTIVE' })
    );
}
