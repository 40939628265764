import { styled } from '@mui/material';

const StyledButtonContainer = styled(`div`)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    '& button + button': {
        marginLeft: theme.spacing(1),
    },
}));

export default StyledButtonContainer;
