import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactJsonViewCompare from 'react-json-view-compare';

import { useI18n } from '@braincube/i18n';

import CancelButton from 'components/Buttons/Cancel';
import EditButton from 'components/Buttons/Edit';

function Diff({ old, recent, onClose, onSave }) {
    const i18n = useI18n();

    return (
        <Dialog fullWidth open onClose={onClose}>
            <DialogTitle>{i18n.tc('preferences.confirm')}</DialogTitle>
            <DialogContent>
                <ReactJsonViewCompare oldData={JSON.parse(old)} newData={JSON.parse(recent)} />
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={onClose} />
                <EditButton onClick={onSave} />
            </DialogActions>
        </Dialog>
    );
}

export default Diff;
