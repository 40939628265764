import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import GroupList from '../../../GroupList';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

const ssoWs = new SsoWsClient();

function getAllGroups(onSuccess, onError) {
    const events = { onSuccess, onError };
    return ssoWs.getAllUserGroups(events);
}

function mapGroups(response) {
    const groups = [];

    response.groups.forEach((group) => {
        groups[group.id] = group.cn;
    });
    return groups;
}

/**
 * This component is displayed in a group's edit popin and is used to edit, add or remove members.
 *
 * It's an EntityManager inside another EntityManager (the list of the users)
 */
function UserGroupsList({ memberEmail }) {
    const onGroupDeleting = useCallback(
        (groupToDelete, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.deleteGroupMember(memberEmail, groupToDelete.id, events);
        },
        [memberEmail]
    );

    const onGroupAdding = useCallback(
        (groupToAdd, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.addMemberForGroup(memberEmail, groupToAdd, events);
        },
        [memberEmail]
    );

    const getGroups = useCallback(
        (onSuccess, onError) => {
            const events = { onSuccess, onError };
            return ssoWs.getUserGroups(memberEmail, events);
        },
        [memberEmail]
    );

    return (
        <GroupList
            key={memberEmail}
            mapEntities={mapGroups}
            getMembers={getGroups}
            entityKeyName="id"
            entityLabel="Groups"
            getEntities={getAllGroups}
            handleMemberToAdd={onGroupAdding}
            handleMemberToDelete={onGroupDeleting}
            reponseEntityName="groups"
        />
    );
}

UserGroupsList.propTypes = {
    /** User */
    memberEmail: PropTypes.string.isRequired,
};

export default UserGroupsList;
