import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, styled } from '@mui/material';
import { Help } from '@mui/icons-material';

const StyledTooltipWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
});

const StyledTooltip = styled(Tooltip)({
    whiteSpace: 'pre-wrap',
});

const StyledHelpIcon = styled(Help)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        color: theme.palette.text.secondary,
    },
}));

function InfoTooltip({ text }) {
    return (
        <StyledTooltipWrapper data-testid="tooltip">
            <StyledTooltip title={text} placement="left">
                <StyledHelpIcon />
            </StyledTooltip>
        </StyledTooltipWrapper>
    );
}

InfoTooltip.propTypes = {
    /**
     * The text to display
     */
    text: PropTypes.string.isRequired,
};

export default InfoTooltip;
