import React, { useCallback, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { useI18n } from '@braincube/i18n';

import PRODUCT_TYPES from '../../productTypes';

const isOptionEqualToValue = (option, value) => option.value === value.value;

function IoTStackField({ onChange, providersProducts, siteUuid, value }) {
    const i18n = useI18n();

    const selectableIoTStack = useMemo(() => {
        return providersProducts.filter(
            (providersProduct) =>
                providersProduct.type === PRODUCT_TYPES.IOT_STACK.value && providersProduct.site.siteUuid === siteUuid
        );
    }, [providersProducts, siteUuid]);

    const iotStacksList = useMemo(() => {
        return selectableIoTStack.map((iotStack) => ({
            label: iotStack.name,
            value: iotStack.productId,
        }));
    }, [selectableIoTStack]);

    const renderStackIdInput = useCallback(
        (params) => (
            <TextField
                {...params}
                label={i18n.tc('ssoAdmin.products.fields.iotStack')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleChange = useCallback((e, iotStackValue) => onChange(iotStackValue), [onChange]);

    return (
        <Autocomplete
            data-testid="product-iotStack"
            label={i18n.tc('ssoAdmin.products.fields.iotStack')}
            renderInput={renderStackIdInput}
            options={iotStacksList}
            value={value}
            onChange={handleChange}
            isOptionEqualToValue={isOptionEqualToValue}
            fullWidth
        />
    );
}

IoTStackField.propTypes = {
    onChange: PropTypes.func.isRequired,
    providersProducts: PropTypes.arrayOf(
        PropTypes.shape({
            site: PropTypes.shape({
                siteUuid: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    siteUuid: PropTypes.string.isRequired,
    value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
};

IoTStackField.defaultProps = {
    value: null,
};

export default IoTStackField;
