import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { useMe } from '@braincube/header';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import UserDelete from './UserDelete';
import Deletion from '../../../EntityManager/Deletion';
import { SSO_BRANCH } from './UserAddition';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit user management
 */
function UserDeletion({ user, onCancel, onDelete, users }) {
    const i18n = useI18n();
    const { user: me } = useMe();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = useCallback(
        (newOwner) => {
            ssoWs.deleteUser(user.email, newOwner, {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.deleteOK'), { variant: 'success' });
                    onDelete();
                },
                onError: () => enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.deleteNOK'), { variant: 'error' }),
            });
        },
        [enqueueSnackbar, i18n, onDelete, user.email]
    );

    const { email, uuid, certSerial, ssoBranch, gidNumber, homeDirectory, uidNumber, owner } = user;

    return (
        <Deletion label={i18n.tc('ssoAdmin.users.deletion.title')} onCancel={onCancel}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.firstname')}
                            value={user.firstName || ''}
                            fullWidth
                            disabled
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.lastname')}
                            value={user.lastName || ''}
                            fullWidth
                            disabled
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.providerAdmin')}
                            value={user.providerAdmin || ''}
                            fullWidth
                            disabled
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControl fullWidth disabled>
                            <InputLabel>{i18n.tc('ssoAdmin.users.fields.shell')}</InputLabel>
                            <Select value={user.loginShell || ''}>
                                {['/bin/nologin', '/bin/bash', '/bin/zsh'].map((shell) => (
                                    <MenuItem key={shell} value={shell}>
                                        {shell}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField label={i18n.tc('ssoAdmin.users.fields.email')} disabled value={email} fullWidth />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField label={i18n.tc('ssoAdmin.users.fields.uuid')} disabled value={uuid} fullWidth />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.certSerial')}
                            disabled
                            value={certSerial || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControl fullWidth disabled>
                            <InputLabel>{i18n.tc('ssoAdmin.users.fields.branch')}</InputLabel>
                            <Select value={ssoBranch} label={i18n.tc('ssoAdmin.users.fields.branch')}>
                                {SSO_BRANCH.map((branch) => (
                                    <MenuItem key={branch} value={branch}>
                                        {branch}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.gid')}
                            disabled
                            value={gidNumber || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.directory')}
                            disabled
                            value={homeDirectory || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.uid')}
                            disabled
                            value={uidNumber || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField label={i18n.tc('ssoAdmin.users.fields.owner')} disabled value={owner || ''} fullWidth />
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <UserDelete
                        myEmail={me.email}
                        onClick={handleDelete}
                        disabled={!me.rootAdmin && !me.providerAdmin}
                        userUuid={user.uuid}
                        users={users}
                    />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

UserDeletion.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        ssoBranch: PropTypes.string.isRequired,
        providers: PropTypes.arrayOf(PropTypes.string),
        providerAdmin: PropTypes.string,
        loginShell: PropTypes.string,
        homeDirectory: PropTypes.string,
        certSerial: PropTypes.string,
        gidNumber: PropTypes.string,
        uidNumber: PropTypes.string,
        owner: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserDeletion;
