import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Dialog, styled, CardContent, Card, Fab } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FilterList } from '@mui/icons-material';

import { Search } from '@braincube/ui';
import { useI18n } from '@braincube/i18n';
import Util from '@braincube/brain-js/lib/util';
import { TemporaryDrawer } from '@braincube/ui-lab';

import { apiRefPropTypes, useDataGridPro } from '../DataGridPro';
import { NoResultsOverlay, NoRowsOverlay } from '../Overlay';

const EDITION_DRAWER_WIDTH = 550;

const StyledSearchContainer = styled(`div`)(({ theme }) => ({
    marginRight: theme.spacing(2),
    width: 250,
}));

const StyledMiddleSide = styled(`div`, {
    shouldForwardProp: (prop) => prop !== 'editionMode',
})(({ theme, editionMode }) => ({
    flex: 1,
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${theme.header.height}px)`,
    marginRight: editionMode ? EDITION_DRAWER_WIDTH : 0,
}));

const StyledDrawer = styled(TemporaryDrawer)({
    '.MuiDrawer-paper': {
        width: EDITION_DRAWER_WIDTH,
        backgroundColor: grey[200],
    },
});

const StyledDialog = styled(Dialog)({
    '.MuiDialog-paper': {
        width: '100%',
    },
});

const StyledCard = styled(Card)({
    height: '100%',
});

const StyledCardContent = styled(CardContent)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});

const StyledFiltersArea = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const StyledCreator = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
}));

const components = {
    NoResultsOverlay,
    NoRowsOverlay,
    ColumnFilteredIcon: FilterList,
};

function getRowId(row) {
    return row.uuid || row.id || JSON.stringify(row);
}

/**
 * This component takes as input a list of entities and a schema description for one entity.
 * From there, it allows to filter, visualize, edit and add an entity.
 * Add and modify pass through functions that must return a component.
 */
function EntityManager({
    entities: entitiesProp,
    columns,
    addRenderer,
    filter,
    loadingDataPending,
    editRenderer,
    delRenderer,
    canAdd,
    onDeletion,
    apiRef,
    creationLabel,
}) {
    const i18n = useI18n();

    const [searchValue, setSearchValue] = useState('');
    const [entities, setEntities] = useState(entitiesProp);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [isNewEntity, setIsNewEntity] = useState(false);
    const [isDeletion, setIsDeletion] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: columns[0].field,
            sort: 'asc',
        },
    ]);

    const { dataGridProClasses, dataGridProLocale } = useDataGridPro();

    const handleFiltering = useCallback(
        (filterValue = searchValue) => {
            const allEntities = entitiesProp;

            const reg = Util.getGlobalRegExp(filterValue, true, true);

            let filteredEntities = allEntities;

            if (filterValue !== '') {
                filteredEntities = allEntities.filter((row) => {
                    // We only keep lines that have at least one element that matches the regular expression
                    return (
                        Object.values(row).filter((value) => {
                            return reg.test(value);
                        }).length > 0
                    );
                });
            }

            setEntities(filteredEntities);
            setSearchValue(filterValue);
        },
        [entitiesProp, searchValue]
    );

    useEffect(() => {
        setEntities(entitiesProp);
        handleFiltering();
    }, [entitiesProp, handleFiltering]);

    useEffect(() => {
        if (onDeletion) {
            setSelectedEntity(onDeletion);
            setIsDeletion(true);

            apiRef.current.selectRow(getRowId(onDeletion));
        }
    }, [apiRef, onDeletion]);

    const onCellClick = useCallback(
        ({ row }) => {
            if (selectedEntity !== row) {
                // delete rowData.rm;
                setIsNewEntity(false);
                setSelectedEntity(row);
            }
        },
        [selectedEntity]
    );

    const componentProps = useMemo(
        () => ({
            noRowsOverlay: {
                onCreate: () => setIsNewEntity(true),
                creationLabel,
                fromQuickSearch: searchValue !== '', // To display NO_RESULT type on quick search filter usage
            },
        }),
        [creationLabel, searchValue]
    );

    const rows = useMemo(() => [...entities], [entities]);

    const onSortModelChange = useCallback((model) => setSortModel(model), []);

    const handleCellClick = useCallback(
        (cell) => cell.field && cell.field !== 'actions' && onCellClick(cell),
        [onCellClick]
    );

    const onCreation = useCallback(() => setIsNewEntity(true), []);

    const unselectRows = useCallback(() => {
        const selectedRowsIds = [];
        apiRef.current.getSelectedRows().forEach((row) => selectedRowsIds.push(getRowId(row)));
        apiRef.current.selectRows(selectedRowsIds, false);
    }, [apiRef]);

    const closeDrawer = useCallback(() => {
        setIsNewEntity(false);
        setSelectedEntity(null);
        setIsDeletion(false);

        unselectRows();
    }, [unselectRows]);

    return (
        <>
            <StyledMiddleSide editionMode={(selectedEntity || isNewEntity) && !isDeletion}>
                <StyledCard>
                    <StyledCardContent>
                        <StyledFiltersArea>
                            <StyledSearchContainer>
                                <Search
                                    label={i18n.tc('ssoAdmin.search')}
                                    searchValue={searchValue}
                                    onChange={handleFiltering}
                                />
                            </StyledSearchContainer>
                            {filter || null}
                        </StyledFiltersArea>

                        <DataGridPro
                            apiRef={apiRef}
                            loading={loadingDataPending}
                            className={dataGridProClasses}
                            localeText={dataGridProLocale}
                            columns={columns}
                            rows={rows}
                            getRowId={getRowId}
                            sortModel={sortModel}
                            onSortModelChange={onSortModelChange}
                            onCellClick={handleCellClick}
                            pagination={false}
                            hideFooter
                            components={components}
                            componentsProps={componentProps}
                        />
                    </StyledCardContent>
                </StyledCard>
                {!selectedEntity && !isNewEntity && canAdd && (
                    <StyledCreator>
                        <Fab variant="extended" color="primary" onClick={onCreation}>
                            {creationLabel}
                        </Fab>
                    </StyledCreator>
                )}
            </StyledMiddleSide>
            {(selectedEntity || isNewEntity) && (
                <>
                    <StyledDrawer
                        mode="internal"
                        anchor="right"
                        variant="persistent"
                        open={!isDeletion}
                        onClose={closeDrawer}
                    >
                        {isNewEntity ? addRenderer(() => setIsNewEntity(false)) : null}

                        {selectedEntity
                            ? editRenderer(selectedEntity, () => {
                                  unselectRows();
                                  setSelectedEntity(null);
                                  setIsDeletion(false);
                              })
                            : null}
                    </StyledDrawer>
                    <StyledDialog open={isDeletion} fullWidth>
                        {isDeletion
                            ? delRenderer(selectedEntity, () => {
                                  unselectRows();
                                  setSelectedEntity(null);
                                  setIsDeletion(false);
                              })
                            : null}
                    </StyledDialog>
                </>
            )}
        </>
    );
}

EntityManager.propTypes = {
    /** A function that returns a component */
    addRenderer: PropTypes.func,
    /** The header columns of the table */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string.isRequired,
        })
    ).isRequired,
    /** The entities to manage, each entity will be represented by a row in the table */
    entities: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * A function that returns a component, two parameters: the selected row and a function to call to return to the
     * add mode
     */
    editRenderer: PropTypes.func.isRequired,
    delRenderer: PropTypes.func,
    /** An optional element to render next to the SearchField */
    filter: PropTypes.element,
    /** Indicates if a loader is needed (data fetching) */
    loadingDataPending: PropTypes.bool,
    canAdd: PropTypes.bool,
    onDeletion: PropTypes.shape({}),
    apiRef: apiRefPropTypes.isRequired,
    creationLabel: PropTypes.string,
};

EntityManager.defaultProps = {
    addRenderer: null,
    filter: null,
    loadingDataPending: false,
    canAdd: true,
    delRenderer: null,
    onDeletion: null,
    creationLabel: '',
};

export default EntityManager;
