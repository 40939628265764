import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';

import { useI18n } from '@braincube/i18n';

function EditButton({ onClick, disabled }) {
    const i18n = useI18n();

    return (
        <Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
            {i18n.tc('ssoAdmin.actions.save')}
        </Button>
    );
}

EditButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

EditButton.defaultProps = {
    disabled: false,
};

export default EditButton;
