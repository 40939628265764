import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import UserGroupAddition from './UserGroupAddition';
import UserGroupEdition from './UserGroupEdition';
import UserGroupDeletion from './UserGroupDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const ssoWs = new SsoWsClient();

function AddRenderer({ onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <UserGroupAddition onAdd={onAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ userGroup, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <UserGroupEdition userGroup={userGroup} onDelete={onUpdate} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

function DelRenderer({ userGroup, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <UserGroupDeletion userGroup={userGroup} onDelete={onUpdate} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}
/**
 * Groups management
 */
function UserGroups() {
    const { dispatch, state } = useContext(AppContext);

    const [userGroups, setUserGroups] = useState([]);
    const [deletingGroup, setDeletingGroup] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const fetchUserGroups = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.getAllUserGroups({
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setUserGroups(response.groups);
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
    }, [dispatch, enqueueSnackbar]);

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer onFetch={fetchUserGroups} onHideDrawer={hideDrawers} />,
        [fetchUserGroups]
    );

    const editRenderer = useCallback(
        (userGroup, hideDrawers) => (
            <EditRenderer userGroup={userGroup} onFetch={fetchUserGroups} onHideDrawer={hideDrawers} />
        ),
        [fetchUserGroups]
    );

    const delRenderer = useCallback(
        (userGroup, hideDrawers) => (
            <DelRenderer userGroup={userGroup} onFetch={fetchUserGroups} onHideDrawer={hideDrawers} />
        ),
        [fetchUserGroups]
    );

    const handleDelete = useCallback(
        (groupId) => {
            const groupToDelete = userGroups.find((group) => group.id === groupId);

            if (groupToDelete) {
                setDeletingGroup(groupToDelete);
            }
        },
        [userGroups]
    );

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: i18n.tc('ssoAdmin.userGroups.headerName.id'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'cn',
                headerName: i18n.tc('ssoAdmin.userGroups.headerName.cn'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'gidNumber',
                headerName: i18n.tc('ssoAdmin.userGroups.headerName.gidNumber'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'posixGroup',
                headerName: i18n.tc('ssoAdmin.userGroups.headerName.posixGroup'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ row }) => (
                    <Chip color={row.posixGroup ? 'success' : 'error'} label={row.posixGroup.toString()} />
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    useEffect(fetchUserGroups, [fetchUserGroups]);

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingGroup}
            entities={userGroups}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.userGroups.addition.title')}
        />
    );
}

export default UserGroups;
