import Check from '@braincube/brain-js/lib/check';

import BrainWs from './BrainWs';
import BrainWsEnums from './BrainWsEnums';

/**
 * A method that handles an asynchronous ajax query
 *
 * @param {string} url - The url on which to execute the query
 * @param {BraincubeWsEvents} events
 * @param {string} [httpMethod=GET] HTTP method (GET, POST, PUT, DELETE...). Defaults to GET.
 * @param {*} [data=null] - Parameters for POST or PUT request
 * @param {Array} [headers=[]]
 * @param {boolean} [withCredentials=false]
 */
const brainRequest = (
    url,
    events,
    httpMethod = BrainWsEnums.HttpMethods.GET,
    data = null,
    headers = [],
    withCredentials = false
) => {
    const xhr = new XMLHttpRequest();

    xhr.open(httpMethod, url, true);

    if (events.onError) {
        xhr.onerror = events.onError;
    }

    if (events.onTimeout) {
        xhr.timeout = !Check.isUndefinedOrNull(events.onTimeout.delay) ? events.onTimeout.delay : 20000; // milliseconds
        xhr.ontimeout = events.onTimeout.fn;
    }

    if (events.onAbort) {
        xhr.onabort = events.onAbort;
    }

    xhr.onload = () => {
        if (xhr.status >= 400) {
            if (events.onError) {
                events.onError(BrainWs.buildBrainWsResponseObject(xhr.status, xhr.statusText), xhr);
            }
        } else if (events.onSuccess) {
            let result;

            try {
                if (xhr.status === 201) {
                    result = xhr.getResponseHeader('Location');
                } else {
                    result = JSON.parse(xhr.responseText);
                }
            } catch (e) {
                result = xhr.responseText || xhr.responseXML;
            }

            events.onSuccess(BrainWs.buildBrainWsResponseObject(xhr.status, result), xhr);
        }
    };

    xhr.withCredentials = withCredentials;

    headers.forEach((header) => {
        xhr.setRequestHeader(header.name, header.value);
    });

    xhr.send(data);

    return xhr;
};

/**
 * @module brainRequest
 */
export default brainRequest;
