import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { colors, IconButton, Tooltip } from '@mui/material';
import { Check, Clear, Delete, Edit } from '@mui/icons-material';

import { useI18n } from '@braincube/i18n';

import { apiRefPropTypes } from '../../services';

function ActionsCell({ id, apiRef, onSave, preventEdit, onEdit, preventDelete, onDelete }) {
    const {
        current: { getRow, getRowMode, updateRows },
    } = apiRef;
    const i18n = useI18n();

    const isInEditMode = getRowMode(id) === 'edit';

    const handleSaveRow = useCallback(() => {
        onSave(id);
    }, [id, onSave]);

    const handleEditRow = useCallback(() => {
        const rowToEdit = getRow(id);
        onEdit(rowToEdit);
    }, [getRow, id, onEdit]);

    const handleDeleteRow = useCallback(() => {
        onDelete(id);
    }, [id, onDelete]);

    const handleRemoveRow = useCallback(() => {
        updateRows([{ id, _action: 'delete' }]);
    }, [id, updateRows]);

    return isInEditMode ? (
        <>
            <Tooltip title={i18n.tc('common.validate')}>
                <IconButton onClick={handleSaveRow} data-testid="dataGrid-saveRow" size="large">
                    <Check htmlColor={colors.green[500]} />
                </IconButton>
            </Tooltip>
            <Tooltip title={i18n.tc('common.cancel')}>
                <IconButton onClick={handleRemoveRow} data-testid="dataGrid-removeRow" color="secondary" size="large">
                    <Clear htmlColor={colors.red[500]} />
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <>
            {!preventEdit && (
                <Tooltip title={i18n.tc('common.edit')}>
                    <IconButton onClick={handleEditRow} data-testid="dataGrid-editRow" size="large">
                        <Edit />
                    </IconButton>
                </Tooltip>
            )}

            {!preventDelete && (
                <Tooltip title={i18n.tc('common.delete')}>
                    <IconButton onClick={handleDeleteRow} data-testid="dataGrid-deleteRow" size="large">
                        <Delete />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
}

ActionsCell.propTypes = {
    id: PropTypes.string.isRequired,
    apiRef: apiRefPropTypes.isRequired,
    onSave: PropTypes.func,
    preventEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    preventDelete: PropTypes.bool,
    onDelete: PropTypes.func,
};

ActionsCell.defaultProps = {
    onSave: () => {},
    preventEdit: false,
    onEdit: () => {},
    preventDelete: false,
    onDelete: () => {},
};

export default ActionsCell;
