import React from 'react';
import PropTypes from 'prop-types';
import { styled, Tooltip } from '@mui/material';

const StyledTooltip = styled(Tooltip)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

function CellTooltip({ value }) {
    return value ? (
        <StyledTooltip title={value}>
            <div>{value}</div>
        </StyledTooltip>
    ) : (
        ''
    );
}

CellTooltip.propTypes = {
    value: PropTypes.string,
};

CellTooltip.defaultProps = {
    value: null,
};

export default CellTooltip;
