import React from 'react';

import { NavMenu } from '@braincube/ui';

import AppsManagerNavList from '../NavList/AppsManager';
import SsoNavList from '../NavList/Sso';
import { APP_STORE_ROUTES } from '../App';

function Menu({ location }) {
    return (
        <NavMenu drawerWidth={260}>
            {APP_STORE_ROUTES.includes(location.pathname) ? <AppsManagerNavList /> : <SsoNavList />}
        </NavMenu>
    );
}

export default Menu;
