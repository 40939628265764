import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import ProviderAddition from './ProviderAddition';
import ProviderEdition from './ProviderEdition';
import ProviderDeletion from './ProviderDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

function AddRenderer({ onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <ProviderAddition onAdd={onAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ provider, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <ProviderEdition provider={provider} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

function DelRenderer({ provider, setDeletingProvider, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingProvider(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingProvider]);

    return <ProviderDeletion provider={provider} onDelete={onDelete} onCancel={onCancel} />;
}

/**
 * Providers management
 */
function Providers() {
    const { dispatch, state } = useContext(AppContext);

    const [providers, setProviders] = useState([]);
    const [deletingProvider, setDeletingProvider] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const fetchProviders = useCallback(() => {
        dispatch(setIsFetching(true));

        new SsoWsClient().getAllProviders({
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setProviders(response.providers);
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
    }, [dispatch, enqueueSnackbar]);

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer onFetch={fetchProviders} onHideDrawer={hideDrawers} />,
        [fetchProviders]
    );

    const editRenderer = useCallback(
        (provider, hideDrawers) => (
            <EditRenderer provider={provider} onFetch={fetchProviders} onHideDrawer={hideDrawers} />
        ),
        [fetchProviders]
    );

    const delRenderer = useCallback(
        (provider, hideDrawers) => (
            <DelRenderer
                provider={provider}
                setDeletingProvider={setDeletingProvider}
                onFetch={fetchProviders}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchProviders]
    );

    const handleDelete = useCallback((provider) => setDeletingProvider(JSON.parse(provider)), []);

    useEffect(fetchProviders, [fetchProviders]);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.providers.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'url',
                headerName: i18n.tc('ssoAdmin.providers.headerName.url'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'vendor',
                headerName: i18n.tc('ssoAdmin.providers.headerName.vendor'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    useEffect(fetchProviders, [fetchProviders]);

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            loadingDataPending={state.isFetching}
            onDeletion={deletingProvider}
            entities={providers}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            creationLabel={i18n.tc('ssoAdmin.providers.addition.title')}
        />
    );
}

export default Providers;
