import { Button, Stack, styled, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { getAkuiteoStatus, getOffers, reloadAkuiteo } from '../../../../wsClient/AppsManagerWsClient';
import EntityManager from '../../../EntityManager';
import OfferEdition from './OfferEdition';
import CellTooltip from '../../../CellTooltip';
import { AppContext, setIsFetching } from '../../../../app-context';

function EditRenderer({ offer, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <OfferEdition offer={offer} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2, 0, 0, 2),
}));

/**
 * Offers management
 */
function OffersComponent() {
    const [offers, setOffers] = useState([]);
    const { dispatch, state } = useContext(AppContext);
    const i18n = useI18n();
    const apiRef = useGridApiRef();
    const { enqueueSnackbar } = useSnackbar();

    const fetchOffers = useCallback(() => {
        dispatch(setIsFetching(true));

        getOffers().then((response) => {
            if (response.ok) {
                response.json().then(setOffers);
            }

            dispatch(setIsFetching(false));
        });
    }, [dispatch]);

    const editRenderer = useCallback(
        (offer, hideDrawers) => <EditRenderer offer={offer} onFetch={fetchOffers} onHideDrawer={hideDrawers} />,
        [fetchOffers]
    );

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.offers.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
        ],
        [i18n]
    );

    useEffect(fetchOffers, [fetchOffers]);

    const handleReloadAkuiteo = useCallback(() => {
        reloadAkuiteo()
            .then(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.offers.notistack.akuiteo.success'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.offers.notistack.akuiteo.error'), { variant: 'error' });
            });
    }, [enqueueSnackbar, i18n]);

    const [akuiteoStatus, setAkuiteoStatus] = useState(null);

    useEffect(() => {
        getAkuiteoStatus().then(setAkuiteoStatus);
    }, []);

    const akuiteoTooltip = useMemo(
        () => (
            <Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography variant="caption">{i18n.tc('ssoAdmin.offers.akuiteo.offersCount')}</Typography>
                    <Typography variant="caption">{akuiteoStatus?.offersCount}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography variant="caption">{i18n.tc('ssoAdmin.offers.akuiteo.lastCheck')}</Typography>
                    <Typography variant="caption">{akuiteoStatus?.lastCheck || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography variant="caption">{i18n.tc('ssoAdmin.offers.akuiteo.lastReload')}</Typography>
                    <Typography variant="caption">{akuiteoStatus?.lastReload || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography variant="caption">{i18n.tc('ssoAdmin.offers.akuiteo.lastFailed')}</Typography>
                    <Typography variant="caption">{akuiteoStatus?.lastFailed || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Typography variant="caption">{i18n.tc('ssoAdmin.offers.akuiteo.lastFailed')}</Typography>
                    <Typography variant="caption">{akuiteoStatus?.nextCheck || '-'}</Typography>
                </Stack>
            </Stack>
        ),
        [
            akuiteoStatus?.lastCheck,
            akuiteoStatus?.lastFailed,
            akuiteoStatus?.lastReload,
            akuiteoStatus?.nextCheck,
            akuiteoStatus?.offersCount,
            i18n,
        ]
    );

    return (
        <>
            <Tooltip title={akuiteoTooltip}>
                <StyledButton onClick={handleReloadAkuiteo} variant="contained">
                    reload akuiteo
                </StyledButton>
            </Tooltip>
            <EntityManager
                apiRef={apiRef}
                columns={columns}
                entities={offers}
                editRenderer={editRenderer}
                loadingDataPending={state.isFetching}
                canAdd={false}
            />
        </>
    );
}

export default OffersComponent;
