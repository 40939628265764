import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { ContentArea, DrawerActions, DrawerContent } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

/**
 * Add provider management
 */
function ProviderAddition({ onAdd, onCancel }) {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [vendor, setVendor] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        new SsoWsClient().createProvider(
            { name, url, vendor },
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.providers.notistack.create.success'), { variant: 'success' });

                    setName('');
                    setUrl('');
                    setVendor('');
                    onAdd();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [enqueueSnackbar, i18n, name, onAdd, onCancel, url, vendor]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleVendorChange = useCallback((e) => setVendor(e.target.value), []);

    return (
        <Addition label={i18n.tc('ssoAdmin.providers.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            required
                            label={i18n.tc('ssoAdmin.providers.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.providers.fields.url')}
                            value={url}
                            onChange={handleUrlChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        label={i18n.tc('ssoAdmin.providers.fields.vendor')}
                        value={vendor}
                        onChange={handleVendorChange}
                        fullWidth
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={handleAddition} label={i18n.t('ssoAdmin.providers.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

ProviderAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProviderAddition;
