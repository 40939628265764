import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { deployEverywhere } from '../../../../wsClient/AppsManagerWsClient';
import { AppContext, setIsFetching } from '../../../../app-context';

/**
 * This component displays a link to deploy application everywhere.
 */
function DeployEverywhereLink({ appId }) {
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const onDeploySuccess = useCallback(() => {
        enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.deploy.success'), { variant: 'success' });
    }, [enqueueSnackbar, i18n]);

    const onDeployError = useCallback(() => {
        enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.deploy.error'), { variant: 'error' });
    }, [enqueueSnackbar, i18n]);

    const onClick = useCallback(() => {
        dispatch(setIsFetching(true));
        deployEverywhere(appId).then((response) => {
            dispatch(setIsFetching(false));

            if (response.ok) {
                onDeploySuccess();
            } else {
                onDeployError();
            }
        });
    }, [appId, dispatch, onDeployError, onDeploySuccess]);

    return (
        <div>
            <Button variant="contained" fullWidth onClick={onClick}>
                {i18n.tc('ssoAdmin.apps.deployEverywhere')}
            </Button>
        </div>
    );
}

DeployEverywhereLink.propTypes = {
    /** Application id */
    appId: PropTypes.string.isRequired,
};

export default DeployEverywhereLink;
