import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import Deletion from '../../../EntityManager/Deletion';
import { DELEGATE_PROTOCOL } from './DelegateAddition';
import DeleteButton from '../../../Buttons/Delete';
import CancelButton from '../../../Buttons/Cancel';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit group management
 */
function DelegateDeletion(props) {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        ssoWs.deleteDelegate(props.delegate.name, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.delegates.notistack.delete.success'), { variant: 'success' });
                props.onDelete();
            },
            onError: ({ httpCode }) => {
                let msg = i18n.tc('ssoAdmin.delegates.notistack.delete.error.default');
                if (httpCode === 400) msg = i18n.tc('ssoAdmin.delegates.notistack.delete.error.invalidName');
                if (httpCode === 404) msg = i18n.tc('ssoAdmin.delegates.notistack.delete.error.notFound');
                enqueueSnackbar(msg, { variant: 'error' });
                props.onDelete();
            },
        });
    }, [enqueueSnackbar, i18n, props]);

    return (
        <Deletion
            label={i18n.tc('ssoAdmin.delegates.deletion.title')}
            onCancel={props.onCancel}
            handleDelete={handleDelete}
        >
            <FormControl component="fieldset">
                <StyledSpacedWrapper>
                    <TextField
                        label={i18n.tc('ssoAdmin.delegates.fields.name')}
                        disabled
                        value={props.delegate.name}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <StyledSpacedWrapper>
                    <FormControl disabled fullWidth>
                        <InputLabel>{i18n.tc('ssoAdmin.delegates.fields.protocol')}</InputLabel>
                        <Select value={props.delegate.protocol} label={i18n.tc('ssoAdmin.delegates.fields.protocol')}>
                            {DELEGATE_PROTOCOL.map((proto) => (
                                <MenuItem key={proto.value} value={proto.value}>
                                    {proto.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </StyledSpacedWrapper>
            </FormControl>
            <StyledButtonContainer>
                <CancelButton onClick={props.onCancel} />
                <DeleteButton onClick={handleDelete} />
            </StyledButtonContainer>
        </Deletion>
    );
}

DelegateDeletion.propTypes = {
    delegate: PropTypes.shape({
        name: PropTypes.string.isRequired,
        protocol: PropTypes.string.isRequired,
        configuration: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DelegateDeletion;
