import * as importedLogos from '@braincube/svg';

const apps = [
    'Account',
    'AdminSso',
    'Admin',
    'AdvancedAnalitycs',
    'Alerts',
    'Alias',
    'ApiManager',
    'AppsManager',
    'AutomateRead',
    'AutomateWrite',
    'Automate',
    'AutonomousPlantStrategyManager',
    'Brainbox',
    'BraincubeApi',
    'BraincubeTransfert',
    'Buzz',
    'CalculatedVariablesRunner',
    'Charts',
    'Comparator',
    'ComputerVision',
    'ConnectorAutomate',
    'ConnectorDatabase',
    'ControlCharts',
    'Counter',
    'CriticalParametersMonitoring',
    'CsvExport',
    'Dashboard',
    'DataFlow',
    'DataGenerator',
    'Data',
    'DfmLogs',
    'DigitalTwinBuilder',
    'ElectronicBatchRecord',
    'EthernetipRead',
    'EthernetipWrite',
    'Ethernetip',
    'ExportBraincube',
    'ExportCsv',
    'ExportMindSphere',
    'Export',
    'Faq',
    'FeedbackLabelling',
    'FileTransfer',
    'Forms',
    'Frequency',
    'FtpSftp',
    'GoogleCloud',
    'GoogleSheets',
    'GraphGlobal',
    'Homogeneity',
    'HpcReport',
    'Interoperability',
    'IotServerSupervision',
    'IotServerTransfer',
    'IotServer',
    'IssueToolbox',
    'LiveApplicationReport',
    'LiveCenterlining',
    'LiveInstructions',
    'LivePrescriptions',
    'Lms',
    'Logbook',
    'MachineStatus',
    'ManageProject',
    'MindSphereApi',
    'ModelBuilder',
    'ModelManager',
    'ModelRunner',
    'MsSql',
    'Node',
    'NumericalFilter',
    'Oee',
    'OpcDaHda',
    'Opc',
    'Opcda',
    'Opcua',
    'Oscilloscope',
    'OsisoftExportImport',
    'Palette',
    'Percall',
    'QuickBrainbox',
    'ProductionStandard',
    'RapidMinerSymbol',
    'RapidMiner',
    'Report',
    'SasSymbol',
    'Sas',
    'ServiceManager',
    'Sftp',
    'SmartReco',
    'Smb',
    'Snapshot',
    'Snmp',
    'SpcReport',
    'StorageManager',
    'Studio',
    'Table',
    'ThreeDimensionsChart',
    'Timeout',
    'TimestampConverter',
    'Todo',
    'Transformation',
    'Trends',
    'UndefinedLogo',
    'UsbCamera',
    'VariationDetector',
    'VirtualTwinBuilder',
    'WidgetPanel',
    'WorkstationManager',
];

const appsIcons = Object.keys(importedLogos).filter((logo) => apps.includes(logo));
const getBraincubeUiComponent = (icon) => importedLogos[icon];

export { appsIcons, getBraincubeUiComponent };
