import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions, FormControlLabel, styled, Switch, Autocomplete, ListItem } from '@mui/material';
import { validate } from 'uuid';

import { useI18n } from '@braincube/i18n';
import { naturalSort } from '@braincube/ui-lab/lib/services/advancedTextSearch';
import { AutocompleteInputRender, SmallCheckBox } from '@braincube/ui-lab';

import InfoTooltip from '../../../../InfoTooltip';

const StyledInfoTooltip = styled(`div`)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
}));

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

const filter = createFilterOptions();

const createOption = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue && validate(params.inputValue)) {
        filtered.push({
            label: params.inputValue,
            value: params.inputValue,
        });
    }

    return filtered;
};

function Visibility({ isPublished, selectedProducts, productsList, onChange }) {
    const [isPublishedApp, setIsPublishedApp] = useState(isPublished);
    const [selectedProductsOnApp, setSelectedProductsOnApp] = useState(
        productsList
            .filter((productItem) => selectedProducts.includes(productItem.uuid))
            .map((product) => ({
                label: product.title,
                value: product.uuid,
            }))
    );
    const i18n = useI18n();

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.selectedProducts')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const getProductsList = useMemo(() => {
        return productsList
            .map((productItem) => {
                return {
                    label: productItem.title,
                    value: productItem.uuid,
                };
            })
            .sort((a, b) => naturalSort(a, b, ['label']));
    }, [productsList]);

    const handleSelectedProductsChange = useCallback(
        (event, value) => {
            setSelectedProductsOnApp(value);
            onChange(isPublishedApp, value);
        },
        [isPublishedApp, onChange]
    );

    const handleIsPublishedChange = useCallback(
        (event, checked) => {
            setIsPublishedApp(checked);

            if (checked) {
                setSelectedProductsOnApp([]);
                onChange(checked, []);
            } else {
                onChange(checked, selectedProductsOnApp);
            }
        },
        [onChange, selectedProductsOnApp]
    );

    const getSelectedOption = useCallback((option, value) => option.value === value.value, []);

    const switchControl = useMemo(
        () => <Switch color="primary" checked={isPublishedApp} onChange={handleIsPublishedChange} />,
        [handleIsPublishedChange, isPublishedApp]
    );

    return (
        <>
            <StyledInfoTooltip>
                <FormControlLabel control={switchControl} label={i18n.tc('ssoAdmin.apps.isPublished')} />
                <InfoTooltip text={i18n.tc('ssoAdmin.apps.isPublishedHelp')} />
            </StyledInfoTooltip>
            <Autocomplete
                filterOptions={createOption}
                freeSolo
                disabled={isPublished}
                selectOnFocus
                clearOnBlur
                multiple
                handleHomeEndKeys
                disableCloseOnSelect
                renderInput={renderInput}
                renderOption={renderOption}
                options={getProductsList}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={getSelectedOption}
                value={selectedProductsOnApp}
                onChange={handleSelectedProductsChange}
            />
        </>
    );
}

Visibility.propTypes = {
    /**
     * Define if the app is published or not
     */
    isPublished: PropTypes.bool.isRequired,
    /**
     * The selected products list
     */
    selectedProducts: PropTypes.array.isRequired,
    /**
     * The selectable products list
     */
    productsList: PropTypes.array.isRequired,
    /**
     * Function to get changed values
     */
    onChange: PropTypes.func.isRequired,
};

export default Visibility;
