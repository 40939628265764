import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ACCESS_TYPES from 'components/GrantList/accessTypes';
import PRODUCT_TYPES from 'components/Routes/Sso/Products/productTypes';

import GrantList from '../../../GrantList';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

const ssoWs = new SsoWsClient();

function getUsers(onSuccess, onError) {
    const events = { onSuccess, onError };
    return ssoWs.getAllUsers(events);
}

function mapUsers(response) {
    const users = [];

    response.users.forEach((user) => {
        users[user.email] = user.email;
    });

    return users;
}

export function getAvailableGrantsLevels(productType) {
    switch (productType) {
        case PRODUCT_TYPES.TAMPIX.value:
            return [ACCESS_TYPES.ADMIN, ACCESS_TYPES.USER];
        case PRODUCT_TYPES.MX.value:
        case PRODUCT_TYPES.IOT.value:
        case PRODUCT_TYPES.IOT_STACK.value:
            return [ACCESS_TYPES.ADMIN, ACCESS_TYPES.EXPERT, ACCESS_TYPES.USER];
        case PRODUCT_TYPES.PLATFORM.value:
            return [ACCESS_TYPES.IPLWADMIN];
        case PRODUCT_TYPES.MODEL_MANAGER.value:
            return [ACCESS_TYPES.IPLWADMIN, ACCESS_TYPES.COACH, ACCESS_TYPES.ADMIN, ACCESS_TYPES.USER];
        case PRODUCT_TYPES.ADMIN.value:
            return [ACCESS_TYPES.IPLWADMIN];
        case PRODUCT_TYPES.BRAINCUBE.value:
        default:
            return Object.values(ACCESS_TYPES);
    }
}

/**
 * This component is displayed in a user's edit popin and is used to edit, add or remove grants.
 *
 * It's an EntityManager inside another EntityManager (the list of the users)
 */
function ProductGrantsList({ productId, providerName, productType }) {
    const onGrantAdding = useCallback(
        (grantToAdd, onSuccess, onError) => {
            grantToAdd.productId = productId;

            const events = { onSuccess, onError };
            ssoWs.addGrantForProduct(grantToAdd, providerName, productId, events);
        },
        [productId, providerName]
    );

    const onGrantEditing = useCallback(
        (grantToUpdate, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.updateGrant(grantToUpdate, grantToUpdate.userId, productId, events);
        },
        [productId]
    );

    const onGrantDeleting = useCallback(
        (grantToDelete, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.deleteUserGrant(providerName, productId, grantToDelete.userId, events);
        },
        [productId, providerName]
    );

    const getTempGrants = useCallback(
        (grants, onSuccess, onError) => {
            const events = {
                onSuccess: ({ response }) => {
                    onSuccess(
                        response.accessList.concat(
                            grants.filter((grant) => {
                                let found = false;

                                response.accessList.forEach((access) => {
                                    if (access.userId === grant.userId) {
                                        found = true;
                                    }
                                });

                                return !found;
                            })
                        )
                    );
                },
                onError,
            };
            return ssoWs.getProductTempGrants(providerName, productId, events);
        },
        [productId, providerName]
    );

    const getGrants = useCallback(
        (onSuccess, onError) => {
            const events = {
                onSuccess: ({ response }) => {
                    getTempGrants(response.accessList, onSuccess, onError);
                },
                onError,
            };
            return ssoWs.getProductGrants(providerName, productId, events);
        },
        [getTempGrants, productId, providerName]
    );

    return (
        <GrantList
            key={productId} // useful to refresh the component when we select another product
            mapEntities={mapUsers}
            entityKeyName="userId"
            entityLabel="Email"
            getGrants={getGrants}
            getEntities={getUsers}
            handleGrantToAdd={onGrantAdding}
            handleGrantToEdit={onGrantEditing}
            handleGrantToDelete={onGrantDeleting}
            productType={productType}
        />
    );
}

ProductGrantsList.propTypes = {
    /** Product ID of the product opened */
    productId: PropTypes.string.isRequired,
    /** Name of the product provider */
    providerName: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
};

export default ProductGrantsList;
