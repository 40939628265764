import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import { createConsumer, activatePlan, getConsumers, getConsumer } from '../../../../wsClient/KongWsClient';
import EditButton from '../../../Buttons/Edit';
import { AppContext, setIsFetching } from '../../../../app-context';

const ssoWs = new SsoWsClient();

function Quota({ site }) {
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const [created, setCreated] = useState(false);
    const [activated, setActivated] = useState(false);
    const [consumer, setConsumer] = useState(undefined);
    const [quota, setQuota] = useState('');
    const [extraQuota, setExtraQuota] = useState('');
    const i18n = useI18n();

    useEffect(() => {
        setCreated(false);
        setActivated(false);
        ssoWs.getQuota(site.provider, site.groupName, site.siteName, {
            onSuccess: ({ response }) => {
                setQuota(response || '');
            },
            onError: () => {
                setQuota(null);
            },
        });
        ssoWs.getExtraQuota(site.provider, site.groupName, site.siteName, {
            onSuccess: ({ response }) => {
                setExtraQuota(response || '');
            },
            onError: () => {
                setExtraQuota(null);
            },
        });
        getConsumers()
            .then(({ data }) => {
                const consumerBySite = data.filter((item) => item.siteUuid === site.siteUuid);
                if (consumerBySite.length !== 0) {
                    setCreated(true);
                    getConsumer(consumerBySite[0].consumerId).then((item) => {
                        setActivated(true);
                        setConsumer(item);
                    });
                }
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.getConsumers.error'), { variant: 'error' });
            });
    }, [enqueueSnackbar, i18n, site]);

    const handleEdit = useCallback(() => {
        dispatch(setIsFetching(true));
        ssoWs.setQuota(site.provider, site.groupName, site.siteName, quota, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.update.success'), { variant: 'success' });
            },
            onError: (brainWsResponse, xhr) => {
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
        ssoWs.setExtraQuota(site.provider, site.groupName, site.siteName, extraQuota, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.update.success'), { variant: 'success' });
            },
            onError: (brainWsResponse, xhr) => {
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });

        if (!created) {
            createConsumer(site)
                .then((item) => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.createConsumer.success'), { variant: 'success' });
                    setCreated(true);
                    setConsumer(item);
                    activatePlan(item)
                        .then(() => {
                            setActivated(true);
                            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.activatePlan.success'), {
                                variant: 'success',
                            });
                        })
                        .catch(() => {
                            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.activatePlan.error'), {
                                variant: 'error',
                            });
                        });
                })
                .catch(() => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.createConsumer.error'), { variant: 'error' });
                });
        } else if (!activated) {
            activatePlan(consumer)
                .then(() => {
                    setActivated(true);
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.activatePlan.success'), { variant: 'success' });
                })
                .catch(() => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.activatePlan.error'), { variant: 'error' });
                });
        }
    }, [activated, consumer, created, dispatch, enqueueSnackbar, extraQuota, i18n, quota, site]);

    const handleQuotaChange = useCallback((e) => setQuota(e.target.value), []);

    const handleExtraQuotaChange = useCallback((e) => setExtraQuota(e.target.value), []);

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.sites.fields.quota')}
                                value={quota}
                                onChange={handleQuotaChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.sites.fields.extraQuota')}
                                value={extraQuota}
                                onChange={handleExtraQuotaChange}
                            />
                        </Grid>
                    </Grid>
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Quota.propTypes = {
    site: PropTypes.shape({
        provider: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        siteUuid: PropTypes.string.isRequired,
    }).isRequired,
};

export default Quota;
