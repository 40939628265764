import { useQuery, useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Fab, styled, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Save } from '@mui/icons-material';

import { fetchWithBearer } from '@braincube/jwt-browser';
import { useI18n } from '@braincube/i18n';

import SsoAceEditorWithSuspense from 'components/SsoAceEditor/WithSuspense';
import Diff from 'components/Prefs/diff';

function getGlobalPreferences() {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.preference}/global/`).then((response) => {
        if (response.ok) {
            return response.json();
        }

        return Promise.reject(response);
    });
}

function updateGlobalPreferences(prefs) {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.preference}/global/`, {
        method: 'PUT',
        body: JSON.stringify(JSON.parse(prefs)),
    });
}

const StyledFab = styled(Fab)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
}));

function Preferences() {
    const { data } = useQuery(['get-global-preferences'], getGlobalPreferences, {
        initialData: { settings: {} },
        select: (json) => JSON.stringify(json.settings, null, 2),
    });

    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const [tmp, setTmp] = useState(data);

    const { mutate, isSuccess, isError } = useMutation({ mutationFn: () => updateGlobalPreferences(tmp) });

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(i18n.tc('preferences.updateOk'), { variant: 'success' });
        }
    }, [isSuccess, enqueueSnackbar, i18n]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(i18n.tc('preferences.updateNok', { variant: 'error' }));
        }
    }, [isError, enqueueSnackbar, i18n]);

    useEffect(() => {
        setTmp(data);
    }, [data]);

    const isJSONValid = useMemo(() => {
        try {
            JSON.parse(tmp);
            return true;
        } catch (e) {
            return false;
        }
    }, [tmp]);

    const [confirm, setConfirm] = useState(false);

    const toggleConfirm = useCallback(() => {
        setConfirm(!confirm);
    }, [confirm]);

    const onSave = useCallback(() => {
        mutate();
        setConfirm(false);
    }, [mutate]);

    return (
        <>
            <StyledFab disabled={!isJSONValid} color="primary" onClick={toggleConfirm}>
                <Save />
            </StyledFab>
            <SsoAceEditorWithSuspense data={tmp} onChange={setTmp} height={window.innerHeight - theme.header.height} />
            {confirm && <Diff old={data} recent={tmp} onClose={toggleConfirm} onSave={onSave} />}
        </>
    );
}

export default Preferences;
