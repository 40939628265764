import { format } from 'date-fns';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useI18n } from '@braincube/i18n';

import ACCESS_TYPES from 'components/GrantList/accessTypes';

import { StyledSpacedWrapper } from '../StyledComponents';

const StyledTitle = styled(`div`)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > svg': {
        margin: theme.spacing(0, 1, 0, 1),
    },
}));

const StyledFirstTextFieldWrapper = styled(`div`)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

function doNothing() {}

const renderDateTimePickerInput = (props) => <TextField fullWidth {...props} />;

function labelFunc(date) {
    return format(date, 'MMM dd yyyy / hh:mm a');
}

/**
 * A Popin to edit a grant
 */
function GrantDelete(props) {
    const { accessType } = props.grant;
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        props.onDelete(props.grant);
        props.onClose();
    }, [props]);

    return (
        <Dialog onClose={props.onClose} open fullWidth>
            <DialogTitle>
                <StyledTitle>
                    <DeleteIcon />
                    {i18n.tc('ssoAdmin.grant.dialog.delete.title')}
                </StyledTitle>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <StyledFirstTextFieldWrapper>
                            <TextField disabled value={props.grantValue} label={props.entityLabel} fullWidth />
                        </StyledFirstTextFieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledSpacedWrapper>
                            <FormControl fullWidth>
                                <InputLabel>{i18n.tc('ssoAdmin.grant.fields.accessType')}</InputLabel>
                                <Select disabled value={accessType}>
                                    {Object.values(ACCESS_TYPES).map((access) => (
                                        <MenuItem key={access} value={access}>
                                            {access}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </StyledSpacedWrapper>
                    </Grid>
                    {props.grant.expireDate && (
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    onChange={doNothing}
                                    fullWidth
                                    disabled
                                    label={i18n.tc('ssoAdmin.grant.fields.expirationDate')}
                                    value={props.grant.expireDate || new Date()}
                                    labelFunc={labelFunc}
                                    renderInput={renderDateTimePickerInput}
                                />
                            </LocalizationProvider>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" onClick={handleDelete} color="primary" autoFocus>
                    {i18n.tc('ssoAdmin.actions.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

GrantDelete.propTypes = {
    /** The grant being edited */
    grant: PropTypes.shape({
        accessType: PropTypes.string.isRequired,
        expireDate: PropTypes.number,
    }).isRequired,
    /** The object of the grant, if it concerns a user his email, if it concerns a product his name */
    grantValue: PropTypes.string.isRequired,
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    /** Callback when the delete button is clicked */
    onDelete: PropTypes.func.isRequired,
    /** The label of the entity (such as Email or Product Name) */
    entityLabel: PropTypes.string.isRequired,
};

export default GrantDelete;
