import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, FormControlLabel, Checkbox, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';

/**
 * Add group management
 */
function UserGroupAddition({ onAdd, onCancel }) {
    const [cn, setCn] = useState('');
    const [posixGroup, setPosixGroup] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        new SsoWsClient().createUserGroup(
            { cn, posixGroup },
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.userGroups.notistack.create.success'), { variant: 'success' });

                    setCn('');
                    setPosixGroup(false);
                    onAdd();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [cn, enqueueSnackbar, i18n, onAdd, onCancel, posixGroup]);

    const handleCnChange = useCallback((e) => setCn(e.target.value), []);

    const handlePosixGroupChange = useCallback((e) => setPosixGroup(e.target.checked), []);

    const checkboxIcon = useMemo(
        () => <Checkbox checked={posixGroup} onChange={handlePosixGroupChange} />,
        [handlePosixGroupChange, posixGroup]
    );

    return (
        <Addition label={i18n.tc('ssoAdmin.userGroups.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label={i18n.tc('ssoAdmin.userGroups.fields.cn')}
                                value={cn}
                                onChange={handleCnChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={checkboxIcon}
                                label={i18n.tc('ssoAdmin.userGroups.fields.posixGroup')}
                            />
                        </Grid>
                    </Grid>
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={handleAddition} label={i18n.t('ssoAdmin.userGroups.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

UserGroupAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserGroupAddition;
