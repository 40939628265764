import { styled } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

import { Node } from '@braincube/svg';

import { getBraincubeUiComponent } from './icons';

const StyledAvatar = styled(Avatar, { shouldForwardProp: (prop) => prop !== 'app' })(({ theme, app }) => ({
    backgroundColor: app.color || theme.palette.primary.main,
    color: theme.palette.getContrastText(app.color || theme.palette.primary.main),
}));

function Icon({ app }) {
    let Logo = getBraincubeUiComponent(app.icon === 'default' ? 'Node' : app.icon);

    if (!Logo) {
        Logo = Node;
    }

    // eslint-disable-next-line max-len
    return (
        <StyledAvatar app={app}>
            <Logo />
        </StyledAvatar>
    );
}

Icon.propTypes = {
    app: PropTypes.shape({
        icon: PropTypes.string,
        color: PropTypes.string,
    }).isRequired,
};

export default Icon;
