import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import SiteAddition from './SiteAddition';
import SiteEdition from './SiteEdition';
import SiteDeletion from './SiteDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const ssoWs = new SsoWsClient();

function AddRenderer({ existingGroups, selectedProvider, onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <SiteAddition
            existingGroups={existingGroups}
            provider={selectedProvider}
            onAdd={onAdd}
            onCancel={onHideDrawer}
        />
    );
}

function EditRenderer({ site, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <SiteEdition site={site} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

function DelRenderer({ site, setDeletingSite, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingSite(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingSite]);

    return <SiteDeletion site={site} onDelete={onDelete} onCancel={onCancel} />;
}
/**
 * Sites management
 */
function Sites() {
    const { dispatch, state } = useContext(AppContext);

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [deletingSite, setDeletingSite] = useState(null);
    const [products, setProducts] = useState([]);
    const [existingGroups, setExistingGroups] = useState([]);
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const createExistingGroupsList = useCallback((sites) => {
        const existingGroupsList = sites.map((site) => site.groupName);

        // Remove double values and construct object for autocomplete
        const cleanedGroupsList = existingGroupsList
            .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), [])
            .map((group) => {
                return {
                    title: group,
                    value: group,
                };
            });

        setExistingGroups(cleanedGroupsList);
    }, []);

    const fetchSites = useCallback(() => {
        dispatch(setIsFetching(true));
        ssoWs.getSitesByProvider(selectedProvider, {
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setProducts(response.siteList);

                createExistingGroupsList(response.siteList);
            },
            onError: () => {
                dispatch(setIsFetching(false));
            },
        });
    }, [createExistingGroupsList, dispatch, selectedProvider]);

    const addRenderer = useCallback(
        (hideDrawers) => (
            <AddRenderer
                existingGroups={existingGroups}
                selectedProvider={selectedProvider}
                onFetch={fetchSites}
                onHideDrawer={hideDrawers}
            />
        ),
        [existingGroups, fetchSites, selectedProvider]
    );

    const editRenderer = useCallback(
        (site, hideDrawers) => <EditRenderer site={site} onFetch={fetchSites} onHideDrawer={hideDrawers} />,
        [fetchSites]
    );

    const delRenderer = useCallback(
        (site, hideDrawers) => (
            <DelRenderer
                site={site}
                setDeletingSite={setDeletingSite}
                onFetch={fetchSites}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchSites]
    );

    useEffect(() => {
        ssoWs.getAllProviders({
            onSuccess: ({ response }) => {
                setProviders(response.providers);
                setSelectedProvider(response.providers[0].name);
            },
            onError: () => {},
        });
    }, []);

    const handleDelete = useCallback((provider) => setDeletingSite(JSON.parse(provider)), []);

    const columns = useMemo(
        () => [
            {
                field: 'groupName',
                headerName: i18n.tc('ssoAdmin.sites.headerName.groupName'),
                flex: 1, // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'siteName',
                headerName: i18n.tc('ssoAdmin.sites.headerName.name'),
                flex: 1, // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    useEffect(() => {
        if (selectedProvider) {
            fetchSites();
        }
    }, [fetchSites, selectedProvider]);

    const filterChange = useCallback(({ target: { value } }) => {
        setSelectedProvider(value);
    }, []);

    const renderFilter = useMemo(
        () => (
            <FormControl>
                <InputLabel>{i18n.tc('ssoAdmin.sites.fields.provider')}</InputLabel>
                <Select
                    label={i18n.tc('ssoAdmin.sites.fields.provider')}
                    value={selectedProvider}
                    onChange={filterChange}
                >
                    {providers.map((provider) => (
                        <MenuItem key={provider.name} value={provider.name}>
                            {provider.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ),
        [filterChange, i18n, providers, selectedProvider]
    );

    if (providers.length === 0 || selectedProvider === null) {
        return null;
    }

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingSite}
            entities={products}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            filter={renderFilter}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.sites.addition.title')}
        />
    );
}

export default Sites;
