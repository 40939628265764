import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormControlLabel, Switch, TextField, Autocomplete, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { useMe } from '@braincube/header';
import { AutocompleteInputRender, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { addDistributor } from '../../../../wsClient/LicenceWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import { AppContext } from '../../../../app-context';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import { getOffers } from '../../../../wsClient/AppsManagerWsClient';

const inputProps = { maxLength: 2 };

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.label === value.label;
}

/**
 * Add distrib management
 */
function DistributorAddition({ siteList, onAdd, onCancel }) {
    const i18n = useI18n();

    const { user } = useMe();
    const [key, setKey] = useState('');
    const [name, setName] = useState('');
    const [extId, setExtId] = useState('');
    const [allowSmartGeneration, setAllowSmartGeneration] = useState(true);
    const [defaultDuration, setDefaultDuration] = useState(0);
    const [site, setSite] = useState(undefined);

    const [offer, setOffer] = useState(null);
    const [offersLoading, setOffersLoading] = useState(false);
    const [offersList, setOffersList] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const { state } = useContext(AppContext);

    const handleAddition = useCallback(() => {
        addDistributor({
            key,
            name,
            externalId: extId,
            allowSmartGeneration,
            defaultDuration,
            defaultSiteUuid: site ? site.siteUuid : '',
            defaultOfferUuid: offer?.value || null,
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.create.success'), { variant: 'success' });
                setKey('');
                setName('');
                setExtId('');
                setAllowSmartGeneration(false);
                setDefaultDuration(0);
                onAdd();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.distributors.notistack.create.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [
        allowSmartGeneration,
        defaultDuration,
        enqueueSnackbar,
        extId,
        i18n,
        key,
        name,
        offer?.value,
        onAdd,
        onCancel,
        site,
    ]);

    const handleKeyChange = useCallback((e) => setKey(e.target.value), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleExternalIdChange = useCallback((e) => setExtId(e.target.value), []);

    const handleAllowSmartGenerationChange = useCallback((event, checked) => setAllowSmartGeneration(checked), []);

    const renderSwitch = useMemo(
        () => <Switch checked={allowSmartGeneration} onChange={handleAllowSmartGenerationChange} color="primary" />,
        [allowSmartGeneration, handleAllowSmartGenerationChange]
    );

    const handleDefaultDurationChange = useCallback((e) => setDefaultDuration(e.target.value), []);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.distributors.fields.siteName')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const renderOfferInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.licence.addition.formFields.offer')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const selectedSiteValue = useMemo(
        () =>
            site
                ? {
                      label: site.siteName.concat(' - ', site.groupName),
                      value: site,
                  }
                : null,
        [site]
    );

    const handleSelectedSiteChange = useCallback((event, selectedItem) => {
        setSite(selectedItem?.value);
    }, []);

    const handleOfferChange = useCallback((event, selectedItem) => {
        setOffer(selectedItem);
    }, []);

    const populateOffers = useCallback(() => {
        setOffersLoading(true);
        getOffers()
            .then((response) => {
                if (response.ok) {
                    response.json().then((items) => {
                        setOffersList(
                            items
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((item) => ({ label: item.name, value: item.id }))
                        );
                    });
                }
            })
            .finally(() => setOffersLoading(false));
    }, []);

    useEffect(() => {
        populateOffers();
    }, [populateOffers]);

    return (
        <Addition label={i18n.tc('ssoAdmin.distributors.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            required
                            label={i18n.tc('ssoAdmin.distributors.fields.key')}
                            value={key}
                            onChange={handleKeyChange}
                            inputProps={inputProps}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.distributors.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            required
                            label={i18n.tc('ssoAdmin.distributors.fields.externalId')}
                            value={extId}
                            onChange={handleExternalIdChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControlLabel
                            control={renderSwitch}
                            label={i18n.tc('ssoAdmin.distributors.fields.allowSmartGeneration')}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            required={allowSmartGeneration}
                            disabled={!allowSmartGeneration}
                            label={i18n.tc('ssoAdmin.distributors.fields.defaultDuration')}
                            type="number"
                            value={defaultDuration}
                            onChange={handleDefaultDurationChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            disabled={!allowSmartGeneration}
                            selectOnFocus
                            handleHomeEndKeys
                            ListboxComponent={ListboxComponent}
                            renderInput={renderInput}
                            options={siteList.map((item) => ({
                                label: item.siteName.concat(' - ', item.groupName),
                                value: item,
                            }))}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={selectedSiteValue}
                            onChange={handleSelectedSiteChange}
                        />
                    </StyledSpacedWrapper>

                    <Autocomplete
                        loading={offersLoading}
                        options={offersList}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions
                        renderInput={renderOfferInput}
                        value={offer}
                        onChange={handleOfferChange}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton
                        onClick={handleAddition}
                        disabled={
                            (!user.rootAdmin && !state.meIsLicenceAdmin) ||
                            !key ||
                            !extId ||
                            (allowSmartGeneration && defaultDuration < 1)
                        }
                        label={i18n.t('ssoAdmin.distributors.addButton')}
                    />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

DistributorAddition.propTypes = {
    siteList: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DistributorAddition;
