import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Switch,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useI18n } from '@braincube/i18n';

import { getAvailableGrantsLevels } from 'components/Routes/Sso/Products/ProductGrantsList';
import ACCESS_TYPES from 'components/GrantList/accessTypes';

import FormField from '../FormField';
import { StyledSpacedWrapper } from '../StyledComponents';

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
}));

const StyledSwitchWrapper = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0),
}));

const renderDateTimePickerInput = (props) => <TextField fullWidth {...props} />;

function okLabel() {
    return (
        <Button variant="contained" color="primary">
            Ok
        </Button>
    );
}

function labelFunc(date) {
    return format(date, 'MMM dd yyyy / hh:mm a');
}

/**
 * A Popin to edit a grant
 */
function GrantEdition(props) {
    const [accessType, setAccessType] = useState(props.grant.accessType);
    const [selectedDate, setSelectedDate] = useState(props.grant.expireDate ? new Date(props.grant.expireDate) : null);
    const i18n = useI18n();

    const availableGrantsLevels = useMemo(() => {
        try {
            return getAvailableGrantsLevels(props.productType || props.grant.productType);
        } catch (e) {
            return Object.values(ACCESS_TYPES);
        }
    }, [props.grant.productType, props.productType]);

    useEffect(() => {
        if (!availableGrantsLevels.includes(accessType)) {
            setAccessType('');
        }
    }, [accessType, availableGrantsLevels]);

    const handleDateChange = useCallback((date) => {
        setSelectedDate(date);
    }, []);

    const handleValidate = useCallback(() => {
        props.onValidate({
            ...props.grant,
            expireDate: selectedDate ? selectedDate.getTime() : null,
            accessType,
        });

        props.onClose();
    }, [accessType, props, selectedDate]);

    const handleAccessTypeChange = useCallback((e) => setAccessType(e.target.value), []);

    const handleIsTemporaryChange = useCallback((e) => {
        return e.target.checked ? setSelectedDate(new Date()) : setSelectedDate(null);
    }, []);

    const cancelLabel = useMemo(() => <Button color="secondary">{i18n.tc('ssoAdmin.actions.cancel')}</Button>, [i18n]);

    return (
        <Dialog onClose={props.onClose} open fullWidth>
            <DialogTitle>
                <StyledTitle>
                    <EditIcon />
                    {i18n.tc('ssoAdmin.grant.dialog.edit.title')}
                    <StyledCloseButton aria-label="Close" onClick={props.onClose} size="large">
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledTitle>
            </DialogTitle>
            <DialogContent>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField disabled value={props.grantValue} label={props.entityLabel} fullWidth />
                    </StyledSpacedWrapper>
                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('ssoAdmin.grant.fields.accessType')}</InputLabel>
                            <Select
                                value={accessType}
                                onChange={handleAccessTypeChange}
                                label={i18n.tc('ssoAdmin.grant.fields.accessType')}
                            >
                                {availableGrantsLevels.map((access) => (
                                    <MenuItem key={access} value={access}>
                                        {access}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>
                    <StyledSpacedWrapper>
                        <StyledSwitchWrapper>
                            <Typography>{i18n.tc('ssoAdmin.grant.fields.temporary')}</Typography>
                            <Switch checked={selectedDate !== null} onChange={handleIsTemporaryChange} />
                        </StyledSwitchWrapper>
                    </StyledSpacedWrapper>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            fullWidth
                            disabled={selectedDate === null}
                            label={i18n.tc('ssoAdmin.grant.fields.expirationDate')}
                            value={selectedDate || new Date()}
                            onChange={handleDateChange}
                            cancelLabel={cancelLabel}
                            okLabel={okLabel}
                            disablePast
                            labelFunc={labelFunc}
                            renderInput={renderDateTimePickerInput}
                        />
                    </LocalizationProvider>
                </FormField>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" onClick={handleValidate} color="primary">
                    {i18n.tc('ssoAdmin.actions.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

GrantEdition.propTypes = {
    /** The grant being edited */
    grant: PropTypes.shape({
        accessType: PropTypes.string.isRequired,
        expireDate: PropTypes.number,
    }).isRequired,
    /** The object of the grant, if it concerns a user his email, if it concerns a product his name */
    grantValue: PropTypes.string.isRequired,
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    /** Callback when the validate button is clicked. The edited grant is passed as parameter. */
    onValidate: PropTypes.func.isRequired,
    /** The label of the entity (such as Email or Product Name) */
    entityLabel: PropTypes.string.isRequired,
    productType: PropTypes.string,
};

GrantEdition.defaultProps = {
    productType: null,
};

export default GrantEdition;
