import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormControl, FormControlLabel, TextField, Checkbox } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import DeleteButton from '../../../Buttons/Delete';
import Deletion from '../../../EntityManager/Deletion';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit group management
 */
function UserGroupDeletion({ userGroup, onDelete, onCancel }) {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        ssoWs.deleteUserGroup(userGroup.id, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.userGroups.notistack.delete.success'), { variant: 'success' });
                onDelete();
            },
            onError: (brainWsResponse, xhr) => enqueueSnackbar(xhr.response, { variant: 'error' }),
        });
    }, [enqueueSnackbar, i18n, onDelete, userGroup.id]);

    const checkboxIcon = useMemo(() => <Checkbox disabled checked={userGroup.posixGroup} />, [userGroup.posixGroup]);

    return (
        <Deletion label={i18n.tc('ssoAdmin.userGroups.deletion.title')} onCancel={onCancel} handleDelete={handleDelete}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.userGroups.fields.id')}
                            disabled
                            value={userGroup.id}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.userGroups.fields.cn')}
                            disabled
                            value={userGroup.cn}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.userGroups.fields.gidNumber')}
                            disabled
                            value={userGroup.gidNumber || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <FormControl fullWidth>
                        <FormControlLabel
                            control={checkboxIcon}
                            label={i18n.tc('ssoAdmin.userGroups.fields.posixGroup')}
                        />
                    </FormControl>
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

UserGroupDeletion.propTypes = {
    userGroup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cn: PropTypes.string.isRequired,
        posixGroup: PropTypes.bool.isRequired,
        gidNumber: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserGroupDeletion;
