import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { getApp, updateApp } from '../../../../../wsClient/AppsManagerWsClient';

function EulaEditor({ app }) {
    const [isOpen, setIsOpen] = useState(false);
    const [eula, setEula] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        getApp(app.id)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject();
            })
            .then((appWithExtendedInfos) => {
                setEula(appWithExtendedInfos.eula);
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.eula.notistack.get.error'), { variant: 'error' });
            });
    }, [app, enqueueSnackbar, i18n]);

    const handleSave = useCallback(() => {
        updateApp(app.id, {
            ...app,
            eula,
        })
            .then(() => {
                setIsOpen(false);
                enqueueSnackbar(i18n.tc('ssoAdmin.eula.notistack.update.success'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.eula.notistack.update.error'), { variant: 'error' });
            });
    }, [app, enqueueSnackbar, eula, i18n]);

    const handleEulaChange = useCallback((e) => setEula(e.target.value), []);

    const handleClose = useCallback(() => setIsOpen(false), []);

    const handleOpen = useCallback(() => setIsOpen(true), []);

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>{i18n.tc('ssoAdmin.eula.dialog.update.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        placeholder={i18n.tc('ssoAdmin.eula.fields.text')}
                        fullWidth
                        multiline
                        value={eula}
                        onChange={handleEulaChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        {i18n.tc('ssoAdmin.actions.cancel')}
                    </Button>
                    <Button variant="contained" onClick={handleSave} color="primary">
                        {i18n.tc('ssoAdmin.actions.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button color="secondary" fullWidth onClick={handleOpen}>
                {i18n.tc('ssoAdmin.eula.dialog.update.title')}
            </Button>
        </>
    );
}

EulaEditor.propTypes = {
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default EulaEditor;
