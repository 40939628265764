import Check from '@braincube/brain-js/lib/check';
import UrlBuilder from '@braincube/url-builder';

import BrainWs from './BrainWs';
import BrainWsEnums from './BrainWsEnums';

/**
 * This client must be used for hitting the SSO web services.
 */
export default class SsoWsClient extends BrainWs {
    constructor() {
        super('');

        this.urlBuilder = null;
    }

    /**
     * @return {UrlBuilder} URL builder configured with the good protocol, endpoint, port...
     * @private
     */
    _initUrlBuilder() {
        const builder = new UrlBuilder(window.BC_API_ENDPOINTS_CONF.sso).protocol(UrlBuilder.PROTOCOLS.HTTPS);

        this.urlBuilder = builder.addPathParam('sso-server');
        return this.urlBuilder;
    }

    /**
     * @return {UrlBuilder} URL builder ready for hitting the API.
     * @private
     */
    _getApiUrlBuilder() {
        this.urlBuilder = this._initUrlBuilder().addPathParam('api').addPathParam('v2');
        return this.urlBuilder;
    }

    /** ************************************ */
    /** ************* PROVIDERS ************ */
    /** ************************************ */

    /**
     * Returns all the providers.
     * @param {object} events Event callbacks.
     */
    getAllProviders(events) {
        this._getApiUrlBuilder().addPathParam('provider');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Create a new provider.
     * @param {object} provider Provider to create.
     * @param {object} events Event callbacks.
     */
    createProvider(provider, events) {
        this._getApiUrlBuilder().addPathParam('provider').addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(provider), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Update an existing provider.
     * @param provider Updated provider.
     * @param {String} providerName Name of the provider.
     * @param {object} events Event callbacks.
     */
    updateProvider(provider, providerName, events) {
        delete provider.rm;
        this._getApiUrlBuilder().addPathParam('provider').addPathParam(providerName);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(provider), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Delete a provider.
     * @param {String} providerName Name of the provider to delete.
     * @param {object} events Event callbacks.
     */
    deleteProvider(providerName, events) {
        this._getApiUrlBuilder().addPathParam('provider').addPathParam(providerName);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /** *********************************** */
    /** ********** PRODUCTS / SITES ******* */
    /** *********************************** */

    /**
     * Returns all the products.
     * @param {object} events Event callbacks.
     */
    getAllProducts(events) {
        this._getApiUrlBuilder().addPathParam('product');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Returns the products of a given provider.
     * @param providerName Name of the provider.
     * @param {object} events Event callbacks.
     */
    getProducts(providerName, events) {
        this._getApiUrlBuilder().addPathParam('provider').addPathParam(providerName).addPathParam('product');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    getSiteProducts(siteId, events) {
        this._getApiUrlBuilder().addPathParam('site').addPathParam(siteId).addPathParam('product');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Returns the Id SAP for a product.
     * @param providerName Name of the provider.
     * @param groupName Name of the client.
     * @param siteName Name of the site.
     * @param {object} events Event callbacks.
     */
    getIdSAP(providerName, groupName, siteName, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('idsap');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    setIdSAP(providerName, groupName, siteName, idSap, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('idsap');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, idSap, [BrainWs.CONTENT_TYPE_JSON]);
    }

    getDnsName(providerName, groupName, siteName, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('dnsName');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    setDnsName(providerName, groupName, siteName, dnsName, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('dnsName');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, dnsName, [BrainWs.CONTENT_TYPE_JSON]);
    }

    getQuota(providerName, groupName, siteName, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('apiQuota');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    setQuota(providerName, groupName, siteName, quota, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('apiQuota');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, quota, [BrainWs.CONTENT_TYPE_JSON]);
    }

    getExtraQuota(providerName, groupName, siteName, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('extraApiQuota');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    setExtraQuota(providerName, groupName, siteName, extraQuota, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('extraApiQuota');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, extraQuota, [BrainWs.CONTENT_TYPE_JSON]);
    }

    createSite(site, events) {
        this._getApiUrlBuilder().addPathParam('site').addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(site), [BrainWs.CONTENT_TYPE_JSON]);
    }

    getSitesByProvider(providerName, events) {
        this._getApiUrlBuilder().addPathParam('provider').addPathParam(providerName).addPathParam('site');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    getSites(events) {
        this._getApiUrlBuilder().addPathParam('site');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    getSiteByUuid(uuid, events) {
        this._getApiUrlBuilder().addPathParam('site').addPathParam(uuid);

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    deleteSite(siteId, events) {
        this._getApiUrlBuilder().addPathParam('site').addPathParam(siteId);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /**
     * Create a new product.
     * @param {String} providerName Name of the provider for which to add the product.
     * @param {object} product Updated product.
     * @param {object} events Event callbacks.
     */
    createProduct(providerName, product, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(product), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Update an existing product.
     * @param {String} providerName Name of the provider the product belongs to.
     * @param {object} product Updated product.
     * @param {String} productId ID of the product to modify.
     * @param {object} events Event callbacks.
     */
    updateProduct(providerName, product, productId, events) {
        delete product.rm;
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(product), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Update product data key
     * @param {string} providerName
     * @param {string} groupName
     * @param {string} siteName
     * @param {string} productUuid
     * @param {string} key
     * @param {string} value
     * @param {object} events
     */
    updateProductDataKey(providerName, groupName, siteName, productUuid, key, value, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('group')
            .addPathParam(groupName)
            .addPathParam('site')
            .addPathParam(siteName)
            .addPathParam('product')
            .addPathParam(productUuid)
            .addPathParam(key);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, value);
    }

    /**
     * Delete a product.
     * @param {String} providerName Name of the provider the product belongs to.
     * @param {String} productId Product ID of the product to delete.
     * @param {object} events Event callbacks.
     */
    deleteProduct(providerName, productId, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    getOldOfferData(provider, group, site, productUuid, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productUuid)
            .addPathParam('offer');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    clearOldOfferData(provider, group, site, productUuid, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productUuid)
            .addPathParam('offer');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, '', [BrainWs.CONTENT_TYPE_JSON]);
    }

    getOfferData(provider, group, site, productUuid, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productUuid)
            .addPathParam('offers');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    updateOfferData(provider, group, site, productUuid, offerUuid, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productUuid)
            .addPathParam('offers');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, offerUuid, [BrainWs.CONTENT_TYPE_JSON]);
    }

    getSiteOfferData(provider, group, site, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('offers');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    updateSiteOfferData(provider, group, site, offerUuid, events) {
        this._getApiUrlBuilder()
            .addPathParam('data')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('offers');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, offerUuid, [BrainWs.CONTENT_TYPE_JSON]);
    }

    /** ******************************** */
    /** ************* GROUPS ************ */
    /** ******************************** */

    /**
     * Returns all the groups.
     * @param {object} events Event callbacks.
     */
    getAllUserGroups(events) {
        this._getApiUrlBuilder().addPathParam('group');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Creates a new group.
     * @param {object} group Group to create.
     * @param {object} events Event callbacks.
     */
    createUserGroup(group, events) {
        this._getApiUrlBuilder().addPathParam('group').addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(group), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Delete a group.
     * @param {String} id Id of the group to delete.
     * @param {object} events Event callbacks.
     */
    deleteUserGroup(id, events) {
        this._getApiUrlBuilder().addPathParam('group').addPathParam(id);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /** ******************************** */
    /** *********** DELEGATES ********** */
    /** ******************************** */

    getDelegates(events) {
        this._getApiUrlBuilder().addPathParam('auth').addPathParam('delegate');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    createDelegate(name, protocol, configuration, events) {
        this._getApiUrlBuilder().addPathParam('auth').addPathParam('delegate').addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify({ name, protocol, configuration }), [
            BrainWs.CONTENT_TYPE_JSON,
        ]);
    }

    editDelegate(delegate, events) {
        delete delegate.rm;
        this._getApiUrlBuilder().addPathParam('auth').addPathParam('delegate').addPathParam(delegate.name);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(delegate), [BrainWs.CONTENT_TYPE_JSON]);
    }

    deleteDelegate(name, events) {
        this._getApiUrlBuilder().addPathParam('auth').addPathParam('delegate').addPathParam(name);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    deployDelegate(name, events) {
        this._getApiUrlBuilder().addPathParam('auth').addPathParam('delegate').addPathParam(name).addPathParam('bump');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT);
    }

    /** ******************************** */
    /** ************* USERS ************ */
    /** ******************************** */

    /**
     * Returns all the users.
     * @param {object} events Event callbacks.
     */
    getAllUsers(events) {
        this._getApiUrlBuilder().addPathParam('user');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Creates a new user.
     * @param {object} user User to create.
     * @param {object} events Event callbacks.
     */
    createUser(user, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(user), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Update an existing user.
     * @param {object} user Updated user.
     * @param {String} userEmail Email of the user to update.
     * @param {object} events Event callbacks.
     */
    updateUser(user, userEmail, events) {
        delete user.rm;
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userEmail);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(user), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Delete a user.
     * @param {String} userEmail Email of the user to delete.
     * @param {object} events Event callbacks.
     */
    deleteUser(userEmail, newOwner, events) {
        const urlBuilder = this._getApiUrlBuilder().addPathParam('user').addPathParam(userEmail);

        if (newOwner) {
            urlBuilder.addQueryParam('replace_owner', newOwner);
        }

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /**
     * Generate a certificate for a user. If a certificate already exists for this user, it will be overwritten.
     * @param {String} userEmail Email of the user for who to generate a certificate.
     * @param {String} password Password to use for encrypting the certificate container.
     * @param {object} events Event callbacks.
     */
    generateCertificate(userEmail, password, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(userEmail)
            .addPathParam('cert')
            .addPathParam('generate');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, password, [BrainWs.CONTENT_TYPE_PLAINTEXT]);
    }

    revokeCertificate(userEmail, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(userEmail)
            .addPathParam('cert')
            .addPathParam('revoke');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST);
    }

    /** ********************************* */
    /** ************ MEMBERS ************ */
    /** ********************************* */

    /**
     * Delete a member from group
     *
     * @param {string} memberId to add.
     * @param {string} groupId.
     *
     * @param events
     */
    deleteGroupMember(memberId, groupId, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(memberId)
            .addPathParam('group')
            .addPathParam(groupId);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /**
     * Returns all the members of a group.
     * @param {String} groupId Group ID.
     * @param {object} events Event callbacks.
     */
    getGroupMembers(groupId, events) {
        this._getApiUrlBuilder().addPathParam('group').addPathParam(groupId).addPathParam('members');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Grants a user on a product.
     * @param {string} memberId to add.
     * @param {object} group.
     * @param {object} events Event callbacks.
     */
    addMemberForGroup(memberId, group, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(memberId).addPathParam('group');

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(group), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Get all groups for a user
     * @param {string} memberId
     * @param {object} events Event callbacks.
     */
    getUserGroups(memberId, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(memberId).addPathParam('group');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.CONTENT_TYPE_JSON]);
    }

    /** ********************************* */
    /** ************* GRANTS ************ */
    /** ********************************* */

    /**
     * Returns all the grants of a user.
     * @param {String} userEmail Email of the user for who to list grants.
     * @param {object} events Event callbacks.
     */
    getUserGrants(userEmail, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userEmail).addPathParam('grant');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    getUserTempGrants(userId, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userId).addPathParam('tempGrant');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Returns all the grants of a product.
     * @param {String} providerName Name of the provider.
     * @param {String} productId Product ID.
     * @param {object} events Event callbacks.
     */
    getProductGrants(providerName, productId, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId)
            .addPathParam('grant');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    getProductTempGrants(providerName, productId, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId)
            .addPathParam('tempGrant');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET, null, [BrainWs.ACCEPT_JSON]);
    }

    /**
     * Grants a user on a product.
     * @param {object} grant Grant object to add.
     * @param {String} providerName Name of the provider.
     * @param {String} productId Product ID on which to grant the user.
     * @param {object} events Event callbacks.
     */
    addGrantForProduct(grant, providerName, productId, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId)
            .addPathParam('grant')
            .addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(grant), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Grants a user.
     * @param {object} grant Grant object to add.
     * @param {String} userEmail Email of the user to grant.
     * @param {object} events Event callbacks.
     */
    addGrantForUser(grant, userEmail, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(userEmail)
            .addPathParam('grant')
            .addPathParam('create');

        return this._ajax(events, BrainWsEnums.HttpMethods.POST, JSON.stringify(grant), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Updates a grant.
     * @param {object} grant Grant to update.
     * @param {String} userEmail Email of the user whose grant is updated.
     * @param {String} productId Product ID of the grant.
     * @param {object} events Event callbacks.
     */
    updateGrant(grant, userEmail, productId, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(userEmail)
            .addPathParam('grant')
            .addPathParam(productId);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(grant), [BrainWs.CONTENT_TYPE_JSON]);
    }

    /**
     * Deletes a grant for a product.
     * @param {String} userEmail Email of the user for who to delete the grant.
     * @param {String} productId Product ID of the grant to delete.
     * @param {String} replaceOwner User UUID for replace owner
     * @param {object} events Event callbacks.
     */
    deleteProductGrant(userEmail, productId, replaceOwner, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam(userEmail)
            .addPathParam('grant')
            .addPathParam(productId);

        if (replaceOwner) {
            this.urlBuilder.addQueryParam('replace_owner', replaceOwner);
        }
        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /**
     * Deletes a grant for a user.
     * @param {String} providerName Name of the provider.
     * @param {String} productId Product ID of the grant to delete.
     * @param {String} userEmail Email of the user for who to delete the grant.
     * @param {object} events Event callbacks.
     */
    deleteUserGrant(providerName, productId, userEmail, events) {
        this._getApiUrlBuilder()
            .addPathParam('provider')
            .addPathParam(providerName)
            .addPathParam('product')
            .addPathParam(productId)
            .addPathParam('grant')
            .addPathParam(userEmail);

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    /** ********************************* */
    /** ************* OTHERS ************ */
    /** ********************************* */

    /**
     * Ask the server to engage the procedure for changing a user's email.
     * @param {String} currentUserEmail Current user email.
     * @param {String} providerName Name of the provider.
     * @param {object} events Event callbacks.
     */
    changeEmail(currentUserEmail, providerName, events) {
        this._getApiUrlBuilder()
            .addPathParam('user')
            .addPathParam('changeEmail')
            .addQueryParam('email', currentUserEmail);

        if (!Check.isUndefinedOrNull(providerName) && providerName !== '') {
            this.urlBuilder.addQueryParam('provider', providerName);
        }

        return this._ajax(events, BrainWsEnums.HttpMethods.POST);
    }

    /**
     * Ask the server to engage the procedure to force the change of the user's email.
     * @param {string} previous - Previous user mail.
     * @param {string} newMail - New user mail.
     * @param {string} provider - Name of the provider.
     * @param {object} events - Event callbacks.
     */
    forceChangeEmail(previous, newMail, provider, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam('forceChangeEmail');

        return this._ajax(
            events,
            BrainWsEnums.HttpMethods.POST,
            JSON.stringify({
                previous,
                new: newMail,
                provider,
            }),
            [BrainWs.CONTENT_TYPE_JSON]
        );
    }

    /**
     * Ask the server to engage the procedure for resetting a user's password.
     * @param {String} userEmail Email of the user.
     * @param {object} events Event callbacks.
     */
    resetPassword(userEmail, providerName, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userEmail).addPathParam('lostPassword');

        if (!Check.isUndefinedOrNull(providerName) && providerName !== '') {
            this.urlBuilder.addQueryParam('provider', providerName);
        }

        return this._ajax(events, BrainWsEnums.HttpMethods.POST);
    }

    /**
     * Log the user out.
     * @param {object} events Event callbacks.
     */
    logout(events) {
        this._initUrlBuilder().addPathParam('ws').addPathParam('session').addPathParam('logout');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    getAccountant(provider, group, site, productId, role, events) {
        this._initUrlBuilder()
            .addPathParam('api')
            .addPathParam('v2')
            .addPathParam('accountant')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productId)
            .addPathParam(role);

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    updateAccountants(provider, group, site, productId, accountants, events) {
        this._initUrlBuilder()
            .addPathParam('api')
            .addPathParam('v2')
            .addPathParam('accountant')
            .addPathParam('provider')
            .addPathParam(provider)
            .addPathParam('group')
            .addPathParam(group)
            .addPathParam('site')
            .addPathParam(site)
            .addPathParam('product')
            .addPathParam(productId);

        return this._ajax(events, BrainWsEnums.HttpMethods.PUT, JSON.stringify(accountants), [
            BrainWs.CONTENT_TYPE_JSON,
        ]);
    }

    getBlockedUsers(events) {
        this._initUrlBuilder().addPathParam('api').addPathParam('v2').addPathParam('block');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }

    unblockUser(email, events) {
        this._initUrlBuilder()
            .addPathParam('api')
            .addPathParam('v2')
            .addPathParam('block')
            .addPathParam('unblock')
            .addQueryParam('email', email);

        return this._ajax(events, BrainWsEnums.HttpMethods.POST);
    }

    blockUser(email, durationInMinutes, events) {
        this._initUrlBuilder()
            .addPathParam('api')
            .addPathParam('v2')
            .addPathParam('block')
            .addPathParam('block')
            .addQueryParam('email', email)
            .addQueryParam('duration', durationInMinutes);

        return this._ajax(events, BrainWsEnums.HttpMethods.POST);
    }

    disableOtp(userUuid, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userUuid).addPathParam('2FA');

        return this._ajax(events, BrainWsEnums.HttpMethods.DELETE);
    }

    getManagerUsers(userUuid, events) {
        this._getApiUrlBuilder().addPathParam('user').addPathParam(userUuid).addPathParam('managedusers');

        return this._ajax(events, BrainWsEnums.HttpMethods.GET);
    }
}
