import { Dialog, Stack, styled, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useI18n } from '@braincube/i18n';

import { GET_APP_VERSIONS_QUERY_KEY } from 'components/Routes/AppsManager/Versions/index';
import DeleteButton from 'components/Buttons/Delete';
import CancelButton from 'components/Buttons/Cancel';
import Deletion from 'components/EntityManager/Deletion';
import { deleteVersion } from 'wsClient/AppsManagerWsClient';

const StyledActions = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

function VersionDeletion({ onClose, version, selectedApplication }) {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const { mutate: deleteVersionQuery } = useMutation(deleteVersion, {
        onSuccess: () => {
            queryClient.invalidateQueries([GET_APP_VERSIONS_QUERY_KEY, selectedApplication?.uuid]);
            onClose();
            enqueueSnackbar(i18n.tc('ssoAdmin.versions.notistack.delete.success'), { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar(i18n.tc('ssoAdmin.versions.notistack.delete.error'), { variant: 'error' });
        },
    });

    const handleDelete = useCallback(() => {
        deleteVersionQuery(version.id);
    }, [deleteVersionQuery, version.id]);

    return (
        <Dialog open fullWidth>
            <Deletion onCancel={onClose} label={i18n.tc('ssoAdmin.versions.delete.title')}>
                <Stack spacing={2}>
                    <TextField
                        disabled
                        value={selectedApplication.label}
                        label={i18n.tc('ssoAdmin.versions.delete.applicationName')}
                    />
                    <TextField
                        disabled
                        value={version.versionNumber}
                        label={i18n.tc('ssoAdmin.versions.delete.applicationVersion')}
                    />
                    <StyledActions>
                        <Stack direction="row" spacing={2}>
                            <CancelButton onClick={onClose} />
                            <DeleteButton onClick={handleDelete} />
                        </Stack>
                    </StyledActions>
                </Stack>
            </Deletion>
        </Dialog>
    );
}

VersionDeletion.propTypes = {
    onClose: PropTypes.func.isRequired,
    version: PropTypes.shape({
        versionNumber: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
    selectedApplication: PropTypes.shape({
        label: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
    }).isRequired,
};

export default VersionDeletion;
