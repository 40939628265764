import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Autocomplete, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';
import { naturalSort } from '@braincube/ui-lab/lib/services/advancedTextSearch';

import { getOffers } from '../../../../wsClient/AppsManagerWsClient';
import EditButton from '../../../Buttons/Edit';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

function Offers({ site, onAdd }) {
    const i18n = useI18n();
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [offers, setOffers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const getOfferList = useMemo(() => {
        return offers
            .sort((a, b) => naturalSort(a, b, ['label']))
            .map((offer) => ({ label: offer.label, value: offer.value }));
    }, [offers]);

    const getOptionLabel = useCallback(
        (option) => {
            return offers.find((offer) => offer.value === option.value)?.label || option.label;
        },
        [offers]
    );

    const getSelectedOption = useCallback((option, value) => option.value === value.value, []);

    const getTextFieldProps = useCallback(
        (params) => ({
            ...params,
            InputProps: {
                ...params.InputProps,
                endAdornment: (
                    <>
                        {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                ),
            },
        }),
        [isLoading]
    );

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={getTextFieldProps(params)}
                label={i18n.tc('ssoAdmin.tabs.offers')}
                placeholder={i18n.tc('ssoAdmin.tabs.offers')}
            />
        ),
        [getTextFieldProps, i18n]
    );
    const onChange = useCallback((e, newValue) => setSelectedOffers(newValue), []);

    const fetchOffers = useCallback(() => {
        getOffers().then((response) => {
            if (response.ok) {
                // eslint-disable-next-line max-nested-callbacks
                response
                    .json()
                    .then((json) => setOffers(json.map((offer) => ({ label: offer.name, value: offer.id }))));
            }
        });
    }, []);

    useEffect(fetchOffers, [fetchOffers]);
    useEffect(() => {
        setLoading(true);
        new SsoWsClient().getSiteOfferData(site.provider, site.groupName, site.siteName, {
            onSuccess: ({ response }) => {
                if (response) {
                    const offersList = response.map((res) => {
                        return {
                            label: res,
                            value: res,
                        };
                    });
                    setSelectedOffers(offersList || []);
                } else {
                    setSelectedOffers([]);
                }
                setLoading(false);
            },
            onError: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.offers.error.fetchSiteOffers'), { variant: 'error' });
                setLoading(false);
            },
        });
    }, [enqueueSnackbar, site, i18n]);

    const handleEdit = useCallback(() => {
        // eslint-disable-next-line max-len
        const cleanedOffersList = selectedOffers.map(
            (selectedOffer) => selectedOffer.value || offers.find((offer) => offer.value === selectedOffer).value
        );
        new SsoWsClient().updateSiteOfferData(
            site.provider,
            site.groupName,
            site.siteName,
            JSON.stringify(cleanedOffersList),
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.offers.offersUpdated'), { variant: 'success' });
                    onAdd();
                },
                onError: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.offers.error.updateSiteOffers'), { variant: 'error' });
                },
            }
        );
    }, [enqueueSnackbar, i18n, offers, onAdd, selectedOffers, site]);

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    <Autocomplete
                        multiple
                        options={getOfferList}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={getSelectedOption}
                        filterSelectedOptions
                        renderInput={renderInput}
                        disableCloseOnSelect
                        value={selectedOffers}
                        onChange={onChange}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </>
    );
}

Offers.propTypes = {
    site: PropTypes.shape({
        provider: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
    }).isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default Offers;
