import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useHistory, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled, Toolbar } from '@mui/material';

import { Header, HeaderAction, HeaderActions, HeaderApplicationDescriptor, HeaderLogo } from '@braincube/header';
import { AdminSso, AppsManager } from '@braincube/svg';

import AppsManagerRoutes from '../Routes/AppsManager';
import SsoRoutes from '../Routes/Sso';
import { AppContext } from '../../app-context';
import Progress from '../Progress';
import Menu from '../Menu';

export const WRAPPER_PADDING = 15;

export const APP_STORE_ROUTES = ['/apps', '/apps-platform', '/offers', '/versions'];

const StyledSimpleHeaderRoot = styled(`div`)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));

const StyledSeparator = styled('div')({
    flex: 1,
});

function SimpleHeader({ isBraincubeUsers }) {
    const history = useHistory();

    const isAppAdmin = useMemo(
        () => ['/apps', '/apps-platform', '/offers', '/versions'].includes(history.location.pathname),
        [history.location.pathname]
    );

    const goTo = useCallback(() => {
        history.push(isAppAdmin ? 'providers' : 'apps');
    }, [history, isAppAdmin]);

    const goToIcon = useMemo(() => (isAppAdmin ? AdminSso : AppsManager), [isAppAdmin]);

    const goToLabel = useMemo(() => (isAppAdmin ? 'SSO Admin' : 'Application administration'), [isAppAdmin]);

    return (
        <StyledSimpleHeaderRoot>
            <Header>
                <HeaderLogo />
                <HeaderApplicationDescriptor
                    title={isBraincubeUsers ? 'Users' : 'SSO-Admin'}
                    version={import.meta.env.VITE_APP_VERSION}
                    logo={AdminSso}
                />
                <StyledSeparator />
                <HeaderActions>
                    <HeaderAction icon={goToIcon} label={goToLabel} onClick={goTo} />
                </HeaderActions>
            </Header>
            {isBraincubeUsers && (
                <Helmet>
                    <title>Admin</title>
                </Helmet>
            )}
        </StyledSimpleHeaderRoot>
    );
}

SimpleHeader.propTypes = {
    isBraincubeUsers: PropTypes.bool.isRequired,
};

export const width = 250;

const StyledRoot = styled(`div`)({
    display: 'flex',
});

const StyledMain = styled(`main`)({
    flexGrow: 1,
    background: 'linear-gradient(#E0E0E0, #FFF)',
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

/**
 * App component, manages resize event, the sidebar and the different routes
 */
function App({ location }) {
    const { state } = useContext(AppContext);

    if (state.meIsLicenceAdmin === null || state.meIsLicenceUsers === null) {
        return null;
    }

    const isBraincubeUsers = location.pathname.includes('braincube-users');

    return (
        <StyledRoot>
            <Progress />
            <SimpleHeader isBraincubeUsers={isBraincubeUsers} />

            <Menu location={location} />

            <StyledMain>
                <Toolbar />

                <QueryClientProvider client={queryClient}>
                    {APP_STORE_ROUTES.includes(location.pathname) ? <AppsManagerRoutes /> : <SsoRoutes />}
                </QueryClientProvider>
            </StyledMain>
        </StyledRoot>
    );
}

App.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(App);
