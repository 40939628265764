import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';

import { useI18n } from '@braincube/i18n';

function CancelButton({ onClick, disabled }) {
    const i18n = useI18n();

    return (
        <Button color="secondary" onClick={onClick} disabled={disabled}>
            {i18n.tc('ssoAdmin.actions.cancel')}
        </Button>
    );
}

CancelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CancelButton.defaultProps = {
    disabled: false,
};

export default CancelButton;
