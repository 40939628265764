import React from 'react';
import PropTypes from 'prop-types';
import { styled, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

import { StyledDialogTitle, StyledTitle } from '../../StyledComponents';

const StyledForm = styled(`div`)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& > div': {
        marginTop: theme.spacing(1),
    },
    padding: theme.spacing(2),
}));

const StyledCancelIcon = styled(CancelIcon)({
    cursor: 'pointer',
});

const StyledFormContainer = styled(`div`)({
    flex: 1,
});

function preventSubmit(e) {
    return e.preventDefault();
}

export const StyledSeparator = styled('div')(({ theme }) => ({
    borderBottom: '1px solid #cccccc',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
}));

/**
 * Edit form
 */
function Deletion({ label, onCancel, children, extra }) {
    return (
        <>
            <StyledDialogTitle>
                <StyledTitle>
                    <DeleteIcon />
                    <Typography variant="h6">{label}</Typography>
                </StyledTitle>
                <StyledCancelIcon onClick={onCancel} />
            </StyledDialogTitle>

            <StyledFormContainer>
                <StyledForm onSubmit={preventSubmit}>{children}</StyledForm>
                {extra && (
                    <>
                        <StyledSeparator />
                        {extra}
                    </>
                )}
            </StyledFormContainer>
        </>
    );
}

Deletion.propTypes = {
    label: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    extra: PropTypes.element,
};

Deletion.defaultProps = {
    extra: null,
};

export default Deletion;
