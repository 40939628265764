import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';

import { DrawerTitle } from '@braincube/ui-lab';

import { StyledSeparator } from 'components/EntityManager/Deletion';

/**
 * Edit form
 */
function Edition({ label, children, extra, onCancel }) {
    return (
        <>
            <DrawerTitle title={label} icon={EditIcon} close={onCancel} />
            {children}
            {extra && (
                <>
                    <StyledSeparator />
                    {extra}
                </>
            )}
        </>
    );
}

Edition.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    extra: PropTypes.element,
    onCancel: PropTypes.func.isRequired,
};

Edition.defaultProps = {
    extra: null,
};

export default Edition;
