import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    styled,
    Autocomplete,
    ListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { AutocompleteInputRender, SmallCheckBox } from '@braincube/ui-lab';

import ListboxComponent from '../ListboxComponent';

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
}));

const StyledDialogContent = styled(DialogContent)({
    overflow: 'visible',
});

function getOptionLabel(option) {
    return option.label;
}

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

/**
 * A Popin to add a member
 */
function MemberAddition({ onClose, onValidate, availableEntitiesForAdding, entityKeyName, entityLabel }) {
    const [selectedEntities, setSelectedEntities] = useState([]);

    const handleValidate = useCallback(() => {
        selectedEntities.forEach((entity) => {
            onValidate({
                [entityKeyName]: entity,
            });
        });

        onClose();
    }, [entityKeyName, onClose, onValidate, selectedEntities]);

    const handleChange = useCallback((event, newValue) => {
        setSelectedEntities(newValue.map((option) => option.value));
    }, []);

    const renderInput = useCallback(
        (params) => <AutocompleteInputRender TextFieldProps={params} label={entityLabel} placeholder="Search" />,
        [entityLabel]
    );

    return (
        <Dialog onClose={onClose} open fullWidth>
            <DialogTitle>
                <StyledTitle>
                    <AddIcon />
                    {`Add ${entityLabel}`}
                    <StyledCloseButton aria-label="Close" onClick={onClose} size="large">
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledTitle>
            </DialogTitle>
            <StyledDialogContent>
                <Autocomplete
                    selectOnFocus
                    clearOnBlur
                    multiple
                    handleHomeEndKeys
                    renderInput={renderInput}
                    ListboxComponent={ListboxComponent}
                    renderOption={renderOption}
                    options={Object.keys(availableEntitiesForAdding).map((key) => ({
                        label: availableEntitiesForAdding[key],
                        value: key,
                    }))}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={selectedEntities.map((selected) => ({
                        value: selected,
                        label: availableEntitiesForAdding[selected],
                    }))}
                    onChange={handleChange}
                />
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleValidate} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MemberAddition.propTypes = {
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    /** Callback when the validate button is clicked. The new grant is passed as parameter. */
    onValidate: PropTypes.func.isRequired,
    /** The full list of available entities (products or users) */
    availableEntitiesForAdding: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** The label of the entity (such as Email or Product Name) */
    entityLabel: PropTypes.string.isRequired,
    entityKeyName: PropTypes.string.isRequired,
};

export default MemberAddition;
