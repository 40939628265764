import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, MobileStepper, styled, useTheme } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useI18n } from '@braincube/i18n';

const StyledRoot = styled(`div`)({
    flexGrow: 1,
});

const StyledImg = styled(`img`)({
    height: 255,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
});

function MediaStepper({ medias }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const i18n = useI18n();

    const maxSteps = medias.length;

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    const nextButton = useCallback(
        () => (
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                {i18n.tc('ssoAdmin.actions.next')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </Button>
        ),
        [activeStep, handleNext, i18n, maxSteps, theme.direction]
    );

    const backButton = useCallback(
        () => (
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                {i18n.tc('ssoAdmin.actions.back')}
            </Button>
        ),
        [activeStep, handleBack, i18n, theme.direction]
    );

    return (
        <StyledRoot>
            {medias[activeStep].endsWith('.mp4') ? (
                <video controls width="100%">
                    <source src={medias[activeStep]} />
                </video>
            ) : (
                <StyledImg src={medias[activeStep]} alt={medias[activeStep]} />
            )}
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={nextButton}
                backButton={backButton}
            />
        </StyledRoot>
    );
}

MediaStepper.propTypes = {
    medias: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MediaStepper;
