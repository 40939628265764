/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    Tabs,
    TextField,
    Checkbox,
    FormControlLabel,
    FormControl,
    createFilterOptions,
    styled,
    Autocomplete,
    ListItem,
    Grid,
} from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { useMe } from '@braincube/header';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { helperTextProps, StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import IconSelector from '../../../IconSelector';
import { AppContext, setIsFetching } from '../../../../app-context';
import SsoAceEditorWithSuspense from '../../../SsoAceEditor/WithSuspense';
import { updateApp } from '../../../../wsClient/AppsManagerWsClient';
import DeployEverywhereLink from './DeployEverywhereLink';
import Edition from '../../../EntityManager/Edition';
import EditButton from '../../../Buttons/Edit';
import EulaEditor from './EulaEditor';
import MediaStepper from './MediaStepper';
import Share from './Share';
import SketchPickerWrapper from './SketchPickerWrapper';
import CancelButton from '../../../Buttons/Cancel';
import APP_PRODUCT_TARGET from '../appTarget';
import ListboxComponent from '../../../ListboxComponent';
import Visibility from './Visibility';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const StyledAceEditorContainer = styled(`div`)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const filter = createFilterOptions();

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function getTagsOptionLabel(option) {
    return option.label || option;
}

function getCategoryOptionLabel(option) {
    return option.label || option;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function filterOptions(options, params) {
    const filtered = filter(options, params);

    if (params.inputValue) {
        filtered.push({
            label: params.inputValue,
            value: params.inputValue,
        });
    }

    return filtered;
}

function isJsonString(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }

    return true;
}

/**
 * Edit app management
 */
function AppEdition({ app, onCancel, onUpdate, productUuid, tags, categories, productsList }) {
    const { dispatch } = useContext(AppContext);
    const { user } = useMe();
    const [name, setName] = useState(app.name);
    const [description, setDescription] = useState(app.description);
    const [fulldescription, setFullDescription] = useState(app.fulldescription || '');
    const [url, setUrl] = useState(app.latestVersion.appUrl || '');
    const [appPackage, setPackage] = useState(app.package);
    const [tab, setTab] = useState(0);
    const [tempTags, setTempTags] = useState(app.tags || []);
    const [tempCategory, setTempCategory] = useState(app.category || null);
    const [medias, setMedias] = useState(app.mediaUrl || []);
    const [color, setColor] = useState(app.color);
    const [icon, setIcon] = useState(app.icon);
    const [defaultApp, setDefaultApp] = useState(app.defaultApp || false);
    const [productsTarget, setProductsTarget] = useState(
        app.productsTarget.map((target) => ({ label: target, value: target }))
    );
    const [isPublishedApp, setIsPublishedApp] = useState(false);
    const [selectedProductsOnApp, setSelectedProductsOnApp] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setName(app.name);
        setDescription(app.description);
        setFullDescription(app.fulldescription || '');
        setUrl(app.latestVersion.appUrl || '');
        setPackage(app.package);
        setTempTags(app.tags || []);
        setTempCategory(app.category || null);
        setMedias(app.mediaUrl || []);
        setColor(app.color);
        setIcon(app.icon);
        setDefaultApp(app.defaultApp);
        setProductsTarget(app.productsTarget.map((target) => ({ label: target, value: target })));
        setSelectedProductsOnApp(app.productId);
        setIsPublishedApp(app.published);
    }, [app]);

    const outOfEnvironmentProducts = useMemo(
        () =>
            app.productId
                .filter((productId) => !productsList.some((product) => product.uuid === productId))
                .map((product) => ({
                    title: product,
                    uuid: product,
                })),
        [app.productId, productsList]
    );

    const completeProductsList = useMemo(
        () => [...productsList, ...outOfEnvironmentProducts],
        [outOfEnvironmentProducts, productsList]
    );

    const handleEdit = useCallback(() => {
        dispatch(setIsFetching(true));

        app.latestVersion.appUrl = url;

        updateApp(app.id, {
            ...app,
            name,
            description,
            fulldescription,
            package: appPackage,
            tags: tempTags.filter((tag) => tag).map((tag) => tag.value || tag),
            category: tempCategory,
            mediaUrl: medias,
            color,
            icon,
            defaultApp,
            productsTarget: productsTarget.map((target) => target.value),
            productId: selectedProductsOnApp,
            published: isPublishedApp,
        }).then((response) => {
            dispatch(setIsFetching(false));

            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.update.success'), { variant: 'success' });
                onUpdate();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.update.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [
        app,
        appPackage,
        color,
        defaultApp,
        description,
        dispatch,
        enqueueSnackbar,
        fulldescription,
        i18n,
        icon,
        isPublishedApp,
        medias,
        name,
        onCancel,
        onUpdate,
        productsTarget,
        selectedProductsOnApp,
        tempCategory,
        tempTags,
        url,
    ]);

    const handleChangeTags = useCallback((event, newValue) => {
        setTempTags(newValue);
    }, []);

    const getTempTags = useMemo(() => {
        return tempTags.filter((tag) => tag);
    }, [tempTags]);

    const getTagsOptions = useMemo(() => {
        return tags
            .filter((tag) => tag)
            .map((tag) => ({
                label: tag,
                value: tag,
            }));
    }, [tags]);

    const handleChangeCategory = useCallback((event, newValue) => {
        setTempCategory(newValue ? newValue.value : null);
    }, []);

    const handleVisibilityChange = useCallback((isPublished, selectedProducts) => {
        setIsPublishedApp(isPublished);
        setSelectedProductsOnApp(selectedProducts.map((selectedProduct) => selectedProduct.value));
    }, []);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleDescriptionChange = useCallback((e) => setDescription(e.target.value), []);

    const handleFullDescriptionChange = useCallback((e) => setFullDescription(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handlePackageNameChange = useCallback((e) => setPackage(e.target.value), []);

    const handleDefaultAppChange = useCallback((e) => setDefaultApp(e.target.checked), []);

    const checkboxControl = useMemo(
        () => <Checkbox checked={defaultApp} onChange={handleDefaultAppChange} />,
        [defaultApp, handleDefaultAppChange]
    );

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.type')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleProductsTargetChange = useCallback((event, newValue) => {
        setProductsTarget(newValue);
    }, []);

    const renderTagsInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.tags')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const renderCategoryInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.category')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleMediasChange = useCallback((newMedias) => {
        if (isJsonString(newMedias)) {
            setMedias(JSON.parse(newMedias));
        }
    }, []);

    return (
        <Edition label={i18n.tc('ssoAdmin.apps.edition.title')} onCancel={onCancel}>
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.medias')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.colorAndLogo')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.visibility')} />
                {productUuid !== null && <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.sharing')} />}
                {user.rootAdmin && <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.commands')} />}
            </Tabs>

            {tab === 0 && (
                <DrawerContent>
                    <ContentArea>
                        <StyledSpacedWrapper>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.apps.fields.name')}
                                onChange={handleNameChange}
                                value={name}
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.apps.fields.description')}
                                value={description}
                                onChange={handleDescriptionChange}
                                multiline
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.apps.fields.fullDescription')}
                                value={fulldescription}
                                onChange={handleFullDescriptionChange}
                                multiline
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <TextField
                                label={i18n.tc('ssoAdmin.apps.fields.url.label')}
                                value={url}
                                onChange={handleUrlChange}
                                fullWidth
                                helperText={i18n.tc('ssoAdmin.apps.fields.url.helperText')}
                                FormHelperTextProps={helperTextProps}
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.apps.fields.packageName')}
                                onChange={handlePackageNameChange}
                                value={appPackage}
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={checkboxControl}
                                    label={i18n.tc('ssoAdmin.apps.fields.defaultApp')}
                                />
                            </FormControl>
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                multiple
                                handleHomeEndKeys
                                renderInput={renderInput}
                                ListboxComponent={ListboxComponent}
                                renderOption={renderOption}
                                options={APP_PRODUCT_TARGET}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={productsTarget}
                                onChange={handleProductsTargetChange}
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                multiple
                                freeSolo
                                forcePopupIcon
                                filterOptions={filterOptions}
                                handleHomeEndKeys
                                renderInput={renderTagsInput}
                                ListboxComponent={ListboxComponent}
                                renderOption={renderOption}
                                options={getTagsOptions}
                                getOptionLabel={getTagsOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={getTempTags}
                                onChange={handleChangeTags}
                            />
                        </StyledSpacedWrapper>

                        <StyledSpacedWrapper>
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                freeSolo
                                forcePopupIcon
                                filterOptions={filterOptions}
                                handleHomeEndKeys
                                renderInput={renderCategoryInput}
                                ListboxComponent={ListboxComponent}
                                renderOption={renderOption}
                                options={categories.map((category) => ({
                                    label: category,
                                    value: category,
                                }))}
                                getOptionLabel={getCategoryOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={tempCategory}
                                onChange={handleChangeCategory}
                            />
                        </StyledSpacedWrapper>

                        <EulaEditor app={app} />
                    </ContentArea>
                </DrawerContent>
            )}

            {tab === 1 && (
                <DrawerContent>
                    <ContentArea>
                        <StyledAceEditorContainer>
                            <SsoAceEditorWithSuspense
                                onChange={handleMediasChange}
                                data={JSON.stringify(medias)}
                                height="200px"
                            />
                        </StyledAceEditorContainer>
                        {medias.length > 0 && <MediaStepper medias={medias} />}
                    </ContentArea>
                </DrawerContent>
            )}

            {tab === 2 && (
                <DrawerContent>
                    <ContentArea>
                        <StyledSpacedWrapper>
                            <SketchPickerWrapper color={color} setColor={setColor} />
                        </StyledSpacedWrapper>
                        <IconSelector selectedIcon={icon} color={color} onChange={setIcon} />
                    </ContentArea>
                </DrawerContent>
            )}

            {tab === 3 && (
                <DrawerContent>
                    <ContentArea>
                        <Visibility
                            isPublished={isPublishedApp}
                            selectedProducts={selectedProductsOnApp}
                            productsList={completeProductsList}
                            onChange={handleVisibilityChange}
                        />
                    </ContentArea>
                </DrawerContent>
            )}

            {tab === 4 && <Share productUuid={productUuid} appId={app.id} />}

            {tab === 5 && (
                <DrawerContent>
                    <ContentArea>
                        <DeployEverywhereLink appId={app.id} />
                    </ContentArea>
                </DrawerContent>
            )}

            {tab !== 4 && tab !== 5 && (
                <DrawerActions>
                    <Grid container justifyContent="flex-end" mt={1}>
                        <Grid item mr={1}>
                            <CancelButton onClick={onCancel} />
                        </Grid>
                        <EditButton onClick={handleEdit} />
                    </Grid>
                </DrawerActions>
            )}
        </Edition>
    );
}

AppEdition.propTypes = {
    app: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fulldescription: PropTypes.string,
        id: PropTypes.string.isRequired,
        latestVersion: PropTypes.shape({
            appUrl: PropTypes.string,
        }).isRequired,
        productsTarget: PropTypes.array.isRequired,
        package: PropTypes.string,
        defaultApp: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        category: PropTypes.string,
        mediaUrl: PropTypes.arrayOf(PropTypes.string),
        color: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    productUuid: PropTypes.string,
    productsList: PropTypes.array,
};

AppEdition.defaultProps = {
    productUuid: null,
    productsList: [],
};

export default AppEdition;
