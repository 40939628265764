import { deDE, enUS, esES, frFR, itIT, nlNL, plPL, ptBR, ruRU } from '@mui/x-data-grid-pro';
import makeStyles from '@mui/styles/makeStyles';
import { useMemo } from 'react';

import { usePreferences } from '@braincube/header';

const localeMap = {
    de: deDE,
    en: enUS,
    'en-US': enUS,
    es: esES,
    fr: frFR,
    it: itIT,
    nl: nlNL,
    pl: plPL,
    'pt-br': ptBR,
    ru: ruRU,
};

const useStyles = makeStyles((theme) => ({
    // eslint-disable-next-line mui-unused-classes/unused-classes
    root: {
        border: 'none',
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
                outline: 'none',
            },
        },
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-row': {
            '&:hover': {
                '& .MuiDataGrid-cell': {
                    backgroundColor: '#EBEBEB',
                },
            },
            '&.Mui-selected, &.MuiDataGrid-row--editing': {
                '&, &:hover': {
                    '& .MuiDataGrid-cell': {
                        backgroundColor: '#FFFBE6',
                    },
                },
            },
        },
        '& .MuiDataGrid-cell--editing': {
            paddingLeft: `${theme.spacing(1)} !important`,
            paddingRight: `${theme.spacing(1)} !important`,
            '&, & .MuiAutocomplete-root': {
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
}));

export const useDataGridPro = () => {
    const { locale } = usePreferences();
    const dataGridProClasses = useStyles();

    let dataGridProLocale;

    try {
        dataGridProLocale = localeMap[locale].components.MuiDataGrid.defaultProps.localeText;
    } catch (e) {
        dataGridProLocale = localeMap.en.components.MuiDataGrid.defaultProps.localeText;
    }

    return { dataGridProLocale, dataGridProClasses: dataGridProClasses.root };
};

export default useDataGridPro;

// For HOC usage
export function withDataGridPro(Component) {
    return function WrappedComponent(props) {
        const { dataGridProLocale, dataGridProClasses } = useDataGridPro();

        const dataGridProps = useMemo(
            () => ({ dataGridProClasses, dataGridProLocale }),
            [dataGridProClasses, dataGridProLocale]
        );

        // eslint-disable-next-line react/react-in-jsx-scope
        return <Component {...props} dataGridProps={dataGridProps} />;
    };
}
