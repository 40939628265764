import { Autocomplete, ListItem, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { AutocompleteInputRender, SmallCheckBox } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';

const StyledLabelSelector = styled(`div`)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function UsersByRole({ product, provider, label, users, onChange }) {
    const { enqueueSnackbar } = useSnackbar();

    const [value, setValue] = useState(null);

    const getAccountant = useCallback(
        (roleLabel) => {
            const { clientName, siteName, productId } = product;

            new SsoWsClient().getAccountant(provider, clientName, siteName, productId, roleLabel, {
                onSuccess: ({ response }) => {
                    if (!Object.prototype.hasOwnProperty.call(response, 'accountant')) {
                        return;
                    }

                    const findedUser = users.find((user) => user.uuid === response.accountant.uuid);

                    if (findedUser) {
                        const setterObject = {
                            label: findedUser.email,
                            value: findedUser.email,
                        };

                        setValue(setterObject);
                    }
                },
                onError: () => {
                    enqueueSnackbar('Unable to retrieve the accountant list for this product', {
                        variant: 'error',
                    });
                },
            });
        },
        [enqueueSnackbar, product, provider, users]
    );

    const handleChange = useCallback(
        (event, newValue) => {
            onChange(newValue);
            setValue(newValue);
        },
        [onChange]
    );

    const renderInput = useCallback(
        (params) => <AutocompleteInputRender TextFieldProps={params} label={label} placeholder="Search" />,
        [label]
    );

    const options = useMemo(
        () =>
            users && users.length > 0
                ? users.map((user) => ({
                      label: user.email,
                      value: user.email,
                  }))
                : [],
        [users]
    );

    useEffect(() => getAccountant(label), [getAccountant, label]);

    return (
        <StyledLabelSelector>
            <Autocomplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={renderInput}
                renderOption={renderOption}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                value={value}
                onChange={handleChange}
                disableClearable
            />
        </StyledLabelSelector>
    );
}

UsersByRole.propTypes = {
    product: PropTypes.shape({
        clientName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired,
    provider: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default UsersByRole;
