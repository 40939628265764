import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import { DrawerTitle } from '@braincube/ui-lab';

/**
 * Add form
 */
function Addition({ label, children, onCancel }) {
    return (
        <>
            <DrawerTitle icon={AddIcon} title={label} close={onCancel} />
            {children}
        </>
    );
}

Addition.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default Addition;
