import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../app-context';
import SsoWsClient from '../../wsClient/SsoWsClient';
import FormField from '../FormField';
import { StyledSpacedWrapper } from '../StyledComponents';

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const ssoWs = new SsoWsClient();

/**
 * Edit product management
 */
function SiteProductDeletion({ product, provider, onDelete, onClose }) {
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.deleteProduct(provider, product.productId, {
            onSuccess: () => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(i18n.tc('ssoAdmin.siteProducts.notistack.delete.success'), { variant: 'success' });
                onDelete();
                onClose();
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
                onClose();
            },
        });
    }, [dispatch, enqueueSnackbar, i18n, onClose, onDelete, product.productId, provider]);

    return (
        <Dialog onClose={onClose} open fullWidth>
            <DialogTitle>
                <StyledTitle>
                    <DeleteIcon />
                    {i18n.tc('ssoAdmin.siteProducts.actions.deleteProduct')}
                </StyledTitle>
            </DialogTitle>
            <DialogContent>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.siteProducts.fields.name')}
                            value={product.name}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.siteProducts.fields.groupName')}
                            value={product.clientName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.siteProducts.fields.siteName')}
                            value={product.siteName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        label={i18n.tc('ssoAdmin.siteProducts.fields.type')}
                        disabled
                        value={product.type}
                        fullWidth
                    />
                </FormField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" onClick={handleDelete} color="primary">
                    {i18n.tc('ssoAdmin.actions.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SiteProductDeletion.propTypes = {
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        clientName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    provider: PropTypes.string.isRequired,
};

export default SiteProductDeletion;
