import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import DeleteButton from '../../../Buttons/Delete';
import CancelButton from '../../../Buttons/Cancel';
import Deletion from '../../../EntityManager/Deletion';
import { deleteLicence } from '../../../../wsClient/LicenceWsClient';
import { AppContext, setIsFetching } from '../../../../app-context';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

function LicenceDeletion({ licence, onDelete, onCancel }) {
    const i18n = useI18n();
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = useCallback(() => {
        dispatch(setIsFetching(true));
        deleteLicence(licence.id)
            .then((response) => {
                dispatch(setIsFetching(false));

                if (response.ok) {
                    return Promise.resolve();
                }

                return Promise.reject(response);
            })
            .then(() => {
                enqueueSnackbar(i18n.tc('ssoAdmin.licence.removal.success'), { variant: 'success' });
                onDelete();
            })
            .catch((response) => {
                let errorMsg = i18n.tc('ssoAdmin.licence.removal.error.default');
                if (response.status === 401) errorMsg = i18n.tc('ssoAdmin.licence.removal.error.unauthorized');
                if (response.status === 403) errorMsg = i18n.tc('ssoAdmin.licence.removal.error.forbidden');
                if (response.status === 404) errorMsg = i18n.tc('ssoAdmin.licence.removal.error.invalidLicence');
                if (response.status === 409) errorMsg = i18n.tc('ssoAdmin.licence.removal.error.licenceIsUsed');
                enqueueSnackbar(errorMsg, { variant: 'error' });
            });
    }, [dispatch, enqueueSnackbar, i18n, licence.id, onDelete]);

    return (
        <Deletion label={i18n.tc('ssoAdmin.licence.removal.title')} onCancel={onCancel} handleDelete={handleDelete}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.removal.formFields.comment')}
                            value={licence.comment}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.removal.formFields.createdBy')}
                            value={licence.createdBy}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.removal.formFields.duration')}
                            value={licence.duration}
                            fullWidth
                        />
                    </StyledSpacedWrapper>
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

LicenceDeletion.propTypes = {
    licence: PropTypes.shape({
        comment: PropTypes.string.isRequired,
        count: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        createdBy: PropTypes.string.isRequired,
        distributor: PropTypes.object,
        duration: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        modifiedBy: PropTypes.string.isRequired,
        orderNumber: PropTypes.string,
        product: PropTypes.string,
        random: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        useDate: PropTypes.string,
        useState: PropTypes.bool.isRequired,
        useUser: PropTypes.string,
        version: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LicenceDeletion;
