import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Autocomplete, Grid, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import { addLicence } from '../../../../wsClient/LicenceWsClient';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import { getOffers } from '../../../../wsClient/AppsManagerWsClient';

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.label === value.label;
}

/**
 * Add licence management
 */
function LicenceAddition({ distributor, siteList, onAdd, onCancel, onError }) {
    const i18n = useI18n();
    const { enqueueSnackbar } = useSnackbar();

    const [duration, setDuration] = useState(0);
    const [comment, setComment] = useState(undefined);
    const [orderNumber, setOrderNumber] = useState(undefined);
    const [licenceNumber, setLicenceNumber] = useState(1);
    const [site, setSite] = useState(undefined);
    const [maxChildren, setMaxChildren] = useState(1);

    const [offer, setOffer] = useState(null);
    const [offersLoading, setOffersLoading] = useState(false);
    const [offersList, setOffersList] = useState([]);

    const isNumeric = (val) => /^-?\d+$/.test(val);

    const isBlank = (val) => (val ? /\S/.test(val) : false);

    const verifiers = useMemo(() => {
        return {
            duration: () => {
                if (!isNumeric(duration)) {
                    return { isValid: false, helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.duration.integer') };
                }
                if (duration < 0) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.duration.positive'),
                    };
                }
                return { isValid: true };
            },
            licenceNumber: () => {
                if (!isNumeric(licenceNumber)) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.licenceNumber.integer'),
                    };
                }
                if (licenceNumber <= 0) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.licenceNumber.positive'),
                    };
                }
                if (licenceNumber > 3000) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.licenceNumber.tooLarge'),
                    };
                }
                return {
                    isValid: true,
                    helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.licenceNumber.defaultHelper'),
                };
            },
            orderNumber: () => {
                if (!isBlank(orderNumber)) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.orderNumber.empty'),
                    };
                }
                return { isValid: true };
            },
            maxChildrenNumber: () => {
                if (!isNumeric(maxChildren)) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.maxChildren.integer'),
                    };
                }
                if (maxChildren <= 0) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.maxChildren.positive'),
                    };
                }
                return { isValid: true };
            },
            licenceSite: () => {
                if (!isBlank(site)) {
                    return {
                        isValid: false,
                        helpMsg: i18n.tc('ssoAdmin.licence.addition.verifiers.licenceSite.empty'),
                    };
                }
                return { isValid: true };
            },
        };
    }, [duration, i18n, licenceNumber, maxChildren, orderNumber, site]);

    const formIsValid = () => Object.values(verifiers).every((check) => check().isValid);

    const handleAddition = useCallback(() => {
        const siteUuid = site ? site.siteUuid : '';
        const offerUuid = offer?.value || '';
        addLicence(distributor.id, { duration, comment, orderNumber, licenceNumber, siteUuid, offerUuid, maxChildren })
            .then((response) => {
                if (response.ok) {
                    return Promise.resolve();
                }

                return Promise.reject(response);
            })
            .then(() => {
                enqueueSnackbar(
                    i18n.tc('ssoAdmin.licence.addition.success', { smart_count: parseInt(licenceNumber, 10) }),
                    {
                        variant: 'success',
                    }
                );
                onAdd();
            })
            .catch((response) => {
                let msg = i18n.tc('ssoAdmin.licence.addition.error.default', {
                    smart_count: parseInt(licenceNumber, 10),
                });
                if (response.status === 404) msg = i18n.tc('ssoAdmin.licence.addition.error.invalidDistributor');
                if (response.status === 507) msg = i18n.tc('ssoAdmin.licence.addition.error.tooManyLicences');
                enqueueSnackbar(msg, { variant: 'error' });
                onError();
            });
    }, [
        comment,
        distributor.id,
        duration,
        enqueueSnackbar,
        i18n,
        licenceNumber,
        offer?.value,
        onAdd,
        onError,
        orderNumber,
        site,
        maxChildren,
    ]);

    const handleDurationChange = useCallback((e) => setDuration(e.target.value), []);

    const handleCommentChange = useCallback((e) => setComment(e.target.value), []);

    const handleMaxChildrenChange = useCallback((e) => setMaxChildren(e.target.value), []);

    const handleOrderNumberChange = useCallback((e) => setOrderNumber(e.target.value), []);

    const handleLicenceNumberChange = useCallback((e) => setLicenceNumber(e.target.value), []);

    const renderSiteInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                TextFieldProps={{
                    ...params,
                    error: !verifiers.licenceSite().isValid,
                    helperText: verifiers.licenceSite().helpMsg,
                }}
                label={i18n.tc('ssoAdmin.licence.addition.formFields.siteName')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n, verifiers]
    );

    const renderOfferInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.licence.addition.formFields.offer')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const selectedSiteName = useMemo(
        () =>
            site
                ? {
                      label: site.siteName.concat(' - ', site.groupName),
                      value: site,
                  }
                : null,
        [site]
    );

    const handleSelectedSiteNameChange = useCallback((event, selectedItem) => {
        setSite(selectedItem ? selectedItem.value : undefined);
    }, []);

    const handleOfferChange = useCallback((event, selectedItem) => {
        setOffer(selectedItem);
    }, []);

    const populateOffers = useCallback(() => {
        setOffersLoading(true);
        getOffers()
            .then((response) => {
                if (response.ok) {
                    response.json().then((items) => {
                        setOffersList(
                            items
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(({ name, id }) => ({ label: name, value: id }))
                        );
                    });
                }
            })
            .finally(() => setOffersLoading(false));
    }, []);

    useEffect(() => {
        populateOffers();
    }, [populateOffers]);

    return (
        <Addition label={i18n.tc('ssoAdmin.licence.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            data-testid="licence-duration"
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.duration')}
                            value={duration}
                            onChange={handleDurationChange}
                            error={!verifiers.duration().isValid}
                            helperText={verifiers.duration().helpMsg}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            data-testid="licence-comment"
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.comment')}
                            value={comment}
                            onChange={handleCommentChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            data-testid="licence-order-number"
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.orderNumber')}
                            value={orderNumber}
                            onChange={handleOrderNumberChange}
                            error={!verifiers.orderNumber().isValid}
                            helperText={verifiers.orderNumber().helpMsg}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            data-testid="licence-number"
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.licenceNumber')}
                            value={licenceNumber}
                            onChange={handleLicenceNumberChange}
                            error={!verifiers.licenceNumber().isValid}
                            helperText={verifiers.licenceNumber().helpMsg}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            data-testid="licence-max-children"
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.maxChildren')}
                            value={maxChildren}
                            onChange={handleMaxChildrenChange}
                            error={!verifiers.maxChildrenNumber().isValid}
                            helperText={verifiers.maxChildrenNumber().helpMsg}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            data-testid="licence-site"
                            selectOnFocus
                            handleHomeEndKeys
                            ListboxComponent={ListboxComponent}
                            renderInput={renderSiteInput}
                            options={siteList.map((item) => ({
                                label: item.siteName.concat(' - ', item.groupName),
                                value: item,
                            }))}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={selectedSiteName}
                            onChange={handleSelectedSiteNameChange}
                        />
                    </StyledSpacedWrapper>

                    <Autocomplete
                        data-testid="licence-offers"
                        loading={offersLoading}
                        options={offersList}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions
                        renderInput={renderOfferInput}
                        value={offer}
                        onChange={handleOfferChange}
                    />
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton
                        disabled={!formIsValid()}
                        onClick={handleAddition}
                        label={i18n.tc('ssoAdmin.licence.label')}
                    />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

LicenceAddition.propTypes = {
    distributor: PropTypes.object.isRequired,
    siteList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAdd: PropTypes.func.isRequired, // Function called when the addition was successful
    onCancel: PropTypes.func.isRequired, // Function called on cancellation by the user
    onError: PropTypes.func.isRequired, // Function called after an error has occurred
};

export default LicenceAddition;
