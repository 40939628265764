import React, { useContext } from 'react';
import { LinearProgress, styled } from '@mui/material';

import { AppContext } from '../../app-context';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 2,
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: 'transparent',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#FFDD00',
    },
}));

function Progress() {
    const { state } = useContext(AppContext);

    if (state.isFetching) {
        return <StyledLinearProgress />;
    }
    return null;
}

export default Progress;
