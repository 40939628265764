import Check from '@braincube/brain-js/lib/check';
import UrlBuilder from '@braincube/url-builder';

import 'url-polyfill';

import brainRequest from './BrainRequest';
import BrainWsEnums from './BrainWsEnums';

/**
 * Main class to request the Braincube webservices.
 *
 * @class
 * @ignore
 */
export default class BrainWs {
    /**
     * Content-Type header for text/plain
     *
     * @type {{name, value}}
     * @static
     */
    static CONTENT_TYPE_PLAINTEXT = BrainWs.createHeader('Content-Type', 'text/plain');

    /**
     * Accept header for text/plain
     *
     * @type {{name, value}}
     * @static
     */
    static ACCEPT_PLAINTEXT = BrainWs.createHeader('Accept', 'text/plain');

    /**
     * Content-Type header for application/json
     *
     * @type {{name, value}}
     * @static
     */
    static CONTENT_TYPE_JSON = BrainWs.createHeader('Content-Type', 'application/json');

    /**
     *Accept header for application/json
     *
     * @type {{name, value}}
     * @static
     */
    static ACCEPT_JSON = BrainWs.createHeader('Accept', 'application/json');

    /**
     * SsoToken key
     *
     * @type {string}
     * @static
     */
    static SSO_TOKEN_KEY = 'IPLSSOTOKEN';

    /**
     * Helper method to create an object readable by the client
     * @param {number} httpCode
     * @param {*} response
     * @returns {{ httpCode: number, response: * }}
     *
     * @method buildBrainWsResponseObject
     * @memberOf BrainWs
     * @static
     */
    static buildBrainWsResponseObject = (httpCode, response) => {
        return {
            httpCode,
            response,
        };
    };

    /**
     * Helper method to create a header entry.
     * @param {string} key header key name
     * @param {string|number} value header value
     * @return {{name: *, value: *}} brain-ws-js header entry.
     *
     * @method createHeader
     * @memberOf BrainWs
     * @static
     */
    static createHeader(key, value) {
        return {
            name: key,
            value,
        };
    }

    /**
     * @param {string} apiUrl - Any API url
     * @returns {string} The url to access the SSO login page with automatic application redirection
     *
     * @method getSsoLoginPage
     * @memberOf BrainWs
     * @static
     */
    static getSsoLoginPage(apiUrl) {
        const parsedUrl = new URL(apiUrl);
        const ssoUrl = parsedUrl.host.replace('api.', '');

        return `${parsedUrl.protocol}//${ssoUrl}?redirect=${window.location.href}`;
    }

    /**
     * Create a ws Object to query Braincube webservices
     * @param {Host} host - host to be requested
     * @param {SsoToken} ssoToken - Account SSO token
     * @param {boolean} unsecureAPI Set to true to use webservices in http mode.
     *
     * @constructor
     */
    constructor(host, ssoToken = null, unsecureAPI = false) {
        // We ensure that there is no slash at the end
        this.host = host.replace(/\/$/, '');
        this.ssoToken = ssoToken;
        this.unsecureAPI = unsecureAPI;
        this.urlBuilder = null;
    }

    /**
     * @returns {string} The url to access the SSO login page with automatic application redirection from the urlBuilder
     */
    getSsoLoginPage() {
        return this.urlBuilder === null ? null : BrainWs.getSsoLoginPage(this.urlBuilder.build());
    }

    /**
     * Set up the url buidler to use HTTP mode
     * It print an error in the console to discourage HTTP usage in Braincube webservices.
     * @param urlBuilder the builder to setup in an unsecure mode.
     *
     * @private
     * @method BrainWs#_useUnsecureAPI
     */
    _useUnsecureAPI(urlBuilder) {
        if (this.unsecureAPI) {
            console.error('You are accessing to Braincube using the unsecure http protocol which is discouraged');
            urlBuilder.protocol(UrlBuilder.PROTOCOLS.HTTP);
        }
    }

    /**
     * Start a new urlBuilder configured with the base path, endpoint and protocol
     * to request the any Braincube webservices
     * @param {BrainWsEnums.Webservice} webservice the webservice to request.
     *
     * @method BrainWs#initUrlBuilder
     */
    initUrlBuilder(webservice) {
        let urlBuilder;
        // Request to a local Braincube instance
        if (this.host.indexOf('braincube.local.ipleanware.com') !== -1) {
            urlBuilder = new UrlBuilder(this.host).addPathParam('braincube-web').addPathParam('ws').addPathParam('v1');
        } else {
            const splittedHost = this.host.split('.');
            const productName = splittedHost.shift();

            urlBuilder = new UrlBuilder(splittedHost.join('.'))
                .endpoint(UrlBuilder.ENDPOINTS.API)
                .addPathParam('braincube')
                .addPathParam(productName);
        }
        this._useUnsecureAPI(urlBuilder);
        urlBuilder.addPathParam(webservice);
        this.urlBuilder = urlBuilder;
        return this.urlBuilder;
    }

    /**
     * Constructs query parameters for pagination.
     *
     * @param {number} startIdx
     * @param {number} size
     * @returns {UrlBuilder} the current urlBuilder property.
     *
     * @private
     * @method Braincube#__setPaginationQueryParams
     */
    __setPaginationQueryParams(startIdx, size) {
        if (!Check.isUndefinedOrNull(startIdx) && startIdx > 0) {
            this.urlBuilder.addQueryParam('startIdx', startIdx);
        }

        if (!Check.isUndefinedOrNull(size)) {
            this.urlBuilder.addQueryParam('size', size);
        }

        return this.urlBuilder;
    }

    /**
     * Start a request and setup response callbacks.
     * @param {BraincubeWsEvents} events
     * @param {string} httpMethod  HTTP method (GET, POST, PUT, DELETE...)
     * @param {any} data  Parameters for POST or PUT request
     * @param {Array} headers - Headers for the request formatted like { name: XX, value:YY }
     *
     * @private
     * @method BrainWs#_ajax
     */
    _ajax(events, httpMethod = BrainWsEnums.HttpMethods.GET, data = null, headers = []) {
        const updatedHeaders = headers.slice(0);
        const isTokenPresent = this.ssoToken !== null && this.ssoToken !== '';

        if (isTokenPresent) {
            updatedHeaders.push(BrainWs.createHeader(BrainWs.SSO_TOKEN_KEY, this.ssoToken));
        }

        return brainRequest(this.urlBuilder.build(), events, httpMethod, data, updatedHeaders, !isTokenPresent);
    }
}
