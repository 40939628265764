import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AppContext, setIsFetching } from '../../../../../app-context';
import { getPlatformApps } from '../../../../../wsClient/AppsManagerWsClient';

const AppPlatformContext = React.createContext();

export function AppPlatformContextProvider({ children }) {
    const [apps, setApps] = useState([]);
    const { dispatch } = useContext(AppContext);

    const getApps = useCallback(() => {
        dispatch(setIsFetching(true));

        getPlatformApps().then((response) => {
            if (response.ok) {
                response.json().then(setApps);
            }

            dispatch(setIsFetching(false));
        });
    }, [dispatch]);

    const providerValues = useMemo(() => ({ apps, getApps }), [apps, getApps]);

    useEffect(() => {
        getApps();
    }, [getApps]);

    return <AppPlatformContext.Provider value={providerValues}>{children}</AppPlatformContext.Provider>;
}

export const usePlatformApps = () => useContext(AppPlatformContext);
