import { Autocomplete, FormControl, styled, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { useI18n } from '@braincube/i18n';

import { getAllApps } from 'wsClient/AppsManagerWsClient';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(30),
}));

function renderOption(params, option) {
    return (
        <li {...params} key={option.uuid}>
            {option.label}
        </li>
    );
}

function VersionApplicationSelector({ selectedApplication, setSelectedApplication }) {
    const i18n = useI18n();

    const { data: apps, isLoading: applicationsIsLoading } = useQuery(['get-all-apps'], getAllApps, {
        select: (appsToSelect) =>
            appsToSelect
                .filter((app) => app.latestVersion.versionNumber !== '') // Cloud app doesn't have a vesrion number => We only want IoT app
                .map((app) => ({ label: app.name, uuid: app.id, isLatest: app.latestVersion.latest }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        initialData: [],
        onSuccess: (receivedApps) => {
            if (receivedApps.length > 0) {
                setSelectedApplication(receivedApps[0]);
            }
        },
    });

    const renderInput = useCallback(
        (params) => {
            return <TextField {...params} label={i18n.tc('ssoAdmin.versions.application')} />;
        },
        [i18n]
    );

    const onChange = useCallback((event, newApp) => setSelectedApplication(newApp), [setSelectedApplication]);

    return (
        <StyledFormControl>
            <Autocomplete
                renderInput={renderInput}
                options={apps}
                renderOption={renderOption}
                value={selectedApplication}
                onChange={onChange}
                loading={applicationsIsLoading}
                disableClearable
            />
        </StyledFormControl>
    );
}

VersionApplicationSelector.propTypes = {
    selectedApplication: PropTypes.shape({}),
    setSelectedApplication: PropTypes.func.isRequired,
};

VersionApplicationSelector.defaultProps = {
    selectedApplication: null,
};

export default VersionApplicationSelector;
