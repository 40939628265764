import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';

/**
 * This component displays a link to reset the user's password.
 */
function ResetPasswordLink({ email, provider }) {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const onClick = useCallback(() => {
        const events = {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.resetPasswordOK'), { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.resetPasswordNOK'), { variant: 'error' });
            },
        };

        new SsoWsClient().resetPassword(email, provider, events);
    }, [email, enqueueSnackbar, i18n, provider]);

    return (
        <div>
            <Button variant="contained" onClick={onClick} fullWidth>
                {i18n.tc('ssoAdmin.user.actions.sendResetPasswordLink')}
            </Button>
        </div>
    );
}

ResetPasswordLink.propTypes = {
    /** User identifier. Used to initiate the call to the webservice to reset the password */
    email: PropTypes.string.isRequired,
    /** Provider for which to change the user password */
    provider: PropTypes.string,
};

ResetPasswordLink.defaultProps = {
    provider: null,
};

export default ResetPasswordLink;
