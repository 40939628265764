import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

const SsoAceEditor = React.lazy(() => import('../index'));

function SuspenseFallback() {
    return <div>Loading editor...</div>;
}

function SsoAceEditorWithSuspense({ onChange, data, height }) {
    return (
        <Suspense fallback={SuspenseFallback}>
            <SsoAceEditor onChange={onChange} data={data} height={height} />
        </Suspense>
    );
}

SsoAceEditorWithSuspense.propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.string.isRequired,
    height: PropTypes.string,
};

SsoAceEditorWithSuspense.defaultProps = {
    height: '200px',
};

export default SsoAceEditorWithSuspense;
