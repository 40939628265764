/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Tabs,
    Tab,
    Checkbox,
    FormControl,
    FormControlLabel,
    createFilterOptions,
    styled,
    Autocomplete,
    ListItem,
    Grid,
} from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import IconSelector from '../../../IconSelector';
import { addApp } from '../../../../wsClient/AppsManagerWsClient';
import SsoAceEditorWithSuspense from '../../../SsoAceEditor/WithSuspense';
import MediaStepper from './MediaStepper';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import SketchPickerWrapper from './SketchPickerWrapper';
import CancelButton from '../../../Buttons/Cancel';
import APP_PRODUCT_TARGET from '../appTarget';
import ListboxComponent from '../../../ListboxComponent';
import Visibility from './Visibility';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const StyledAceEditorContainer = styled(`div`)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const helperTextProps = { style: { marginLeft: 0, whiteSpace: 'initial' } };

/**
 * Add app management
 */
const filter = createFilterOptions();

export const StyledTab = styled(Tab)({ minWidth: 0 });

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function filterOptions(options, params) {
    const filtered = filter(options, params);

    if (params.inputValue) {
        filtered.push({
            label: params.inputValue,
            value: params.inputValue,
        });
    }

    return filtered;
}

function isJsonString(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }

    return true;
}

function AppAddition({ onAdd, onCancel, tags, categories, product, productsList }) {
    const [name, setName] = useState('');
    const [pkgName, setPkgName] = useState('');
    const [description, setDescription] = useState('');
    const [fulldescription, setFullDescription] = useState('');
    const [url, setUrl] = useState('');
    const [tab, setTab] = useState(0);
    const [tempTags, setTempTags] = useState([]);
    const [tempCategory, setTempCategory] = useState(null);
    const [medias, setMedias] = useState([
        'https://source.unsplash.com/featured/?technology',
        'https://source.unsplash.com/featured/?industry',
    ]);
    const [color, setColor] = useState('#CCC');
    const [icon, setIcon] = useState('');
    const [defaultApp, setDefaultApp] = useState(false);
    const [eula, setEula] = useState('');
    const [isPublishedApp, setIsPublishedApp] = useState(true);
    const [selectedProductsOnApp, setSelectedProductsoOnApp] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const isFormValid = useMemo(() => name !== '' && pkgName !== '', [name, pkgName]);

    const initProductTarget = useCallback(() => {
        if (product === null) {
            return [];
        }
        return APP_PRODUCT_TARGET.filter((target) => target.value.toLowerCase() === product.type);
    }, [product]);

    const [productsTarget, setProductsTarget] = useState(initProductTarget());

    useEffect(() => {
        setProductsTarget(initProductTarget());
    }, [initProductTarget, product]);

    const handleAddition = useCallback(() => {
        addApp({
            name,
            package: pkgName,
            description,
            fulldescription,
            tags: tempTags.length > 0 ? tempTags.map((tag) => tag.value) : [],
            category: tempCategory ? tempCategory.value : null,
            mediaUrl: medias,
            color,
            icon,
            defaultApp,
            productsTarget: productsTarget.map((target) => target.value),
            latestVersion: {
                versionNumber: '',
                changelog: '',
                binaryUrl: '',
                nbDownload: 0,
                latest: true,
                filename: '',
                appUrl: url,
            },
            productId: selectedProductsOnApp,
            eula,
            published: isPublishedApp,
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.create.success'), { variant: 'success' });
                onAdd();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.create.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [
        color,
        defaultApp,
        description,
        enqueueSnackbar,
        eula,
        fulldescription,
        i18n,
        icon,
        isPublishedApp,
        medias,
        name,
        onAdd,
        onCancel,
        pkgName,
        productsTarget,
        selectedProductsOnApp,
        tempCategory,
        tempTags,
        url,
    ]);

    const handleVisibilityChange = useCallback((isPublished, selectedProducts) => {
        setIsPublishedApp(isPublished);
        setSelectedProductsoOnApp(selectedProducts.map((selectedProduct) => selectedProduct.value));
    }, []);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleDescriptionChange = useCallback((e) => setDescription(e.target.value), []);

    const handleFullDescriptionChange = useCallback((e) => setFullDescription(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handlePackageNameChange = useCallback((e) => setPkgName(e.target.value), []);

    const handleDefaultAppChange = useCallback((e) => setDefaultApp(e.target.checked), []);

    const checkboxControl = useMemo(
        () => <Checkbox checked={defaultApp} onChange={handleDefaultAppChange} />,
        [defaultApp, handleDefaultAppChange]
    );

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.type')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleProductsTargetChange = useCallback((event, newValue) => {
        setProductsTarget(newValue);
    }, []);

    const renderTagsInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.tags')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const renderCategoryInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.category')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleTagsChange = useCallback((event, newValue) => {
        setTempTags(newValue);
    }, []);

    const handleCategoryChange = useCallback((event, newValue) => {
        setTempCategory(newValue);
    }, []);

    const handleEulaChange = useCallback((e) => setEula(e.target.value), []);

    const handleMediasChange = useCallback((newMedias) => {
        if (isJsonString(newMedias)) {
            setMedias(JSON.parse(newMedias));
        }
    }, []);

    const onAddition = useCallback(() => {
        if (isFormValid) {
            handleAddition();
        }
    }, [handleAddition, isFormValid]);

    return (
        <Addition
            label={i18n.tc('ssoAdmin.apps.addition.title', { onProduct: product ? ` on ${product.name}` : '' })}
            onCancel={onCancel}
            isTab
        >
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.medias')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.tabs.colorAndLogo')} />
                <StyledTab label={i18n.tc('ssoAdmin.apps.visibility')} />
            </Tabs>

            <DrawerContent>
                <ContentArea>
                    {tab === 0 && (
                        <>
                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.name')}
                                    value={name}
                                    onChange={handleNameChange}
                                    fullWidth
                                    required
                                    error={name === '' && !isFormValid}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.description')}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    fullWidth
                                    multiline
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.fullDescription')}
                                    value={fulldescription}
                                    onChange={handleFullDescriptionChange}
                                    fullWidth
                                    multiline
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.url.label')}
                                    value={url}
                                    onChange={handleUrlChange}
                                    fullWidth
                                    helperText={i18n.tc('ssoAdmin.apps.fields.url.helperText')}
                                    placeholder={i18n.tc('ssoAdmin.apps.fields.url.placeholder')}
                                    FormHelperTextProps={helperTextProps}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.apps.fields.packageName')}
                                    value={pkgName}
                                    onChange={handlePackageNameChange}
                                    fullWidth
                                    required
                                    error={pkgName === '' && !isFormValid}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={checkboxControl}
                                        label={i18n.tc('ssoAdmin.apps.fields.defaultApp')}
                                    />
                                </FormControl>
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    disabled={product !== null}
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={APP_PRODUCT_TARGET}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={productsTarget}
                                    onChange={handleProductsTargetChange}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    multiple
                                    freeSolo
                                    forcePopupIcon
                                    filterOptions={filterOptions}
                                    handleHomeEndKeys
                                    renderInput={renderTagsInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={tags.map((tag) => ({
                                        label: tag,
                                        value: tag,
                                    }))}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={tempTags}
                                    onChange={handleTagsChange}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    freeSolo
                                    forcePopupIcon
                                    filterOptions={filterOptions}
                                    handleHomeEndKeys
                                    renderInput={renderCategoryInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={categories.map((category) => ({
                                        label: category,
                                        value: category,
                                    }))}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={tempCategory}
                                    onChange={handleCategoryChange}
                                />
                            </StyledSpacedWrapper>

                            <TextField
                                label={i18n.tc('ssoAdmin.apps.fields.eula')}
                                value={eula}
                                onChange={handleEulaChange}
                                fullWidth
                            />
                        </>
                    )}

                    {tab === 1 && (
                        <>
                            <StyledAceEditorContainer>
                                <SsoAceEditorWithSuspense
                                    onChange={handleMediasChange}
                                    data={JSON.stringify(medias)}
                                    height="200px"
                                />
                            </StyledAceEditorContainer>
                            {medias.length > 0 && <MediaStepper medias={medias} />}
                        </>
                    )}

                    {tab === 2 && (
                        <>
                            <StyledSpacedWrapper>
                                <SketchPickerWrapper color={color} setColor={setColor} />
                            </StyledSpacedWrapper>
                            <IconSelector onChange={setIcon} color={color} selectedIcon={icon} />
                        </>
                    )}

                    {tab === 3 && (
                        <Visibility
                            isPublished={isPublishedApp}
                            selectedProducts={selectedProductsOnApp}
                            productsList={productsList}
                            onChange={handleVisibilityChange}
                        />
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={onAddition} label={i18n.t('ssoAdmin.apps.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

AppAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    product: PropTypes.object,
    productsList: PropTypes.array,
};

AppAddition.defaultProps = {
    product: null,
    productsList: [],
};

export default AppAddition;
