const ACCESS_TYPES = {
    IPLWADMIN: 'IPLWADMIN',
    COACH: 'COACH',
    ADMIN: 'ADMIN',
    MANAGER: 'MANAGER',
    EXPERT: 'EXPERT',
    USER: 'USER',
    CUSTOM: 'CUSTOM',
};

export default ACCESS_TYPES;
