import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Tabs, IconButton, styled, Grid } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import Quota from './Quota';
import Offers from './Offers';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EditButton from '../../../Buttons/Edit';
import SiteProductsList from '../../../SiteProductsList';
import Edition from '../../../EntityManager/Edition';
import CancelButton from '../../../Buttons/Cancel';
import { AppContext, setIsFetching } from '../../../../app-context';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import isASCII from '../../../../services/regex/isASCII';
import Prefs, { PREF_TYPES } from '../../../Prefs';

const StyledCopyFieldContainer = styled(`div`)(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spacing(2),
    '& > div': {
        flex: 1,
        paddingBottom: 0,
        paddingRight: theme.spacing(1),
    },
}));

const ssoWs = new SsoWsClient();

/**
 * Edit site management
 */
function SiteEdition({ site, onUpdate, onCancel }) {
    const { dispatch } = useContext(AppContext);
    const [groupName, setgroupName] = useState(site.groupName || '');
    const [siteName, setSiteName] = useState(site.siteName || '');
    const [tab, setTab] = useState(0);
    const [idSap, setIdSap] = useState('');
    const [dnsName, setDnsName] = useState('');
    const inputEl = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setgroupName(site.groupName || '');
        setSiteName(site.siteName || '');
        ssoWs.getIdSAP(site.provider, site.groupName, site.siteName, {
            onSuccess: ({ response }) => {
                setIdSap(response || '');
            },
            onError: () => {
                setIdSap(null);
            },
        });
        ssoWs.getDnsName(site.provider, site.groupName, site.siteName, {
            onSuccess: ({ response }) => {
                setDnsName(response || '');
            },
            onError: () => {
                setDnsName(null);
            },
        });
    }, [site]);

    const handleEdit = useCallback(() => {
        if (idSap === '' || idSap == null || !isASCII(idSap)) {
            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.idSap.notValid'), { variant: 'error' });
        } else if (dnsName === '' || dnsName == null || !isASCII(dnsName)) {
            enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.dnsName.notValid'), { variant: 'error' });
        } else {
            dispatch(setIsFetching(true));
            ssoWs.setIdSAP(site.provider, site.groupName, site.siteName, idSap, {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.idSap.edited'), { variant: 'success' });
                    setIdSap('');
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                },
            });

            new SsoWsClient().setDnsName(site.provider, site.groupName, site.siteName, dnsName, {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.dnsName.edited'), { variant: 'success' });
                    setDnsName('');
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                },
            });
            onUpdate();
        }
    }, [dispatch, dnsName, enqueueSnackbar, i18n, idSap, onUpdate, site.groupName, site.provider, site.siteName]);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleGroupNameChange = useCallback((e) => setgroupName(e.target.value), []);

    const handleSiteNameChange = useCallback((e) => setSiteName(e.target.value), []);

    const handleCopy = useCallback(() => {
        inputEl.current.disabled = false;
        inputEl.current.select();
        inputEl.current.disabled = true;
        document.execCommand('copy');
    }, []);

    const handleIdSapChange = useCallback((e) => setIdSap(e.target.value), []);

    const handleDnsNameChange = useCallback((e) => setDnsName(e.target.value), []);

    return (
        <Edition label={i18n.tc('ssoAdmin.sites.edition.title')} onCancel={onCancel}>
            <Tabs
                variant="fullWidth"
                textColor="secondary"
                indicatorColor="secondary"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.sites.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.sites.tabs.products')} />
                <StyledTab label={i18n.tc('ssoAdmin.sites.tabs.quota')} />
                <StyledTab label={i18n.tc('ssoAdmin.sites.tabs.offers')} />
                <StyledTab label="Prefs" />
            </Tabs>
            {tab === 0 && (
                <>
                    <DrawerContent>
                        <ContentArea>
                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.sites.fields.groupName')}
                                    value={groupName}
                                    onChange={handleGroupNameChange}
                                    fullWidth
                                    disabled
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.sites.fields.siteName')}
                                    value={siteName}
                                    onChange={handleSiteNameChange}
                                    fullWidth
                                    disabled
                                />
                            </StyledSpacedWrapper>

                            <StyledCopyFieldContainer>
                                <StyledSpacedWrapper>
                                    <TextField
                                        fullWidth
                                        label={i18n.tc('ssoAdmin.sites.fields.idSite')}
                                        value={site.siteUuid}
                                        disabled
                                        inputRef={inputEl}
                                    />
                                </StyledSpacedWrapper>

                                <IconButton onClick={handleCopy} size="large">
                                    <FileCopyIcon />
                                </IconButton>
                            </StyledCopyFieldContainer>

                            <StyledSpacedWrapper>
                                <TextField
                                    fullWidth
                                    label={i18n.tc('ssoAdmin.sites.fields.idSap')}
                                    value={idSap}
                                    onChange={handleIdSapChange}
                                />
                            </StyledSpacedWrapper>

                            <TextField
                                fullWidth
                                label={i18n.tc('ssoAdmin.sites.fields.dnsName')}
                                value={dnsName}
                                onChange={handleDnsNameChange}
                            />
                        </ContentArea>
                    </DrawerContent>
                    <DrawerActions>
                        <Grid container justifyContent="flex-end" mt={1}>
                            <CancelButton onClick={onCancel} />
                            <EditButton onClick={handleEdit} />
                        </Grid>
                    </DrawerActions>
                </>
            )}
            {tab === 1 && <SiteProductsList site={site} provider={site.provider} />}
            {tab === 2 && <Quota site={site} />}
            {tab === 3 && <Offers site={site} onAdd={onUpdate} />}
            {tab === 4 && <Prefs objectUuid={site.siteUuid} prefType={PREF_TYPES.SITES} onCancel={onCancel} />}
        </Edition>
    );
}

SiteEdition.propTypes = {
    site: PropTypes.shape({
        groupName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        siteUuid: PropTypes.string.isRequired,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SiteEdition;
