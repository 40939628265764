import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import Edition from '../../../EntityManager/Edition';
import { DELEGATE_PROTOCOL } from './DelegateAddition';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

const DelegateConfigEditor = lazy(() => import('./DelegateConfigEditor'));

const ssoWs = new SsoWsClient();

function SuspenseFallback() {
    const i18n = useI18n();

    return <div>{i18n.tc('ssoAdmin.delegates.actions.loadingEditor')}</div>;
}

/**
 * Edit group management
 */
function DelegateEdition(props) {
    const [isDelegateConfigIsOpen, setIsDelegateConfigIsOpen] = useState(false);
    const [configuration, setConfiguration] = useState(props.delegate.configuration);
    const [protocol, setProtocol] = useState(props.delegate.protocol);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        setProtocol(props.delegate.protocol);
        setConfiguration(props.delegate.configuration);
    }, [props.delegate]);

    const handleEdit = useCallback(() => {
        ssoWs.editDelegate(
            {
                ...props.delegate,
                configuration: JSON.parse(configuration),
            },
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.delegates.notistack.update.success'), { variant: 'success' });
                    setIsDelegateConfigIsOpen(false);
                    props.onCancel();
                },
                onError: ({ httpCode }) => {
                    let msg = i18n.tc('ssoAdmin.delegates.notistack.update.error.default');
                    if (httpCode === 400) msg = i18n.tc('ssoAdmin.delegates.notistack.update.error.invalidDelegate');
                    if (httpCode === 404) msg = i18n.tc('ssoAdmin.delegates.notistack.update.error.notFound');
                    if (httpCode === 409) msg = i18n.tc('ssoAdmin.delegates.notistack.update.error.conflict');
                    enqueueSnackbar(msg, { variant: 'error' });
                    setIsDelegateConfigIsOpen(false);
                    props.onCancel();
                },
            }
        );
    }, [configuration, enqueueSnackbar, i18n, props]);

    const handleProtocolChange = useCallback((e) => setProtocol(e.target.value), []);

    const onCloseDelegateConfigEditor = useCallback(() => setIsDelegateConfigIsOpen(false), []);
    const onOpenDelegateConfigEditor = useCallback(() => setIsDelegateConfigIsOpen(true), []);

    return (
        <Edition label={i18n.tc('ssoAdmin.delegates.edition.title')} onCancel={props.onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.delegates.fields.name')}
                            disabled
                            value={props.delegate.name}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('ssoAdmin.delegates.fields.protocol')}</InputLabel>
                            <Select
                                value={protocol}
                                onChange={handleProtocolChange}
                                label={i18n.tc('ssoAdmin.delegates.fields.protocol')}
                            >
                                {DELEGATE_PROTOCOL.map((proto) => (
                                    <MenuItem key={proto.value} value={proto.value}>
                                        {proto.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>

                    <FormControl fullWidth>
                        <Button color="secondary" onClick={onOpenDelegateConfigEditor}>
                            {i18n.tc('ssoAdmin.delegates.actions.changeConfig')}
                        </Button>
                    </FormControl>

                    {isDelegateConfigIsOpen && (
                        <Suspense fallback={SuspenseFallback}>
                            <DelegateConfigEditor
                                onClose={onCloseDelegateConfigEditor}
                                onChange={setConfiguration}
                                onValidate={handleEdit}
                                data={configuration}
                            />
                        </Suspense>
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <CancelButton onClick={props.onCancel} />
                </Grid>
            </DrawerActions>
        </Edition>
    );
}

DelegateEdition.propTypes = {
    delegate: PropTypes.shape({
        name: PropTypes.string.isRequired,
        protocol: PropTypes.string.isRequired,
        configuration: PropTypes.string.isRequired,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DelegateEdition;
