import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    DialogContent,
    Autocomplete,
    ListItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender, SmallCheckBox } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import ListboxComponent from '../../../ListboxComponent';

const MANAGED_CHOICES = {
    TAKE_OWNERSHIP: 'take-ownership',
    NEW_OWNER: 'new-owner',
};

const radioIcon = <Radio />;

const renderOption = (props, option, { selected }) => (
    <ListItem button {...props}>
        <SmallCheckBox option={option} selected={selected} />
    </ListItem>
);

const getOptionLabel = (option) => option.label;

const isOptionEqualToValue = (option, value) => option.value === value.value;

function UserDelete({ onClick, disabled, userUuid, users, myEmail }) {
    const i18n = useI18n();
    const [open, setOpen] = useState(false);
    const [managedUsers, setManagedUsers] = useState([]);
    const [managedChoice, setManagedChoice] = useState(MANAGED_CHOICES.TAKE_OWNERSHIP);
    const [newOwner, setNewOwner] = useState(null);

    const handleManagedChoiceChange = useCallback((e) => setManagedChoice(e.target.value), []);

    useEffect(() => {
        new SsoWsClient().getManagerUsers(userUuid, {
            onSuccess: ({ response }) => {
                setManagedUsers(response.users);
            },
            onError: () => {},
        });
    }, [userUuid]);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmDelete = useCallback(() => {
        setOpen(false);

        if (managedChoice === MANAGED_CHOICES.NEW_OWNER && newOwner !== null) {
            onClick(newOwner.value);
        } else if (managedChoice === MANAGED_CHOICES.TAKE_OWNERSHIP) {
            onClick(myEmail);
        } else {
            onClick();
        }
    }, [managedChoice, myEmail, newOwner, onClick]);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.user.emails')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleUsersChange = useCallback((event, newValue) => {
        setNewOwner(newValue);
    }, []);

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={disabled}>
                <DeleteIcon />
                {i18n.tc('ssoAdmin.actions.delete')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{i18n.tc('ssoAdmin.actions.delete')} ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {managedUsers.length === 0
                            ? i18n.tc('ssoAdmin.actions.confirmDeletion')
                            : i18n.tc('ssoAdmin.user.hasManagedUsers')}
                    </DialogContentText>
                    {managedUsers.length > 0 && (
                        <>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="managed-choice"
                                    value={managedChoice}
                                    onChange={handleManagedChoiceChange}
                                >
                                    <FormControlLabel
                                        value={MANAGED_CHOICES.TAKE_OWNERSHIP}
                                        control={radioIcon}
                                        label={i18n.tc('ssoAdmin.user.actions.takeOwnership', {
                                            smart_count: managedUsers.length,
                                            num: managedUsers.length,
                                        })}
                                    />
                                    <FormControlLabel
                                        value={MANAGED_CHOICES.NEW_OWNER}
                                        control={radioIcon}
                                        label={i18n.tc('ssoAdmin.user.actions.chooseNewOwner')}
                                    />
                                </RadioGroup>
                            </FormControl>
                            {managedChoice === MANAGED_CHOICES.NEW_OWNER && (
                                <Autocomplete
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderInput={renderInput}
                                    ListboxComponent={ListboxComponent}
                                    renderOption={renderOption}
                                    options={users.map((user) => ({ label: user.email, value: user.email }))}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    value={newOwner}
                                    onChange={handleUsersChange}
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        {i18n.tc('ssoAdmin.actions.cancel')}
                    </Button>
                    <Button variant="contained" onClick={confirmDelete} color="primary" autoFocus>
                        {i18n.tc('ssoAdmin.actions.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

UserDelete.propTypes = {
    onClick: PropTypes.func.isRequired,
    userUuid: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape).isRequired,
    myEmail: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

UserDelete.defaultProps = {
    disabled: false,
};

export default UserDelete;
