import PropTypes from 'prop-types';

const apiRefPropTypes = PropTypes.shape({
    current: PropTypes.shape({
        updateRows: PropTypes.func,
        setRowMode: PropTypes.func,
        scrollToIndexes: PropTypes.func,
        getRowsCount: PropTypes.func,
        setCellFocus: PropTypes.func,
    }),
});

export default apiRefPropTypes;
