import { Apps } from '@mui/icons-material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { HandshakeOutline, Dashboard } from '@braincube/svg';
import { useI18n } from '@braincube/i18n';

const activeClassName = 'Mui-selected';

function AppsManagerSidebar() {
    const url = window.location.href;
    const i18n = useI18n();

    return (
        <List disablePadding>
            <ListItemButton component={NavLink} to="/apps" activeClassName={activeClassName}>
                {url.includes('apps') ? (
                    <ListItemIcon>
                        <Dashboard htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.apps')} />
            </ListItemButton>

            <ListItemButton component={NavLink} to="/apps-platform" activeClassName={activeClassName}>
                {url.includes('apps-platform') ? (
                    <ListItemIcon>
                        <Dashboard htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.platformApps')} />
            </ListItemButton>

            <ListItemButton component={NavLink} to="/offers" activeClassName={activeClassName}>
                {url.includes('offers') ? (
                    <ListItemIcon>
                        <HandshakeOutline htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <HandshakeOutline />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.offers')} />
            </ListItemButton>

            <ListItemButton component={NavLink} to="/versions" activeClassName={activeClassName}>
                {url.includes('versions') ? (
                    <ListItemIcon>
                        <Apps htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <Apps />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.versions')} />
            </ListItemButton>
        </List>
    );
}

export default AppsManagerSidebar;
