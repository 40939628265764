import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import GroupList from '../../../GroupList';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

const ssoWs = new SsoWsClient();

function getUsers(onSuccess, onError) {
    const events = { onSuccess, onError };
    return ssoWs.getAllUsers(events);
}

function mapUsers(response) {
    const users = [];

    response.users.forEach((user) => {
        if (user.ssoBranch === 'CORPORATE') {
            users[user.email] = user.email;
        }
    });
    return users;
}

/**
 * This component is displayed in a group's edit popin and is used to edit, add or remove members.
 *
 * It's an EntityManager inside another EntityManager (the list of the users)
 */
function GroupMemberList({ group }) {
    const onMemberDeleting = useCallback(
        (memberTodelete, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.deleteGroupMember(memberTodelete.email, group.id, events);
        },
        [group.id]
    );

    const onMemberAdding = useCallback(
        (memberToAdd, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.addMemberForGroup(memberToAdd.email, group, events);
        },
        [group]
    );

    const getMembers = useCallback(
        (onSuccess, onError) => {
            const events = { onSuccess, onError };
            return ssoWs.getGroupMembers(group.id, events);
        },
        [group.id]
    );

    return (
        <GroupList
            key={group.id}
            mapEntities={mapUsers}
            getMembers={getMembers}
            entityKeyName="email"
            entityLabel="Member"
            getEntities={getUsers}
            handleMemberToAdd={onMemberAdding}
            handleMemberToDelete={onMemberDeleting}
            reponseEntityName="users"
        />
    );
}

GroupMemberList.propTypes = {
    /** Group opened */
    group: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default GroupMemberList;
