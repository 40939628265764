import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, FormControl, MenuItem, Select, InputLabel, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';

export const SSO_BRANCH = ['CLIENT', 'CORPORATE'];

const ssoWs = new SsoWsClient();

/**
 * Add user management
 */
function UserAddition({ onAdd, onCancel }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [ssoBranch, setSsoBranch] = useState(SSO_BRANCH[0]);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        const providers = [selectedProvider];
        ssoWs.createUser(
            { firstName, lastName, email, providers, ssoBranch },
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.create.success'), { variant: 'success' });

                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setEmail([]);
                    setSsoBranch(SSO_BRANCH[0]);

                    onAdd();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [email, enqueueSnackbar, firstName, i18n, lastName, onAdd, onCancel, selectedProvider, ssoBranch]);

    const handleFirstnameChange = useCallback((e) => setFirstName(e.target.value), []);

    const handleLastnameChange = useCallback((e) => setLastName(e.target.value), []);

    const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);

    const handleProviderChange = useCallback((e) => setSelectedProvider(e.target.value), []);

    const handleBranchChange = useCallback((e) => setSsoBranch(e.target.value), []);

    useEffect(() => {
        ssoWs.getAllProviders({
            onSuccess: ({ response }) => {
                setProviderList(response.providers);
                setSelectedProvider(response.providers[0].name);
            },
            onError: () => {},
        });
    }, []);

    if (providerList.length === 0 || selectedProvider === null) {
        return null;
    }

    return (
        <Addition label={i18n.tc('ssoAdmin.users.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.firstname')}
                            value={firstName}
                            onChange={handleFirstnameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.lastname')}
                            value={lastName}
                            onChange={handleLastnameChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.users.fields.email')}
                            value={email}
                            onChange={handleEmailChange}
                            fullWidth
                            required
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControl fullWidth>
                            <InputLabel>{i18n.tc('ssoAdmin.users.fields.provider')}</InputLabel>
                            <Select
                                value={selectedProvider}
                                onChange={handleProviderChange}
                                label={i18n.tc('ssoAdmin.users.fields.provider')}
                            >
                                {providerList.map((provider) => (
                                    <MenuItem key={provider.name} value={provider.name}>
                                        {provider.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <FormControl fullWidth required>
                            <InputLabel>{i18n.tc('ssoAdmin.users.fields.branch')}</InputLabel>
                            <Select
                                value={ssoBranch}
                                onChange={handleBranchChange}
                                label={i18n.tc('ssoAdmin.users.fields.branch')}
                            >
                                {SSO_BRANCH.map((branch) => (
                                    <MenuItem key={branch} value={branch}>
                                        {branch}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </StyledSpacedWrapper>
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton onClick={handleAddition} label={i18n.t('ssoAdmin.users.addButton')} />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

UserAddition.propTypes = {
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserAddition;
