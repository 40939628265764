import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    FormControlLabel,
    Grid,
    Checkbox,
    Autocomplete,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledCheckbox } from 'components/GrantList/GrantAddition';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import AddButton from '../../../Buttons/Add';
import Addition from '../../../EntityManager/Addition';
import CancelButton from '../../../Buttons/Cancel';
import ListboxComponent from '../../../ListboxComponent';
import PRODUCT_TYPES from './productTypes';
import IoTStackField from './components/IoTStackField';

const ssoWs = new SsoWsClient();

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

const renderOption = (props, option, { selected }) => (
    <div {...props}>
        <StyledCheckbox icon={checkboxIcon} checkedIcon={checkedCheckboxIcon} checked={selected} />
        {option.label}
    </div>
);

const getOptionLabel = (option) => option.label;

const isOptionEqualToValue = (option, value) => option.label === value.label;

/**
 * Add product management
 */
function ProductAddition({ providersProductsList, provider, onAdd, onCancel }) {
    const [name, setName] = useState('');
    const [siteList, setSiteList] = useState([]);
    const [selectedSite, setSelectedSite] = useState('');
    const [selectedSiteUuid, setSelectedSiteUuid] = useState('');
    const [siteClient, setSiteClient] = useState('');
    const [url, setUrl] = useState('');
    const [type, setType] = useState(PRODUCT_TYPES.BRAINCUBE.value);
    const [cloudIot, setCloudIot] = useState(false);
    const [licenceValidity, setLicenceValidity] = useState(365);
    const [stackId, setStackId] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleAddition = useCallback(() => {
        const product = {
            name,
            clientName: siteClient,
            siteName: selectedSite,
            url,
            type,
            stackId: stackId?.value || null,
        };

        if (type === PRODUCT_TYPES.IOT.value) {
            if (
                !name.match(
                    new RegExp(`IOT${siteClient.replace(/\s/g, '')}${selectedSite.replace(/\s/g, '')}\\d+`, 'i')
                )
            ) {
                enqueueSnackbar(
                    // eslint-disable-next-line max-len
                    i18n.tc('ssoAdmin.products.notistack.create.notMatch'),
                    { variant: 'error' }
                );

                return;
            }

            product.data = {
                renew: licenceValidity.toString(),
                internal_deployment: cloudIot.toString(),
            };

            product.data = JSON.stringify(product.data);
        }

        if (type === PRODUCT_TYPES.IOT_STACK.value) {
            product.data = {
                internal_deployment: cloudIot.toString(),
            };

            product.data = JSON.stringify(product.data);
        }

        ssoWs.createProduct(provider, product, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.create.success'), { variant: 'success' });

                setName('');
                setSelectedSite('');
                setSiteClient('');
                setUrl('');
                setType(PRODUCT_TYPES.BRAINCUBE.value);
                setCloudIot(false);
                setStackId('');
                setLicenceValidity(365);
                onAdd();
            },
            onError: (brainWsResponse, xhr) => {
                enqueueSnackbar(xhr.response, { variant: 'error' });
                onCancel();
            },
        });
    }, [
        cloudIot,
        enqueueSnackbar,
        i18n,
        licenceValidity,
        name,
        onAdd,
        onCancel,
        provider,
        selectedSite,
        siteClient,
        stackId,
        type,
        url,
    ]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const selectedValue = useMemo(() => {
        return selectedSite
            ? {
                  value: { site: selectedSite, client: siteClient },
                  label: selectedSite.concat(' - ', siteClient),
              }
            : null;
    }, [selectedSite, siteClient]);

    const handleSiteNameChange = useCallback((event, newValue) => {
        setSelectedSiteUuid(newValue?.value.uuid || '');
        setSelectedSite(newValue?.value.site || '');
        setSiteClient(newValue?.value.client || '');

        setStackId(null);
    }, []);

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                label={i18n.tc('ssoAdmin.products.fields.siteName')}
                placeholder={i18n.tc('ssoAdmin.search')}
                required
                error={!selectedValue}
            />
        ),
        [i18n, selectedValue]
    );

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleTypeChange = useCallback((e) => setType(e.target.value), []);

    const handleCloutIotChange = useCallback((e) => setCloudIot(e.target.checked), []);

    const cloudIotCheckbox = useMemo(
        () => <Checkbox checked={cloudIot} onChange={handleCloutIotChange} />,
        [cloudIot, handleCloutIotChange]
    );

    const handleLicenceValidityChange = useCallback((e) => setLicenceValidity(e.target.value), []);

    const handleStackIdChange = useCallback((value) => setStackId(value), []);

    useEffect(() => {
        ssoWs.getSitesByProvider(provider, {
            onSuccess: ({ response }) => {
                setSiteList(response.siteList);
            },
            onError: () => {},
        });
    }, [provider]);

    useEffect(() => {
        if ([PRODUCT_TYPES.IOT.value, PRODUCT_TYPES.IOT_STACK.value].includes(type)) {
            setCloudIot(true);
        }
    }, [type]);

    if (siteList.length === 0) {
        return null;
    }

    return (
        <Addition label={i18n.tc('ssoAdmin.products.addition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                label={i18n.tc('ssoAdmin.products.fields.name')}
                                value={name}
                                onChange={handleNameChange}
                                fullWidth
                                required
                                error={!name}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                disabled
                                label={i18n.tc('ssoAdmin.products.fields.groupName')}
                                value={siteClient}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderInput={renderInput}
                                ListboxComponent={ListboxComponent}
                                renderOption={renderOption}
                                options={siteList.map((site) => ({
                                    label: site.siteName.concat(' - ', site.groupName),
                                    value: { uuid: site.siteUuid, site: site.siteName, client: site.groupName },
                                }))}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={selectedValue}
                                onChange={handleSiteNameChange}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label={i18n.tc('ssoAdmin.products.fields.url')}
                                value={url}
                                onChange={handleUrlChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth required>
                                <InputLabel>{i18n.tc('ssoAdmin.products.fields.type')}</InputLabel>
                                <Select
                                    value={type}
                                    onChange={handleTypeChange}
                                    label={i18n.tc('ssoAdmin.products.fields.type')}
                                >
                                    {Object.keys(PRODUCT_TYPES).map((key) => (
                                        <MenuItem key={PRODUCT_TYPES[key].value} value={PRODUCT_TYPES[key].value}>
                                            {PRODUCT_TYPES[key].label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {[PRODUCT_TYPES.IOT.value, PRODUCT_TYPES.IOT_STACK.value].includes(type) && (
                            <Grid item>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={cloudIotCheckbox}
                                        label={i18n.tc('ssoAdmin.products.fields.cloudIot')}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        {type === PRODUCT_TYPES.IOT.value && (
                            <>
                                <Grid item>
                                    <TextField
                                        label={i18n.tc('ssoAdmin.products.fields.licenceValidity')}
                                        value={licenceValidity}
                                        onChange={handleLicenceValidityChange}
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item>
                                    <IoTStackField
                                        siteUuid={selectedSiteUuid}
                                        providersProducts={providersProductsList}
                                        value={stackId}
                                        onChange={handleStackIdChange}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <AddButton
                        disabled={!selectedSite || !name}
                        onClick={handleAddition}
                        label={i18n.t('ssoAdmin.products.addButton')}
                    />
                </Grid>
            </DrawerActions>
        </Addition>
    );
}

ProductAddition.propTypes = {
    providersProductsList: PropTypes.arrayOf(
        PropTypes.shape({
            productId: PropTypes.string.isRequired,
            site: PropTypes.shape({
                siteUuid: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    provider: PropTypes.string.isRequired,
    /** Function called when the addition was successful */
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProductAddition;
