import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Autocomplete } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { AutocompleteInputRender } from '@braincube/ui-lab';

import { AppContext, setIsFetching } from '../../../../app-context';
import { deleteApp } from '../../../../wsClient/AppsManagerWsClient';
import Deletion from '../../../EntityManager/Deletion';
import DeleteButton from '../../../Buttons/Delete';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import APP_PRODUCT_TARGET from '../appTarget';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

function getOptionLabel(option) {
    return option.label;
}

function getTagsOptionLabel(option) {
    return option.label || option;
}

function getCategoryOptionLabel(option) {
    return option.label || option;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

/**
 * Edit app management
 */
function AppDeletion({ app, onCancel, onDelete, tags, categories }) {
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        dispatch(setIsFetching(true));

        deleteApp(app.id).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.delete.success'), { variant: 'success' });
                onDelete();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.apps.notistack.delete.error'), { variant: 'error' });
            }

            dispatch(setIsFetching(false));
        });
    }, [app.id, dispatch, enqueueSnackbar, i18n, onDelete]);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.type')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const renderTagsInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.fields.tags')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const renderCategoryInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.apps.category')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const tagsValue = useMemo(
        () =>
            app.tags.map((tag) => ({
                label: tag,
                value: tag,
            })) || [],
        [app.tags]
    );

    const categoryValue = useMemo(
        () => (app.category ? { label: app.category, value: app.category } : null),
        [app.category]
    );

    return (
        <Deletion label={i18n.tc('ssoAdmin.apps.deletion.title')} onCancel={onCancel}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField disabled fullWidth label={i18n.tc('ssoAdmin.apps.fields.name')} value={app.name} />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            fullWidth
                            label={i18n.tc('ssoAdmin.apps.fields.description')}
                            value={app.description}
                            multiline
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            fullWidth
                            label={i18n.tc('ssoAdmin.apps.fields.fullDescription')}
                            value={app.fulldescription || ''}
                            multiline
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.apps.fields.url.label')}
                            value={app.latestVersion.appUrl || ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            fullWidth
                            label={i18n.tc('ssoAdmin.apps.fields.packageName')}
                            value={app.package}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            multiple
                            disabled
                            renderInput={renderInput}
                            options={APP_PRODUCT_TARGET}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={app.productsTarget.map((target) => ({ label: target, value: target }))}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            multiple
                            disabled
                            renderInput={renderTagsInput}
                            options={tags.map((tag) => ({
                                label: tag,
                                value: tag,
                            }))}
                            getOptionLabel={getTagsOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={tagsValue}
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <Autocomplete
                            disabled
                            renderInput={renderCategoryInput}
                            options={categories.map((category) => ({
                                label: category,
                                value: category,
                            }))}
                            getOptionLabel={getCategoryOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={categoryValue}
                        />
                    </StyledSpacedWrapper>
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

AppDeletion.propTypes = {
    app: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fulldescription: PropTypes.string,
        id: PropTypes.string.isRequired,
        latestVersion: PropTypes.shape({
            appUrl: PropTypes.string,
        }).isRequired,
        productsTarget: PropTypes.array.isRequired,
        package: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        category: PropTypes.string,
        mediaUrl: PropTypes.arrayOf(PropTypes.string),
        color: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
};

export default AppDeletion;
