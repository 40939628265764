import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import CachedIcon from '@mui/icons-material/Cached';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EntityManager from '../../../EntityManager';
import DelegateAddition from './DelegateAddition';
import DelegateEdition from './DelegateEdition';
import DelegateDeletion from './DelegateDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';

const ssoWs = new SsoWsClient();

function AddRenderer({ onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <DelegateAddition onAdd={onAdd} onCancel={onHideDrawer} />;
}

function EditRenderer({ delegate, onFetch, onHideDrawer }) {
    const onUpdate = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return <DelegateEdition delegate={delegate} onUpdate={onUpdate} onCancel={onHideDrawer} />;
}

function DelRenderer({ delegate, onFetch, onHideDrawer, setDeletingDelegate }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingDelegate(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingDelegate]);

    return <DelegateDeletion delegate={delegate} onDelete={onDelete} onCancel={onCancel} />;
}

function DeployIcon({ delegate, onClick }) {
    const handleClick = useCallback(
        (event) => {
            onClick(event, delegate);
        },
        [delegate, onClick]
    );

    return <CachedIcon onClick={handleClick} htmlColor="#000" />;
}

/**
 * Groups management
 */
function Delegates() {
    const { dispatch, state } = useContext(AppContext);

    const [delegates, setDelegates] = useState([]);
    const [deletingDelegate, setDeletingDelegate] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();
    const apiRef = useGridApiRef();

    const deployDelegate = useCallback(
        (name) => {
            dispatch(setIsFetching(true));

            ssoWs.deployDelegate(name, {
                onSuccess: () => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(i18n.tc('ssoAdmin.delegates.notistack.deploy.success'), { variant: 'success' });
                },
                onError: (brainWsResponse, xhr) => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                },
            });
        },
        [dispatch, enqueueSnackbar, i18n]
    );

    const onClickDeploy = useCallback(
        (e, delegate) => {
            e.preventDefault();
            e.stopPropagation();
            deployDelegate(delegate.name);
        },
        [deployDelegate]
    );

    const fetchDelegates = useCallback(() => {
        ssoWs.getDelegates({
            onSuccess: ({ response }) =>
                setDelegates(
                    response.delegates.map((delegate) => ({
                        ...delegate,
                        configuration: JSON.stringify(delegate.configuration, undefined, 2),
                        version: delegate.configuration.version,
                        deploy: <DeployIcon delegate={delegate} onClick={onClickDeploy} />,
                    }))
                ),
            onError: (brainWsResponse, xhr) => enqueueSnackbar(xhr.response, { variant: 'error' }),
        });
    }, [enqueueSnackbar, onClickDeploy]);

    const addRenderer = useCallback(
        (hideDrawers) => <AddRenderer onFetch={fetchDelegates} onHideDrawer={hideDrawers} />,
        [fetchDelegates]
    );

    const editRenderer = useCallback(
        (delegate, hideDrawers) => (
            <EditRenderer delegate={delegate} onFetch={fetchDelegates} onHideDrawer={hideDrawers} />
        ),
        [fetchDelegates]
    );

    const delRenderer = useCallback(
        (delegate, hideDrawers) => (
            <DelRenderer
                delegate={delegate}
                setDeletingDelegate={setDeletingDelegate}
                onFetch={fetchDelegates}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchDelegates]
    );

    const handleDelete = useCallback((delegate) => setDeletingDelegate(JSON.parse(delegate)), []);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: i18n.tc('ssoAdmin.delegates.headerName.name'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'protocol',
                headerName: i18n.tc('ssoAdmin.delegates.headerName.protocol'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'configuration',
                headerName: i18n.tc('ssoAdmin.delegates.headerName.configuration'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'version',
                headerName: i18n.tc('ssoAdmin.delegates.headerName.version'),
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'deploy',
                headerName: i18n.tc('ssoAdmin.delegates.headerName.deploy'),
                flex: 1,
                renderCell: ({ row }) => row.deploy,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => <ActionsCell id={id} apiRef={apiRef} onDelete={handleDelete} preventEdit />,
            },
        ],
        [apiRef, handleDelete, i18n]
    );

    useEffect(fetchDelegates, [fetchDelegates]);

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingDelegate}
            entities={delegates}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.delegates.addition.title')}
        />
    );
}

export default Delegates;
