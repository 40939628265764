import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import DeleteButton from '../../../Buttons/Delete';
import Deletion from '../../../EntityManager/Deletion';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit site management
 */
function SiteDeletion({ site, onDelete, onCancel }) {
    const { dispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.deleteSite(site.siteUuid, {
            onSuccess: () => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(i18n.tc('ssoAdmin.sites.notistack.delete.success'), { variant: 'success' });
                onDelete();
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
    }, [dispatch, enqueueSnackbar, i18n, onDelete, site.siteUuid]);

    return (
        <Deletion label={i18n.tc('ssoAdmin.sites.deletion.title')} onCancel={onCancel}>
            <>
                <FormField>
                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.sites.fields.siteName')}
                            value={site.siteName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.sites.fields.groupName')}
                            value={site.groupName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <TextField
                        disabled
                        label={i18n.tc('ssoAdmin.sites.fields.idSite')}
                        value={site.siteUuid}
                        fullWidth
                    />
                </FormField>
                <StyledButtonContainer>
                    <CancelButton onClick={onCancel} />
                    <DeleteButton onClick={handleDelete} />
                </StyledButtonContainer>
            </>
        </Deletion>
    );
}

SiteDeletion.propTypes = {
    site: PropTypes.shape({
        groupName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        siteUuid: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SiteDeletion;
