const PRODUCT_TYPES = {
    BRAINCUBE: { value: 'braincube', label: 'Braincube' },
    MX: { value: 'mx', label: 'MX' },
    TAMPIX: { value: 'tampix', label: 'Tampix' },
    ADMIN: { value: 'admin', label: 'Admin' },
    IOT: { value: 'iot', label: 'IoT' },
    IOT_STACK: { value: 'iot-stack', label: 'IoT Stack' },
    PLATFORM: { value: 'platform', label: 'Platform' },
    MODEL_MANAGER: { value: 'model-manager', label: 'Model Manager' },
};

export default PRODUCT_TYPES;
