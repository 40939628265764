import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, styled } from '@mui/material';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    border: '0px',
    width: '100%',
}));

function FormField({ children }) {
    return <StyledFormControl component="fieldset">{children}</StyledFormControl>;
}

FormField.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FormField;
