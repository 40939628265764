import Check from '@braincube/brain-js/lib/check';

/**
 * This class can be seen as a memo in order for the RequestsManager owner to store its pending (= running) requests.
 * Pending requests can also be cancelled at any time (but it is the responsibility of the RequestsManager owner to call
 * setTerminated() when the request has finished).
 *
 * @class
 */
export default class RequestsManager {
    /**
     * @constructor
     */
    constructor() {
        /** Map of pending requests. Keys are request IDs and values are request objects. */
        this.pendingRequests = {};
    }

    /**
     * Set a new request ID to the pending requests.
     * If another request with the same ID is already pending, it will be overwritten and aborted.
     * @param {string} requestId ID of the request to add.
     * @param {object} request The request object.
     *
     * @method RequestsManager#setPending
     */
    setPending(requestId, request) {
        this.abort(requestId);
        this.pendingRequests[requestId] = request;
    }

    /**
     * Remove a request from the pending requests list.
     * @param {string} requestId Id of the request to terminate.
     * @returns {boolean} True if there is at least one request pending, false otherwise.
     *
     * @method RequestsManager#setTerminated
     */
    setTerminated(requestId) {
        delete this.pendingRequests[requestId];
        return this.isPending();
    }

    /**
     * Abort a pending request given its request ID, then remove it from the pending requests list.
     * @param {string} requestId ID of the request to abort.
     * @returns {boolean} True if there is at least one request pending after the abort, false otherwise.
     *
     * @method RequestsManager#abort
     */
    abort(requestId) {
        const toAbort = this.pendingRequests[requestId];

        if (!Check.isUndefinedOrNull(toAbort)) {
            toAbort.abort();
        }

        return this.setTerminated(requestId);
    }

    /**
     * Aborts all pending requests, then remove them from the pending requests list.
     *
     * @method RequestsManager#abortAll
     */
    abortAll() {
        const requestIds = Object.keys(this.pendingRequests);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < requestIds.length; i++) {
            this.pendingRequests[requestIds[i]].abort();
        }

        this.pendingRequests = {};
    }

    /**
     * Indicates if there are pending requests.
     * @returns {boolean} True if there is at least one request pending, false otherwise.
     *
     * @method RequestsManager#isPending
     */
    isPending() {
        return Object.keys(this.pendingRequests).length > 0;
    }
}
